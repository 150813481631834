import {Component} from 'react'
//------------------------------------------------------------------------------------

import CookieService from "./CookieService";
import RoutingService from "./RoutingService";
// import ApiService from "./ApiService";
// import i18next from 'i18next';
//------------------------------------------------------------------------------------

class SecurityService extends Component {
    static acl = {
        login: { path: '/login', auth: false, namespace: 'login' },

        error500:   { path: '/error500', auth: true, namespace: 'error_pages' },
        error403:   { path: '/error403', auth: true, namespace: 'error_pages' },
        error404:   { path: '/error404', auth: true, namespace: 'error_pages' },

        home: { path: '/', auth: true, namespace: 'app' },
        event_logs: { path: '/logs', auth: true, permission_endpoint_id: 'event_logs', namespace: 'app' },
        transmission_logs: { path: '/transmission_logs', auth: true, permission_endpoint_id: 'transmission_logs', namespace: 'app' },
        roles: { path: '/roles', auth: true, permission_endpoint_id: 'roles', namespace: 'app' },
        users: { path: '/users', auth: true, permission_endpoint_id: 'admin_users', namespace: 'app' },
        profile: { path: '/profile', auth: true, permission_endpoint_id: 'profile', namespace: 'app' },
        orders: { path: '/orders', auth: true, permission_endpoint_id: 'orders', namespace: 'app' },
        preferences: { path: '/preferences', auth: true, permission_endpoint_id: 'company_preferences', namespace: 'app' },

        consignee_types: { path: '/consignee_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        bond_types: { path: '/bond_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        reconciliation_issues: { path: '/reconciliation_issues', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        change_options: { path: '/change_options', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        recon_location_codes: { path: '/recon_location_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        recon_payment_types: { path: '/recon_payment_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        recon_transmission_actions: { path: '/recon_transmission_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        recon_statuses: { path: '/recon_statuses', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        pmt_types: { path: '/pmt_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        manif_dest_from: { path: '/manif_dest_from', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        consecutives: { path: '/consecutives', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        invoice_formats: { path: '/invoice_formats', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        reach_limit_actions: { path: '/reach_limit_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        empty_validation_actions: { path: '/empty_validation_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        weight_in_lines: { path: '/weight_in_lines', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        electronic_filing_options: { path: '/electronic_filing_options', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        rel_date_mismatch_actions: { path: '/rel_date_mismatch_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        document_templates: { path: '/document_templates', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        traffic_types: { path: '/traffic_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        traffic_statuses: { path: '/traffic_statuses', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        ports: { path: '/ports', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        carriers: { path: '/carriers', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        unit_metrics: { path: '/unit_metrics', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        related_types: { path: '/related_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        teams: { path: '/teams', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        manufacturing_ids: { path: '/manufacturing_ids', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        mode_of_transport: { path: '/mode_of_transport', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        del_term: { path: '/del_term', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        edi_abi_transmissions: { path: '/edi_abi_transmissions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_convert_eda_actions: { path: '/edi_convert_eda_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_cvd_add_hts_types: { path: '/edi_cvd_add_hts_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_empty_value_lines: { path: '/edi_empty_value_lines', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_file_error_actions: { path: '/edi_file_error_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_group_entry_lines: { path: '/edi_group_entry_lines', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_group_sed_lines: { path: '/edi_group_sed_lines', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_inbond_transmissions: { path: '/edi_inbond_transmissions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_isf_transmissions: { path: '/edi_isf_transmissions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_lower_hts_types: { path: '/edi_lower_hts_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_on_convert_actions: { path: '/edi_on_convert_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_overwrite_product_no_exist_actions: { path: '/edi_overwrite_product_no_exist_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_overwrite_use_product_files: { path: '/edi_overwrite_use_product_files', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_product_file_mismatch_actions: { path: '/edi_product_file_mismatch_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_product_file_updated_actions: { path: '/edi_product_file_updated_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_product_no_exist_actions: { path: '/edi_product_no_exist_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_sed_transmissions: { path: '/edi_sed_transmissions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_uom_line_mismatches: { path: '/edi_uom_line_mismatches', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        edi_use_product_file_actions: { path: '/edi_use_product_file_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        broker_teams: { path: '/broker_teams', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        electronic_export_seds: { path: '/electronic_export_seds', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        entry_summary_query_types: { path: '/entry_summary_query_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        companies: { path: '/companies', auth: true, permission_endpoint_id: 'companies', namespace: 'app' },
        traffics: { path: '/traffics', auth: true, permission_endpoint_id: 'traffics', namespace: 'app' },
        delivery_orders: { path: '/delivery_orders', auth: true, permission_endpoint_id: 'delivery_orders', namespace: 'app' },
        cbp_transmition_trade: { path: '/cbp_transmition_trade', auth: true, permission_endpoint_id: 'cbp_transmition_trade', namespace: 'app' },
        entries: { path: '/entries', auth: true, permission_endpoint_id: 'entries', namespace: 'app' },
        cbp_statements: { path: '/cbp_statements', auth: true, permission_endpoint_id: 'cbp_statements', namespace: 'app' },
        inbonds: { path: '/inbonds', auth: true, permission_endpoint_id: 'inbonds', namespace: 'app' },
        general_orders: { path: '/general_orders', auth: true, permission_endpoint_id: 'general_orders', namespace: 'app' },
        blue_letter: { path: '/blue_letter', auth: true, permission_endpoint_id: 'blue_letter', namespace: 'app' },
        isf: { path: '/isf', auth: true, permission_endpoint_id: 'isf_entry', namespace: 'app' },
        
        ships_monitoring: { path: '/ships_monitoring', auth: true, permission_endpoint_id: 'ships_monitoring', namespace: 'app' },
        flexible_reports: { path: '/flexible_reports', auth: true, permission_endpoint_id: 'flexible_reports', namespace: 'app' },

        smtp_ssl_modes: { path: '/smtp_ssl_modes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        smtp_types: { path: '/smtp_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        system_on_update_actions: { path: '/system_on_update_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        sequence_modes: { path: '/sequence_modes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        traffic_flows: { path: '/traffic_flows', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        print_ent_not_certs: { path: '/print_ent_not_certs', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        default_entry_labels: { path: '/default_entry_labels', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        overwrite_origins: { path: '/overwrite_origins', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        seven_five_cero_one_declaration_forms: { path: '/seven_five_cero_one_declaration_forms', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        inbond_entered_options: { path: '/inbond_entered_options', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        close_traffic_ie_options: { path: '/close_traffic_ie_options', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        close_traffic_it_options: { path: '/close_traffic_it_options', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        close_traffic_te_options: { path: '/close_traffic_te_options', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        close_traffics: { path: '/close_traffics', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        default_aes_form_billed_docs: { path: '/default_aes_form_billed_docs', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        management_modes: { path: '/management_modes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        location_types: { path: '/location_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        firm_codes: { path: '/firm_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fee_codes: { path: '/fee_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        exchange_rates: { path: '/exchange_rates', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        entry_types: { path: '/entry_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        split_shipments: { path: '/split_shipments', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        payment_types: { path: '/payment_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        entry_actions: { path: '/entry_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        ace_dispositions: { path: '/ace_dispositions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        status_totals_cert_quota_statuses: { path: '/status_totals_cert_quota_statuses', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        status_totals_liq_types: { path: '/status_totals_cert_quota_statuses', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        status_totals_ace_psc_statuses: { path: '/status_totals_ace_psc_statuses', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        cbp_teams: { path: '/cbp_teams', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        inward_man_stickers: { path: '/inward_man_stickers', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        hts_options: { path: '/hts_options', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fta_options: { path: '/fta_options', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        country_fta_options: { path: '/country_fta_options', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        spip_options: { path: '/spip_options', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        spis_options: { path: '/spis_options', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fda_actions: { path: '/fda_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        disclaimers: { path: '/disclaimers', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        program_codes: { path: '/program_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        processing_codes: { path: '/processing_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        temp_qualifiers: { path: '/temp_qualifiers', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        temp_degree_types: { path: '/temp_degree_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        temp_locations: { path: '/temp_locations', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        entity_roles: { path: '/entity_roles', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        country_source_types: { path: '/country_source_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        aoc_codes: { path: '/aoc_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fee_accounting_class_codes: { path: '/fee_accounting_class_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        shipments: { path: '/shipments', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        bill_of_lading_types: { path: '/bill_of_lading_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        filing_types: { path: '/filing_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        id_qualifiers: { path: '/id_qualifiers', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        shipment_types: { path: '/shipment_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        // place_of_deliveries: { path: '/place_of_deliveries', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        activity_codes: { path: '/activity_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        container_types: { path: '/container_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        isf_transmission_actions: { path: '/isf_transmission_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        isf_transmission_reasons: { path: '/isf_transmission_reasons', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        isf_statuses: { path: '/isf_statuses', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        isf_entity_section_types: { path: '/isf_entity_section_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        isf_entity_types: { path: '/isf_entity_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        
        arrive_export_actions: { path: '/arrive_export_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        export_mode_of_transports: { path: '/export_mode_of_transports', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        inbond_types: { path: '/inbond_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        arrive_exports: { path: '/arrive_exports', auth: true, permission_endpoint_id: 'inbond_wp_entry', namespace: 'app' },

        shipper_statuses: { path: '/shipper_statuses', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        sed_labels: { path: '/sed_labels', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        sed_transmission_actions: { path: '/sed_transmission_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        inbond_codes: { path: '/inbond_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        license_codes: { path: '/license_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        export_codes: { path: '/export_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        export_dfs: { path: '/export_dfs', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        export_actions: { path: '/export_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        shippers: { path: '/shippers', auth: true, permission_endpoint_id: 'shippers', namespace: 'app' },

        go_statuses: { path: '/go_statuses', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        cargo_status: { path: '/cargo_status', auth: true, permission_endpoint_id: 'cargo_status', namespace: 'app' },
        inventory: { path: '/inventory', auth: true, permission_endpoint_id: 'inventory_records', namespace: 'app' },
        total_entries: { path: '/total_entries', auth: true, permission_endpoint_id: 'total_entries', namespace: 'app' },
        total_entries_month: { path: '/total_entries_month', auth: true, permission_endpoint_id: 'total_entries', namespace: 'app' },
        total_entries_year: { path: '/total_entries_year', auth: true, permission_endpoint_id: 'total_entries', namespace: 'app' },
        
        fda_pe_actions: { path: '/fda_pe_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        inbond_action_codes: { path: '/inbond_action_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        bill_of_lading_doc_types: { path: '/bill_of_lading_doc_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        routes: { path: '/routes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        emanif_trans_actions: { path: '/emanif_trans_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        emanif_trans_rfas: { path: '/emanif_trans_rfas', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        trip_statuses: { path: '/trip_statuses', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        conveyance_types: { path: '/conveyance_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        equipment_types: { path: '/equipment_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        equipment_itts: { path: '/equipment_itts', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        driver_types: { path: '/driver_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        cross_doc_types: { path: '/cross_doc_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        shipment_bl_types: { path: '/shipment_bl_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        shipment_rfas: { path: '/shipment_rfas', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        shipment_transmission_actions: { path: '/shipment_transmission_actions', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        emanifests: { path: '/emanifests', auth: true, permission_endpoint_id: 'emanif', namespace: 'app' },
        
        holidays: { path: '/holidays', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        duty_estimates: { path: '/duty_estimates', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        ddtc_licenses: { path: '/ddtc_licenses', auth: true, permission_endpoint_id: 'ddtc_licenses', namespace: 'app' },
        recon_entries: { path: '/recon_entries', auth: true, permission_endpoint_id: 'recon_entry', namespace: 'app' },

        pga_fda_codes: { path: '/pga_fda_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        pga_nhtsa_codes: { path: '/pga_nhtsa_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        pga_aphis_codes: { path: '/pga_aphis_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        pga_fsis_codes: { path: '/pga_fsis_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        pga_entities: { path: '/pga_entities', auth: true, permission_endpoint_id: 'pga_entity_records', namespace: 'app' },

        product_files: { path: '/product_files', auth: true, permission_endpoint_id: 'product_file', namespace: 'app' },
        product_file_pga_codes: { path: '/product_file_pga_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        product_file_pga_processing_codes: { path: '/product_file_pga_processing_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        aphis_program_codes: { path: '/aphis_program_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        aphis_processing_codes: { path: '/aphis_processing_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        aphis_types: { path: '/aphis_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        aphis_item_types: { path: '/aphis_item_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        aphis_category_types: { path: '/aphis_category_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        aphis_commodity_qualifiers: { path: '/aphis_commodity_qualifiers', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        upc_quality_codes: { path: '/upc_quality_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        insp_arrival_location_codes: { path: '/insp_arrival_location_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        intended_use_codes: { path: '/intended_use_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        route_types: { path: '/route_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        txn_types: { path: '/txn_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        lpco_types: { path: '/lpco_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        permit_issues: { path: '/permit_issues', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        date_qualifiers: { path: '/date_qualifiers', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        nhtsa_box_numbers: { path: '/nhtsa_box_numbers', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        nhtsa_program_codes: { path: '/nhtsa_program_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        nmfs_processing_codes: { path: '/nmfs_processing_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        nhtsa_qualifier_numbers: { path: '/nhtsa_qualifier_numbers', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        epa_program_codes: { path: '/epa_program_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        remark_codes: { path: '/remark_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        dea_program_codes: { path: '/dea_program_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        dea_document_ids: { path: '/dea_document_ids', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fsis_program_codes: { path: '/fsis_program_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fsis_inspections: { path: '/fsis_inspections', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fsis_lpco_types: { path: '/fsis_lpco_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fsis_lpco_geo_qualifiers: { path: '/fsis_lpco_geo_qualifiers', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fsis_category_codes: { path: '/fsis_category_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fsis_commodity_qualifiers: { path: '/fsis_commodity_qualifiers', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fws_program_codes: { path: '/fws_program_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fws_proc_codes: { path: '/fws_proc_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fws_disclaimers: { path: '/fws_disclaimers', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fws_declaration_codes: { path: '/fws_declaration_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        fws_description_codes: { path: '/fws_description_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        ttb_program_codes: { path: '/ttb_program_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        ttb_processing_codes: { path: '/ttb_processing_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        ttb_lpco_types: { path: '/ttb_lpco_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        ttb_exemption_codes: { path: '/ttb_exemption_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        product_file_nmfssim_harvdesccodes: { path: '/product_file_nmfssim_harvdesccodes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        product_file_nmfssim_harvgeartypes: { path: '/product_file_nmfssim_harvgeartypes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        product_file_nmfssim_procdesccodes: { path: '/product_file_nmfssim_procdesccodes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },

        country_codes: { path: '/country_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        aphis_inspections: { path: '/aphis_inspections', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        
        sim_specy_category_codes: { path: '/sim_specy_category_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        sim_species: { path: '/sim_species', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        harvest_desc_codes: { path: '/harvest_desc_codes', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        harvest_gear_types: { path: '/harvest_gear_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        harvest_vessel_char_types: { path: '/harvest_vessel_char_types', auth: true, permission_endpoint_id: 'lists_config', namespace: 'app' },
        
        charges: { path: '/charges', auth: true, permission_endpoint_id: 'ar_charges', namespace: 'app' },
        automatic_charges: { path: '/automatic_charges', auth: true, permission_endpoint_id: 'ar_auto_charges', namespace: 'app' },
        invoices: { path: '/invoices', auth: true, permission_endpoint_id: 'ar_invoices', namespace: 'app' },
        
        system_support: { path: '/system_support', auth: true, permission_endpoint_id: 'system_support', namespace: 'app' },
        email_templates: { path: '/email_templates', auth: true, permission_endpoint_id: 'email_templates', namespace: 'app' },
    };

    static tokenHasExpired() {
        if (SecurityService.userIsLogin()) {
            let current_timestamp = new Date().getTime();
            let token_limit_timestamp = SecurityService.getUserTokenLimitTimestamp();
            
            return (current_timestamp > token_limit_timestamp || !token_limit_timestamp);
        }

        return false;
    }

    static protectByAcl(action) {
        let url = null;
        let acl = SecurityService.acl;

        for (let key in acl) {
            let path = acl[key].path

            if (path.indexOf(':id') >= 0) {
                //Tiene parámetros...
                path = path.replace('/:id', '');

                if (RoutingService.urlContains(path)) {
                    url = acl[key];
                    break;
                }                
            }
            else {
                if (RoutingService.urlContainsExactly(path)) {
                    url = acl[key];
                    break;
                } 
            }
        }

        if (!url) {
            if (SecurityService.userIsLogin()) return RoutingService.redirectTo(acl.error404.path);
            return RoutingService.redirectTo(acl.login.path);
        }
        
        if (url.auth && !SecurityService.userIsLogin()) {
            if (url.namespace !== 'error_pages') CookieService.setCookie('url_before_login', RoutingService.getCurrentUrl(), true);
            return RoutingService.redirectTo(acl.login.path);
        }

        if (url.permission_endpoint_id && !SecurityService.userIsGrantedTo(url.permission_endpoint_id)) {
            return RoutingService.redirectTo(acl.error403.path);
        }

        if (url.namespace === 'login' && SecurityService.userIsLogin()) {
            return RoutingService.redirectTo(acl.home.path);
        }

        action();
    }

    static login(
        token, token_valid_minutes, client, id, name, email, photo_url,
        expiration_date, expiration_time, 
        roles_en, roles_es, roles_pt, allowed_tools, 
        redirect_url, remember) {

        CookieService.setCookie('user_token', token, remember);

        let now = new Date();
        let limit_date = new Date(now.getTime() + token_valid_minutes*60000)
        CookieService.setCookie('user_token_limit_timestamp', limit_date.getTime().toString(), remember);

        CookieService.setCookie('user_client', client, remember);
        CookieService.setCookie('user_id', id, remember);
        CookieService.setCookie('user_name', name, remember);
        CookieService.setCookie('user_email', email, remember);
        CookieService.setCookie('user_photo_url', photo_url, remember);

        CookieService.setCookie('user_expiration_date', expiration_date, remember);
        CookieService.setCookie('user_expiration_time', expiration_time, remember);

        CookieService.setCookie('user_roles_en', roles_en, remember);
        CookieService.setCookie('user_roles_es', roles_es, remember);
        CookieService.setCookie('user_roles_pt', roles_pt, remember);

        CookieService.setCookie('user_allowed_tools', JSON.stringify(allowed_tools), remember);

        if (redirect_url) {
            return RoutingService.redirectTo(redirect_url);
        }

        RoutingService.redirectTo(SecurityService.acl.home.path);
    }

    static assisted_login(
        token, token_valid_minutes, client, id, name, email, photo_url,
        expiration_date, expiration_time, 
        roles_en, roles_es, roles_pt, allowed_tools) {

        CookieService.setCookie('before_assisted_login_url', window.location.href, false);
        CookieService.setCookie('assisted_login_active', '1', false);

        CookieService.setCookie('assisted_user_token', token, false);

        let now = new Date();
        let limit_date = new Date(now.getTime() + token_valid_minutes*60000)
        CookieService.setCookie('assisted_user_token_limit_timestamp', limit_date.getTime().toString(), false);

        CookieService.setCookie('assisted_user_client', client, false);
        CookieService.setCookie('assisted_user_id', id, false);
        CookieService.setCookie('assisted_user_name', name, false);
        CookieService.setCookie('assisted_user_email', email, false);
        CookieService.setCookie('assisted_user_photo_url', photo_url, false);

        CookieService.setCookie('assisted_user_expiration_date', expiration_date, false);
        CookieService.setCookie('assisted_user_expiration_time', expiration_time, false);

        CookieService.setCookie('assisted_user_roles_en', roles_en, false);
        CookieService.setCookie('assisted_user_roles_es', roles_es, false);
        CookieService.setCookie('assisted_user_roles_pt', roles_pt, false);

        CookieService.setCookie('assisted_user_allowed_tools', JSON.stringify(allowed_tools), false);

        RoutingService.redirectTo(SecurityService.acl.home.path);
    }

    static isAdmin() {
        return (SecurityService.hasRole('SuperAdmin'));
    }

    static isAssistedLogin() {
        return CookieService.getCookie('assisted_login_active', '0') === '1';
    }

    static updateProfile(name, email, token) {
        if (SecurityService.isAssistedLogin()) {
            CookieService.setCookie('assisted_user_name', name, true);
            CookieService.setCookie('assisted_user_email', email, true);  
            CookieService.setCookie('assisted_user_token', token, true);
        }
        else {
            CookieService.setCookie('user_name', name, true);
            CookieService.setCookie('user_email', email, true);  
            CookieService.setCookie('user_token', token, true);          
        }
    }

    static updatePhotoUrl(url) {
        if (SecurityService.isAssistedLogin()) {
            CookieService.setCookie('assisted_user_photo_url', url, true);
        }
        else {
            CookieService.setCookie('user_photo_url', url, true);
        }
    }

    static userIsLogin() {
        return (
            SecurityService.getUserName() !== null &&
            SecurityService.getUserName() !== 'null' &&
            SecurityService.getUserName() !== '' &&
            SecurityService.getUserName() !== undefined
        );
    }

    static getUserToken() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_token');
        }

        return CookieService.getCookie('user_token');
    }

    static getUserTokenLimitTimestamp() {
        if (SecurityService.isAssistedLogin()) {
            return parseInt(CookieService.getCookie('assisted_user_token_limit_timestamp'));
        }

        return parseInt(CookieService.getCookie('user_token_limit_timestamp'));
    }

    static refreshUserToken(token, token_valid_minutes) {
      let now = new Date();
      let limit_date = new Date(now.getTime() + token_valid_minutes*60000);

      if (SecurityService.isAssistedLogin()) {
        CookieService.setCookie('assisted_user_token', token, true);
        CookieService.setCookie('assisted_user_token_limit_timestamp', limit_date.getTime().toString(), true);
        return;
      }

      CookieService.setCookie('user_token', token, true);
      CookieService.setCookie('user_token_limit_timestamp', limit_date.getTime().toString(), true);
    }

    static getUserClient() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_client');
        }

        return CookieService.getCookie('user_client');
    }

    static getUserId() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_id');
        }

        return CookieService.getCookie('user_id');
    }

    static userHasExpirationDate() {
        let expiration_date = null;

        if (SecurityService.isAssistedLogin()) {
            expiration_date = CookieService.getCookie('assisted_user_expiration_date');
        }
        else {
            expiration_date = CookieService.getCookie('user_expiration_date');
        }

        return (
            expiration_date != null &&
            expiration_date !== 'null' &&
            expiration_date !== '' &&
            expiration_date !== undefined
        );        
    }  

    static getUserName() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_name');
        }

        return CookieService.getCookie('user_name');
    }

    static isGhostUser() {
        let username = CookieService.getCookie('user_email');
        return username === window.env.CUSTOMS_ADMIN_GHOST_EMAIL
    }

    static getShortUserName() {
        let short_name = null;

        if (SecurityService.isAssistedLogin()) {
            short_name = CookieService.getCookie('assisted_user_name');
        }        
        else {
            short_name = CookieService.getCookie('user_name');
        }

        if (short_name) {
            return short_name.split(' ')[0]
        }

        return null;
    }

    static getUserEmail() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_email');
        }

        return CookieService.getCookie('user_email');
    }

    static getMainUserPhotoUrl() {
        return CookieService.getCookie('user_photo_url');
    }

    static getUserPhotoUrl() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_photo_url');
        }

        return CookieService.getCookie('user_photo_url');
    }

    static hasRole(role) {
        let roles = null;
        if (SecurityService.isAssistedLogin()) roles = CookieService.getCookie('assisted_user_roles_en');
        else roles = CookieService.getCookie('user_roles_en');

        return (roles.includes(role));
    }

    static getUserRole() {
        let locale = CookieService.getLocale();

        if (SecurityService.isAssistedLogin()) {
            if (locale === "es") return CookieService.getCookie('assisted_user_roles_es');
            if (locale === "pt") return CookieService.getCookie('assisted_user_roles_pt');
            
            return CookieService.getCookie('assisted_user_roles_en');
        }

        if (locale === "es") return CookieService.getCookie('user_roles_es');
        if (locale === "pt") return CookieService.getCookie('user_roles_pt');

        return CookieService.getCookie('user_roles_en');
    }

    static getUserRoleEn() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_roles_en');
        }

        return CookieService.getCookie('user_roles_en');
    }

    static getPasswordExpirationDate() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_expiration_date');
        }

        return CookieService.getCookie('user_expiration_date');
    }

    static getPasswordExpirationTime() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_expiration_time');
        }

        return CookieService.getCookie('user_expiration_time');
    }

    static userHasBeenWelcome() {
        if (SecurityService.isAssistedLogin()) {
            return CookieService.getCookie('assisted_user_welcome');
        }

        return CookieService.getCookie('user_welcome');
    }  

    static welcomeUser() {
        if (SecurityService.isAssistedLogin()) {
            CookieService.setCookie('assisted_user_welcome', '1', true);
        }
        else {
            CookieService.setCookie('user_welcome', '1', true);
        }
    }

    // static loadDynamicAllowedTools(action) {
    //     ApiService.requestEndpoint(i18next.t, window.env.GENERIC_BACKEND_RAILS_ACCOUNT, `user_permissions/${SecurityService.getUserId()}`,
    //         (response) => {
    //             CookieService.setCookie('user_allowed_tools', JSON.stringify(response.allowed_tools), true);
    //             action();
    //         }
    //     );
    // }

    static get_allowed_tools() {
        let tools = null;

        if (SecurityService.isAssistedLogin()) {
            tools = CookieService.getCookie('assisted_user_allowed_tools');
        }
        else {
            tools = CookieService.getCookie('user_allowed_tools');
        }

        return JSON.parse(tools)
    }

    static removeUserExpirationDate() {
        if (SecurityService.isAssistedLogin()) {
            CookieService.removeCookie('assisted_user_expiration_date');
        }
        else {
            CookieService.removeCookie('user_expiration_date');    
        }
        
        RoutingService.redirectTo(SecurityService.acl.home.path);
    }

    static userIsGrantedTo(tool, action='read') {
        // If it has no this property assigned, means that it is a tool not registered on permissions...
        // So, is a free tool, e.g., dashboard...
        if (!tool) return true;

        let allowed_tools = SecurityService.get_allowed_tools();
        if (!allowed_tools) return false;

        let tools = tool.split('|');
        let is_granted = false;

        tools.forEach((x) => {
            if (action === 'read') {
                is_granted = is_granted || (x in allowed_tools)
            }
            else {
                is_granted = is_granted || (x in allowed_tools && allowed_tools[x][action] === true)
            }
        });

        return is_granted;
    }

    static logout() {
        if (SecurityService.isAssistedLogin()) {
            CookieService.removeCookie('assisted_login_active');
            CookieService.removeCookie('assisted_first_load');

            CookieService.removeCookie('assisted_user_token');
            CookieService.removeCookie('assisted_user_token_limit_timestamp');
            CookieService.removeCookie('assisted_user_client');
            CookieService.removeCookie('assisted_user_id');
            CookieService.removeCookie('assisted_user_name');
            CookieService.removeCookie('assisted_user_email');
            CookieService.removeCookie('assisted_user_photo_url');
            CookieService.removeCookie('assisted_user_expiration_date');
            CookieService.removeCookie('assisted_user_roles_en');
            CookieService.removeCookie('assisted_user_roles_es');
            CookieService.removeCookie('assisted_user_roles_pt');
            CookieService.removeCookie('assisted_user_allowed_tools');
            CookieService.removeCookie('assisted_user_welcome');

            let url = CookieService.getCookie('before_assisted_login_url', null);
            CookieService.removeCookie('before_assisted_login_url');
            if (url) window.location.href = url;
            else RoutingService.redirectTo(SecurityService.acl.home.path);          
        }
        else {
            CookieService.removeCookie('first_load');
            CookieService.removeCookie('user_token');
            CookieService.removeCookie('user_token_limit_timestamp');
            CookieService.removeCookie('user_client');
            CookieService.removeCookie('user_id');
            CookieService.removeCookie('user_name');
            CookieService.removeCookie('user_email');
            CookieService.removeCookie('user_photo_url');
            CookieService.removeCookie('user_expiration_date');
            CookieService.removeCookie('user_roles_en');
            CookieService.removeCookie('user_roles_es');
            CookieService.removeCookie('user_roles_pt');
            CookieService.removeCookie('user_allowed_tools');
            CookieService.removeCookie('user_welcome');

            RoutingService.redirectTo(SecurityService.acl.login.path);       
        }
    }
}

export default SecurityService;
