import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Error404 = Loadable(lazy(() => import('views/error_pages/Error404')));
const Error403 = Loadable(lazy(() => import('views/error_pages/Error403')));
const Error500 = Loadable(lazy(() => import('views/error_pages/Error500')));

const EventLogs = Loadable(lazy(() => import('views/utilities/EventLogs')));
const TransmissionLogs = Loadable(lazy(() => import('views/utilities/TransmissionLogs')));
const Roles = Loadable(lazy(() => import('views/security/Roles')));
const AdminUsers = Loadable(lazy(() => import('views/security/admin_users/AdminUsers')));
const UserProfile = Loadable(lazy(() => import('views/security/UserProfile')));
const Orders = Loadable(lazy(() => import('views/utilities/Orders')));
const Preferences = Loadable(lazy(() => import('views/utilities/preferences/Preferences')));

const ConsigneeTypes = Loadable(lazy(() => import('views/lists_config/ConsigneeTypes')));
const BondTypes = Loadable(lazy(() => import('views/lists_config/BondTypes')));

const ReconciliationIssues = Loadable(lazy(() => import('views/lists_config/ReconciliationIssues')));
const ChangeOptions = Loadable(lazy(() => import('views/lists_config/ChangeOptions')));
const ReconLocationCodes = Loadable(lazy(() => import('views/lists_config/ReconLocationCodes')));
const ReconPaymentTypes = Loadable(lazy(() => import('views/lists_config/ReconPaymentTypes')));
const ReconTransmissionActions = Loadable(lazy(() => import('views/lists_config/ReconTransmissionActions')));
const ReconStatuses = Loadable(lazy(() => import('views/lists_config/ReconStatuses')));

const PmtTypes = Loadable(lazy(() => import('views/lists_config/PmtTypes')));
const ManifDestFroms = Loadable(lazy(() => import('views/lists_config/ManifDestFroms')));
const Consecutives = Loadable(lazy(() => import('views/lists_config/Consecutives')));
const InvoiceFormats = Loadable(lazy(() => import('views/lists_config/InvoiceFormats')));
const ReachLimitActions = Loadable(lazy(() => import('views/lists_config/ReachLimitActions')));
const EmptyValidationActions = Loadable(lazy(() => import('views/lists_config/EmptyValidationActions')));
const WeightInLines = Loadable(lazy(() => import('views/lists_config/WeightInLines')));
const ElectronicFilingOptions = Loadable(lazy(() => import('views/lists_config/ElectronicFilingOptions')));
const RelDateMismatchActions = Loadable(lazy(() => import('views/lists_config/RelDateMismatchActions')));
const DocumentTemplates = Loadable(lazy(() => import('views/lists_config/DocumentTemplates')));
const TrafficTypes = Loadable(lazy(() => import('views/lists_config/TrafficTypes')));
const TrafficStatuses = Loadable(lazy(() => import('views/lists_config/TrafficStatuses')));
const Ports = Loadable(lazy(() => import('views/lists_config/Ports')));
const UnitMetrics = Loadable(lazy(() => import('views/lists_config/UnitMetrics')));
const RelatedTypes = Loadable(lazy(() => import('views/lists_config/RelatedTypes')));
const ModeOfTransport = Loadable(lazy(() => import('views/lists_config/ModeOfTransport')));
const DelTerm = Loadable(lazy(() => import('views/lists_config/DelTerm')));
const EdiAbiTransmissions = Loadable(lazy(() => import('views/lists_config/EdiAbiTransmissions')));
const EdiConvertEdaActions = Loadable(lazy(() => import('views/lists_config/EdiConvertEdaActions')));
const EdiCvdAddHtsTypes = Loadable(lazy(() => import('views/lists_config/EdiCvdAddHtsTypes')));
const EdiEmptyValueLines = Loadable(lazy(() => import('views/lists_config/EdiEmptyValueLines')));
const EdiFileErrorActions = Loadable(lazy(() => import('views/lists_config/EdiFileErrorActions')));
const EdiGroupEntryLines = Loadable(lazy(() => import('views/lists_config/EdiGroupEntryLines')));
const EdiGroupSedLines = Loadable(lazy(() => import('views/lists_config/EdiGroupSedLines')));
const EdiInbondTransmissions = Loadable(lazy(() => import('views/lists_config/EdiInbondTransmissions')));
const EdiIsfTransmissions = Loadable(lazy(() => import('views/lists_config/EdiIsfTransmissions')));
const EdiLowerHtsTypes = Loadable(lazy(() => import('views/lists_config/EdiLowerHtsTypes')));
const EdiOnConvertActions = Loadable(lazy(() => import('views/lists_config/EdiOnConvertActions')));
const EdiOverwriteProductNoExistActions = Loadable(lazy(() => import('views/lists_config/EdiOverwriteProductNoExistActions')));
const EdiOverwriteUseProductFiles = Loadable(lazy(() => import('views/lists_config/EdiOverwriteUseProductFiles')));
const EdiProductFileMismatchActions = Loadable(lazy(() => import('views/lists_config/EdiProductFileMismatchActions')));
const EdiProductFileUpdatedActions = Loadable(lazy(() => import('views/lists_config/EdiProductFileUpdatedActions')));
const EdiProductNoExistActions = Loadable(lazy(() => import('views/lists_config/EdiProductNoExistActions')));
const EdiSedTransmissions = Loadable(lazy(() => import('views/lists_config/EdiSedTransmissions')));
const EdiUomLineMismatches = Loadable(lazy(() => import('views/lists_config/EdiUomLineMismatches')));
const EdiUseProductFileActions = Loadable(lazy(() => import('views/lists_config/EdiUseProductFileActions')));
const Teams = Loadable(lazy(() => import('views/lists_config/Teams')));

const BrokerTeams = Loadable(lazy(() => import('views/lists_config/BrokerTeams')));
const ElectronicExportSeds = Loadable(lazy(() => import('views/lists_config/ElectronicExportSeds')));
const EntrySummaryQueryTypes = Loadable(lazy(() => import('views/lists_config/EntrySummaryQueryTypes')));

const Companies = Loadable(lazy(() => import('views/companies/Companies')));
const Traffics = Loadable(lazy(() => import('views/traffics/Traffics')));
const DeliveryOrders = Loadable(lazy(() => import('views/delivery_orders/DeliveryOrders')));
const CBPTransmitionTrade = Loadable(lazy(() => import('views/cbp_transmition_trade/CBPTransmitionTrade')));

const Entries = Loadable(lazy(() => import('views/entries/Entries')));
const EntryDrawback7551 = Loadable(lazy(() => import('views/entries/drawback7551/EntryDrawback7551')));
const EntryDrawback7553 = Loadable(lazy(() => import('views/entries/drawback7553/EntryDrawback7553')));

const CBPStatements = Loadable(lazy(() => import('views/cbp_statements/CBPStatements')));
const Inbonds = Loadable(lazy(() => import('views/inbonds/Inbonds')));
const GeneralOrders = Loadable(lazy(() => import('views/general_orders/GeneralOrders')));
const BlueLetter = Loadable(lazy(() => import('views/blue_letter/BlueLetter')));
const ISF = Loadable(lazy(() => import('views/isf/ISF')));
const ArriveExports = Loadable(lazy(() => import('views/arrive_exports/ArriveExports')));
const Shippers = Loadable(lazy(() => import('views/shippers/Shippers')));
const EManifests = Loadable(lazy(() => import('views/emanifests/EManifests')));

const ShipsMonitoring = Loadable(lazy(() => import('views/ships_monitoring/ShipsMonitoring')));
const FlexibleReports = Loadable(lazy(() => import('views/flexible_reports/FlexibleReports')));
const CargoStatus = Loadable(lazy(() => import('views/cargo_status/CargoStatus')));
const Inventory = Loadable(lazy(() => import('views/inventory/Inventory')));
const TotalEntriesDateRange = Loadable(lazy(() => import('views/total_entries/TotalEntriesDateRange')));
const TotalEntriesMonth = Loadable(lazy(() => import('views/total_entries/TotalEntriesMonth')));
const TotalEntriesYear = Loadable(lazy(() => import('views/total_entries/TotalEntriesYear')));

const SmtpSslModes = Loadable(lazy(() => import('views/lists_config/SmtpSslModes')));
const SmtpTypes = Loadable(lazy(() => import('views/lists_config/SmtpTypes')));
const SystemOnUpdateActions = Loadable(lazy(() => import('views/lists_config/SystemOnUpdateActions')));
const SequenceModes = Loadable(lazy(() => import('views/lists_config/SequenceModes')));
const TrafficFlows = Loadable(lazy(() => import('views/lists_config/TrafficFlows')));
const PrintEntNotCerts = Loadable(lazy(() => import('views/lists_config/PrintEntNotCerts')));
const DefaultEntryLabels = Loadable(lazy(() => import('views/lists_config/DefaultEntryLabels')));
const OverwriteOrigins = Loadable(lazy(() => import('views/lists_config/OverwriteOrigins')));
const SevenFiveCeroOneDeclarationForms = Loadable(lazy(() => import('views/lists_config/SevenFiveCeroOneDeclarationForms')));
const InbondEnteredOptions = Loadable(lazy(() => import('views/lists_config/InbondEnteredOptions')));
const CloseTrafficIeOptions = Loadable(lazy(() => import('views/lists_config/CloseTrafficIeOptions')));
const CloseTrafficItOptions = Loadable(lazy(() => import('views/lists_config/CloseTrafficItOptions')));
const CloseTrafficTeOptions = Loadable(lazy(() => import('views/lists_config/CloseTrafficTeOptions')));
const CloseTraffics = Loadable(lazy(() => import('views/lists_config/CloseTraffics')));
const DefaultAesFormBilledDocs = Loadable(lazy(() => import('views/lists_config/DefaultAesFormBilledDocs')));
const ManagementModes = Loadable(lazy(() => import('views/lists_config/ManagementModes')));
const LocationTypes = Loadable(lazy(() => import('views/lists_config/LocationTypes')));
const FirmCodes = Loadable(lazy(() => import('views/lists_config/FirmCodes')));
const FeeCodes = Loadable(lazy(() => import('views/lists_config/FeeCodes')));
const ExchangeRates = Loadable(lazy(() => import('views/lists_config/ExchangeRates')));

const EntryTypes = Loadable(lazy(() => import('views/lists_config/EntryTypes')));
const SplitShipments = Loadable(lazy(() => import('views/lists_config/SplitShipments')));
const PaymentTypes = Loadable(lazy(() => import('views/lists_config/PaymentTypes')));
const EntryActions = Loadable(lazy(() => import('views/lists_config/EntryActions')));
const AceDispositions = Loadable(lazy(() => import('views/lists_config/AceDispositions')));
const StatusTotalsCertQuotaStatuses = Loadable(lazy(() => import('views/lists_config/StatusTotalsCertQuotaStatuses')));
const StatusTotalsLiqTypes = Loadable(lazy(() => import('views/lists_config/StatusTotalsLiqTypes')));
const StatusTotalsAcePscStatuses = Loadable(lazy(() => import('views/lists_config/StatusTotalsAcePscStatuses')));
const CbpTeams = Loadable(lazy(() => import('views/lists_config/CbpTeams')));
const InwardManStickers = Loadable(lazy(() => import('views/lists_config/InwardManStickers')));
const HtsOptions = Loadable(lazy(() => import('views/lists_config/HtsOptions')));
const FtaOptions = Loadable(lazy(() => import('views/lists_config/FtaOptions')));
const CountryFtaOptions = Loadable(lazy(() => import('views/lists_config/CountryFtaOptions')));
const SpipOptions = Loadable(lazy(() => import('views/lists_config/SpipOptions')));
const SpisOptions = Loadable(lazy(() => import('views/lists_config/SpisOptions')));
const FdaActions = Loadable(lazy(() => import('views/lists_config/FdaActions')));
const Disclaimers = Loadable(lazy(() => import('views/lists_config/Disclaimers')));
const ProgramCodes = Loadable(lazy(() => import('views/lists_config/ProgramCodes')));
const ProcessingCodes = Loadable(lazy(() => import('views/lists_config/ProcessingCodes')));
const TempQualifiers = Loadable(lazy(() => import('views/lists_config/TempQualifiers')));
const TempDegreeTypes = Loadable(lazy(() => import('views/lists_config/TempDegreeTypes')));
const TempLocations = Loadable(lazy(() => import('views/lists_config/TempLocations')));
const EntityRoles = Loadable(lazy(() => import('views/lists_config/EntityRoles')));
const CountrySourceTypes = Loadable(lazy(() => import('views/lists_config/CountrySourceTypes')));
const AocCodes = Loadable(lazy(() => import('views/lists_config/AocCodes')));
const FeeAccountingClassCodes = Loadable(lazy(() => import('views/lists_config/FeeAccountingClassCodes')));

const Shipments = Loadable(lazy(() => import('views/lists_config/Shipments')));

const BillOfLadingTypes = Loadable(lazy(() => import('views/lists_config/BillOfLadingTypes')));
const FilingTypes = Loadable(lazy(() => import('views/lists_config/FilingTypes')));
const IdQualifiers = Loadable(lazy(() => import('views/lists_config/IdQualifiers')));
const ShipmentTypes = Loadable(lazy(() => import('views/lists_config/ShipmentTypes')));
// const PlaceOfDeliveries = Loadable(lazy(() => import('views/lists_config/PlaceOfDeliveries')));
const ActivityCodes = Loadable(lazy(() => import('views/lists_config/ActivityCodes')));

const ContainerTypes = Loadable(lazy(() => import('views/lists_config/ContainerTypes')));
const IsfTransmissionActions = Loadable(lazy(() => import('views/lists_config/IsfTransmissionActions')));
const IsfTransmissionReasons = Loadable(lazy(() => import('views/lists_config/IsfTransmissionReasons')));
const IsfStatuses = Loadable(lazy(() => import('views/lists_config/IsfStatuses')));
const IsfEntitySectionTypes = Loadable(lazy(() => import('views/lists_config/IsfEntitySectionTypes')));
const IsfEntityTypes = Loadable(lazy(() => import('views/lists_config/IsfEntityTypes')));

const ArriveExportActions = Loadable(lazy(() => import('views/lists_config/ArriveExportActions')));
const ExportModeOfTransports = Loadable(lazy(() => import('views/lists_config/ExportModeOfTransports')));
const InbondTypes = Loadable(lazy(() => import('views/lists_config/InbondTypes')));

const ShipperStatuses = Loadable(lazy(() => import('views/lists_config/ShipperStatuses')));
const SedLabels = Loadable(lazy(() => import('views/lists_config/SedLabels')));
const SedTransmissionActions = Loadable(lazy(() => import('views/lists_config/SedTransmissionActions')));
const InbondCodes = Loadable(lazy(() => import('views/lists_config/InbondCodes')));
const LicenseCodes = Loadable(lazy(() => import('views/lists_config/LicenseCodes')));
const ExportCodes = Loadable(lazy(() => import('views/lists_config/ExportCodes')));
const ExportDfs = Loadable(lazy(() => import('views/lists_config/ExportDfs')));
const ExportActions = Loadable(lazy(() => import('views/lists_config/ExportActions')));

const GoStatuses = Loadable(lazy(() => import('views/lists_config/GoStatuses')));

const FdaPeActions = Loadable(lazy(() => import('views/lists_config/FdaPeActions')));
const InbondActionCodes = Loadable(lazy(() => import('views/lists_config/InbondActionCodes')));
const BillOfLadingDocTypes = Loadable(lazy(() => import('views/lists_config/BillOfLadingDocTypes')));
const Routes = Loadable(lazy(() => import('views/lists_config/Routes')));

const EmanifTransActions = Loadable(lazy(() => import('views/lists_config/EmanifTransActions')));
const EmanifTransRfas = Loadable(lazy(() => import('views/lists_config/EmanifTransRfas')));
const TripStatuses = Loadable(lazy(() => import('views/lists_config/TripStatuses')));
const ConveyanceTypes = Loadable(lazy(() => import('views/lists_config/ConveyanceTypes')));
const EquipmentTypes = Loadable(lazy(() => import('views/lists_config/EquipmentTypes')));
const EquipmentItts = Loadable(lazy(() => import('views/lists_config/EquipmentItts')));
const DriverTypes = Loadable(lazy(() => import('views/lists_config/DriverTypes')));
const CrossDocTypes = Loadable(lazy(() => import('views/lists_config/CrossDocTypes')));
const ShipmentBlTypes = Loadable(lazy(() => import('views/lists_config/ShipmentBlTypes')));
const ShipmentRfas = Loadable(lazy(() => import('views/lists_config/ShipmentRfas')));
const ShipmentTransmissionActions = Loadable(lazy(() => import('views/lists_config/ShipmentTransmissionActions')));

const HolidayRecords = Loadable(lazy(() => import('views/lists_config/HolidayRecords')));
const DutyEstimates = Loadable(lazy(() => import('views/lists_config/DutyEstimates')));
const DDTCLicenses = Loadable(lazy(() => import('views/ddtc_licenses/DDTCLicenses')));
const ReconEntries = Loadable(lazy(() => import('views/recon_entries/ReconEntries')));

const PgaFdaCodes = Loadable(lazy(() => import('views/lists_config/PgaFdaCodes')));
const PgaNhtsaCodes = Loadable(lazy(() => import('views/lists_config/PgaNhtsaCodes')));
const PgaAphisCodes = Loadable(lazy(() => import('views/lists_config/PgaAphisCodes')));
const PgaFsisCodes = Loadable(lazy(() => import('views/lists_config/PgaFsisCodes')));
const PgaEntities = Loadable(lazy(() => import('views/pga_entities/PgaEntities')));

const ProductFiles = Loadable(lazy(() => import('views/product_files/ProductFiles')));
const ProductFilePgaCodes = Loadable(lazy(() => import('views/lists_config/ProductFilePgaCodes')));
const ProductFilePgaProcessingCodes = Loadable(lazy(() => import('views/lists_config/ProductFilePgaProcessingCodes')));

const AphisProgramCodes = Loadable(lazy(() => import('views/lists_config/AphisProgramCodes')));
const AphisProcessingCodes = Loadable(lazy(() => import('views/lists_config/AphisProcessingCodes')));
const AphisTypes = Loadable(lazy(() => import('views/lists_config/AphisTypes')));
const AphisItemTypes = Loadable(lazy(() => import('views/lists_config/AphisItemTypes')));
const AphisCategoryTypes = Loadable(lazy(() => import('views/lists_config/AphisCategoryTypes')));
const AphisCommodityQualifiers = Loadable(lazy(() => import('views/lists_config/AphisCommodityQualifiers')));
const UpcQualityCodes = Loadable(lazy(() => import('views/lists_config/UpcQualityCodes')));
const InspArrivalLocationCodes = Loadable(lazy(() => import('views/lists_config/InspArrivalLocationCodes')));
const IntendedUseCodes = Loadable(lazy(() => import('views/lists_config/IntendedUseCodes')));
const RouteTypes = Loadable(lazy(() => import('views/lists_config/RouteTypes')));
const TxnTypes = Loadable(lazy(() => import('views/lists_config/TxnTypes')));
const LpcoTypes = Loadable(lazy(() => import('views/lists_config/LpcoTypes')));
const PermitIssues = Loadable(lazy(() => import('views/lists_config/PermitIssues')));
const DateQualifiers = Loadable(lazy(() => import('views/lists_config/DateQualifiers')));
const NhtsaBoxNumbers = Loadable(lazy(() => import('views/lists_config/NhtsaBoxNumbers')));
const NhtsaProgramCodes = Loadable(lazy(() => import('views/lists_config/NhtsaProgramCodes')));
const NmfsProcessingCodes = Loadable(lazy(() => import('views/lists_config/NmfsProcessingCodes')));
const NhtsaQualifierNumbers = Loadable(lazy(() => import('views/lists_config/NhtsaQualifierNumbers')));
const EpaProgramCodes = Loadable(lazy(() => import('views/lists_config/EpaProgramCodes')));
const RemarkCodes = Loadable(lazy(() => import('views/lists_config/RemarkCodes')));

const DeaProgramCodes = Loadable(lazy(() => import('views/lists_config/DeaProgramCodes')));
const DeaDocumentIds = Loadable(lazy(() => import('views/lists_config/DeaDocumentIds')));
const FsisProgramCodes = Loadable(lazy(() => import('views/lists_config/FsisProgramCodes')));
const FsisInspections = Loadable(lazy(() => import('views/lists_config/FsisInspections')));
const FsisLpcoTypes = Loadable(lazy(() => import('views/lists_config/FsisLpcoTypes')));
const FsisLpcoGeoQualifiers = Loadable(lazy(() => import('views/lists_config/FsisLpcoGeoQualifiers')));
const FsisCategoryCodes = Loadable(lazy(() => import('views/lists_config/FsisCategoryCodes')));
const FsisCommodityQualifiers = Loadable(lazy(() => import('views/lists_config/FsisCommodityQualifiers')));
const FwsProgramCodes = Loadable(lazy(() => import('views/lists_config/FwsProgramCodes')));
const FwsProcCodes = Loadable(lazy(() => import('views/lists_config/FwsProcCodes')));
const FwsDisclaimers = Loadable(lazy(() => import('views/lists_config/FwsDisclaimers')));
const FwsDeclarationCodes = Loadable(lazy(() => import('views/lists_config/FwsDeclarationCodes')));
const FwsDescriptionCodes = Loadable(lazy(() => import('views/lists_config/FwsDescriptionCodes')));
const TtbProgramCodes = Loadable(lazy(() => import('views/lists_config/TtbProgramCodes')));
const TtbProcessingCodes = Loadable(lazy(() => import('views/lists_config/TtbProcessingCodes')));
const TtbLpcoTypes = Loadable(lazy(() => import('views/lists_config/TtbLpcoTypes')));
const TtbExemptionCodes = Loadable(lazy(() => import('views/lists_config/TtbExemptionCodes')));

const ProductFileNmfssimHarvdesccodes = Loadable(lazy(() => import('views/lists_config/ProductFileNmfssimHarvdesccodes')));
const ProductFileNmfssimHarvgeartypes = Loadable(lazy(() => import('views/lists_config/ProductFileNmfssimHarvgeartypes')));
const ProductFileNmfssimProcdesccodes = Loadable(lazy(() => import('views/lists_config/ProductFileNmfssimProcdesccodes')));

const CountryCodes = Loadable(lazy(() => import('views/lists_config/CountryCodes')));
const AphisInspections = Loadable(lazy(() => import('views/lists_config/AphisInspections')));

const SimSpecyCategoryCodes = Loadable(lazy(() => import('views/lists_config/SimSpecyCategoryCodes')));
const SimSpecies = Loadable(lazy(() => import('views/lists_config/SimSpecies')));
const HarvestDescCodes = Loadable(lazy(() => import('views/lists_config/HarvestDescCodes')));
const HarvestGearTypes = Loadable(lazy(() => import('views/lists_config/HarvestGearTypes')));
const HarvestVesselCharTypes = Loadable(lazy(() => import('views/lists_config/HarvestVesselCharTypes')));

const Charges = Loadable(lazy(() => import('views/charges/Charges')));
const AutomaticCharges = Loadable(lazy(() => import('views/automatic_charges/AutomaticCharges')));
const Invoices = Loadable(lazy(() => import('views/invoices/Invoices')));

const SystemSupport = Loadable(lazy(() => import('views/system_support/SystemSupport')));
const EmailTemplates = Loadable(lazy(() => import('views/email_templates/EmailTemplates')));

const Carriers = Loadable(lazy(() => import('views/carriers/Carriers')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/error404',
            element: <Error404 />
        },
        {
            path: '/error403',
            element: <Error403 />
        },
        {
            path: '/error500',
            element: <Error500 />
        },

        {
            path: 'logs',
            element: <EventLogs />
        },
        {
            path: 'transmission_logs',
            element: <TransmissionLogs />
        },
        {
            path: 'roles',
            element: <Roles />
        },
        {
            path: 'users',
            element: <AdminUsers />
        },
        {
            path: 'profile',
            element: <UserProfile />
        },
        {
            path: 'orders',
            element: <Orders />
        },
        {
            path: 'preferences',
            element: <Preferences />
        },
        {
            path: 'consignee_types',
            element: <ConsigneeTypes />
        },
        {
            path: 'bond_types',
            element: <BondTypes />
        },
        {
            path: 'reconciliation_issues',
            element: <ReconciliationIssues />
        },
        {
            path: 'change_options',
            element: <ChangeOptions />
        },
        {
            path: 'recon_location_codes',
            element: <ReconLocationCodes />
        },
        {
            path: 'recon_payment_types',
            element: <ReconPaymentTypes />
        },
        {
            path: 'recon_transmission_actions',
            element: <ReconTransmissionActions />
        },
        {
            path: 'recon_statuses',
            element: <ReconStatuses />
        },
        {
            path: 'pmt_types',
            element: <PmtTypes />
        },
        {
            path: 'manif_dest_froms',
            element: <ManifDestFroms />
        },
        {
            path: 'consecutives',
            element: <Consecutives />
        },
        {
            path: 'invoice_formats',
            element: <InvoiceFormats />
        },
        {
            path: 'reach_limit_actions',
            element: <ReachLimitActions />
        },
        {
            path: 'empty_validation_actions',
            element: <EmptyValidationActions />
        },
        {
            path: 'weight_in_lines',
            element: <WeightInLines />
        },
        {
            path: 'electronic_filing_options',
            element: <ElectronicFilingOptions />
        },
        {
            path: 'rel_date_mismatch_actions',
            element: <RelDateMismatchActions />
        },
        {
            path: 'document_templates',
            element: <DocumentTemplates />
        },
        {
            path: 'traffic_types',
            element: <TrafficTypes />
        },
        {
            path: 'traffic_statuses',
            element: <TrafficStatuses />
        },
        {
            path: 'ports',
            element: <Ports />
        },
        {
            path: 'unit_metrics',
            element: <UnitMetrics />
        },
        {
            path: 'teams',
            element: <Teams />
        },
        {
            path: 'related_types',
            element: <RelatedTypes />
        },
        {
            path: 'mode_of_transport',
            element: <ModeOfTransport />
        },
        {
            path: 'del_term',
            element: <DelTerm />
        },
        {
            path: 'companies',
            element: <Companies />
        },
        {
            path: 'traffics',
            element: <Traffics />
        },
        {
            path: 'delivery_orders',
            element: <DeliveryOrders />
        },
        {
            path: 'cbp_transmition_trade',
            element: <CBPTransmitionTrade />
        },
        {
            path: 'entries',
            element: <Entries />
        },
        {
            path: 'drawback_7551',
            element: <EntryDrawback7551 />
        },
        {
            path: 'drawback_7553',
            element: <EntryDrawback7553 />
        },
        {
            path: 'cbp_statements',
            element: <CBPStatements />
        },
        {
            path: 'inbonds',
            element: <Inbonds />
        },
        {
            path: 'general_orders',
            element: <GeneralOrders />
        },
        {
            path: 'blue_letter',
            element: <BlueLetter />
        },
        {
            path: 'isf',
            element: <ISF />
        },
        {
            path: 'arrive_exports',
            element: <ArriveExports />
        },
        {
            path: 'shippers',
            element: <Shippers />
        },
        {
            path: 'emanifests',
            element: <EManifests />
        },

        {
            path: 'ships_monitoring',
            element: <ShipsMonitoring />
        },
        {
            path: 'flexible_reports',
            element: <FlexibleReports />
        },
        {
            path: 'cargo_status',
            element: <CargoStatus />
        },
        {
            path: 'inventory',
            element: <Inventory />
        },
        {
            path: 'total_entries',
            element: <TotalEntriesDateRange />
        },
        {
            path: 'total_entries_month',
            element: <TotalEntriesMonth />
        },
        {
            path: 'total_entries_year',
            element: <TotalEntriesYear />
        },

        {
            path: 'edi_abi_transmissions',
            element: <EdiAbiTransmissions />
        },
        {
            path: 'edi_convert_eda_actions',
            element: <EdiConvertEdaActions />
        },
        {
            path: 'edi_cvd_add_hts_types',
            element: <EdiCvdAddHtsTypes />
        },
        {
            path: 'edi_empty_value_lines',
            element: <EdiEmptyValueLines />
        },
        {
            path: 'edi_file_error_actions',
            element: <EdiFileErrorActions />
        },
        {
            path: 'edi_group_entry_lines',
            element: <EdiGroupEntryLines />
        },
        {
            path: 'edi_group_sed_lines',
            element: <EdiGroupSedLines />
        },
        {
            path: 'edi_inbond_transmissions',
            element: <EdiInbondTransmissions />
        },
        {
            path: 'edi_isf_transmissions',
            element: <EdiIsfTransmissions />
        },
        {
            path: 'edi_lower_hts_types',
            element: <EdiLowerHtsTypes />
        },
        {
            path: 'edi_on_convert_actions',
            element: <EdiOnConvertActions />
        },
        {
            path: 'edi_overwrite_product_no_exist_actions',
            element: <EdiOverwriteProductNoExistActions />
        },
        {
            path: 'edi_overwrite_use_product_files',
            element: <EdiOverwriteUseProductFiles />
        },
        {
            path: 'edi_product_file_mismatch_actions',
            element: <EdiProductFileMismatchActions />
        },
        {
            path: 'edi_product_file_updated_actions',
            element: <EdiProductFileUpdatedActions />
        },
        {
            path: 'edi_product_no_exist_actions',
            element: <EdiProductNoExistActions />
        },
        {
            path: 'edi_sed_transmissions',
            element: <EdiSedTransmissions />
        },
        {
            path: 'edi_uom_line_mismatches',
            element: <EdiUomLineMismatches />
        },
        {
            path: 'edi_use_product_file_actions',
            element: <EdiUseProductFileActions />
        },
        {
            path: 'broker_teams',
            element: <BrokerTeams />
        },
        {
            path: 'electronic_export_seds',
            element: <ElectronicExportSeds />
        },
        {
            path: 'entry_summary_query_types',
            element: <EntrySummaryQueryTypes />
        },
        {
            path: 'smtp_ssl_modes',
            element: <SmtpSslModes />
        },
        {
            path: 'smtp_types',
            element: <SmtpTypes />
        },
        {
            path: 'system_on_update_actions',
            element: <SystemOnUpdateActions />
        },
        {
            path: 'sequence_modes',
            element: <SequenceModes />
        },
        {
            path: 'traffic_flows',
            element: <TrafficFlows />
        },
        {
            path: 'print_ent_not_certs',
            element: <PrintEntNotCerts />
        },
        {
            path: 'default_entry_labels',
            element: <DefaultEntryLabels />
        },
        {
            path: 'overwrite_origins',
            element: <OverwriteOrigins />
        },
        {
            path: 'seven_five_cero_one_declaration_forms',
            element: <SevenFiveCeroOneDeclarationForms />
        },
        {
            path: 'inbond_entered_options',
            element: <InbondEnteredOptions />
        },
        {
            path: 'close_traffic_ie_options',
            element: <CloseTrafficIeOptions />
        },
        {
            path: 'close_traffic_it_options',
            element: <CloseTrafficItOptions />
        },
        {
            path: 'close_traffic_te_options',
            element: <CloseTrafficTeOptions />
        },
        {
            path: 'close_traffics',
            element: <CloseTraffics />
        },
        {
            path: 'default_aes_form_billed_docs',
            element: <DefaultAesFormBilledDocs />
        },
        {
            path: 'management_modes',
            element: <ManagementModes />
        },
        {
            path: 'location_types',
            element: <LocationTypes />
        },
        {
            path: 'firm_codes',
            element: <FirmCodes />
        },
        {
            path: 'fee_codes',
            element: <FeeCodes />
        },
        {
            path: 'exchange_rates',
            element: <ExchangeRates />
        },

        {
            path: 'entry_types',
            element: <EntryTypes />
        },
        {
            path: 'split_shipments',
            element: <SplitShipments />
        },
        {
            path: 'payment_types',
            element: <PaymentTypes />
        },
        {
            path: 'entry_actions',
            element: <EntryActions />
        },
        {
            path: 'ace_dispositions',
            element: <AceDispositions />
        },
        {
            path: 'status_totals_cert_quota_statuses',
            element: <StatusTotalsCertQuotaStatuses />
        },
        {
            path: 'status_totals_liq_types',
            element: <StatusTotalsLiqTypes />
        },
        {
            path: 'status_totals_ace_psc_statuses',
            element: <StatusTotalsAcePscStatuses />
        },
        {
            path: 'cbp_teams',
            element: <CbpTeams />
        },
        {
            path: 'inward_man_stickers',
            element: <InwardManStickers />
        },
        {
            path: 'hts_options',
            element: <HtsOptions />
        },
        {
            path: 'fta_options',
            element: <FtaOptions />
        },
        {
            path: 'country_fta_options',
            element: <CountryFtaOptions />
        },
        {
            path: 'spip_options',
            element: <SpipOptions />
        },
        {
            path: 'spis_options',
            element: <SpisOptions />
        },
        {
            path: 'fda_actions',
            element: <FdaActions />
        },
        {
            path: 'disclaimers',
            element: <Disclaimers />
        },
        {
            path: 'program_codes',
            element: <ProgramCodes />
        },
        {
            path: 'processing_codes',
            element: <ProcessingCodes />
        },
        {
            path: 'temp_qualifiers',
            element: <TempQualifiers />
        },
        {
            path: 'temp_degree_types',
            element: <TempDegreeTypes />
        },
        {
            path: 'temp_locations',
            element: <TempLocations />
        },
        {
            path: 'entity_roles',
            element: <EntityRoles />
        },
        {
            path: 'country_source_types',
            element: <CountrySourceTypes />
        },
        {
            path: 'aoc_codes',
            element: <AocCodes />
        },
        {
            path: 'fee_accounting_class_codes',
            element: <FeeAccountingClassCodes />
        },

        {
            path: 'shipments',
            element: <Shipments />
        },

        {
            path: 'bill_of_lading_types',
            element: <BillOfLadingTypes />
        },
        {
            path: 'filing_types',
            element: <FilingTypes />
        },
        {
            path: 'id_qualifiers',
            element: <IdQualifiers />
        },
        {
            path: 'shipment_types',
            element: <ShipmentTypes />
        },
        // {
        //     path: 'place_of_deliveries',
        //     element: <PlaceOfDeliveries />
        // },

        {
            path: 'activity_codes',
            element: <ActivityCodes />
        },
        {
            path: 'container_types',
            element: <ContainerTypes />
        },
        {
            path: 'isf_transmission_actions',
            element: <IsfTransmissionActions />
        },
        {
            path: 'isf_transmission_reasons',
            element: <IsfTransmissionReasons />
        },
        {
            path: 'isf_statuses',
            element: <IsfStatuses />
        },
        {
            path: 'isf_entity_section_types',
            element: <IsfEntitySectionTypes />
        },
        {
            path: 'isf_entity_types',
            element: <IsfEntityTypes />
        },
        {
            path: 'arrive_export_actions',
            element: <ArriveExportActions />
        },
        {
            path: 'export_mode_of_transports',
            element: <ExportModeOfTransports />
        },
        {
            path: 'inbond_types',
            element: <InbondTypes />
        },

        {
            path: 'shipper_statuses',
            element: <ShipperStatuses />
        },
        {
            path: 'sed_labels',
            element: <SedLabels />
        },
        {
            path: 'sed_transmission_actions',
            element: <SedTransmissionActions />
        },
        {
            path: 'inbond_codes',
            element: <InbondCodes />
        },
        {
            path: 'license_codes',
            element: <LicenseCodes />
        },
        {
            path: 'export_codes',
            element: <ExportCodes />
        },
        {
            path: 'export_dfs',
            element: <ExportDfs />
        },
        {
            path: 'export_actions',
            element: <ExportActions />
        },

        {
            path: 'go_statuses',
            element: <GoStatuses />
        },

        {
            path: 'fda_pe_actions',
            element: <FdaPeActions />
        },
        {
            path: 'inbond_action_codes',
            element: <InbondActionCodes />
        },
        {
            path: 'bill_of_lading_doc_types',
            element: <BillOfLadingDocTypes />
        },
        {
            path: 'routes',
            element: <Routes />
        },

        {
            path: 'emanif_trans_actions',
            element: <EmanifTransActions />
        },
        {
            path: 'emanif_trans_rfas',
            element: <EmanifTransRfas />
        },
        {
            path: 'trip_statuses',
            element: <TripStatuses />
        },
        {
            path: 'conveyance_types',
            element: <ConveyanceTypes />
        },
        {
            path: 'equipment_types',
            element: <EquipmentTypes />
        },
        {
            path: 'equipment_itts',
            element: <EquipmentItts />
        },
        {
            path: 'driver_types',
            element: <DriverTypes />
        },
        {
            path: 'cross_doc_types',
            element: <CrossDocTypes />
        },
        {
            path: 'shipment_bl_types',
            element: <ShipmentBlTypes />
        },
        {
            path: 'shipment_rfas',
            element: <ShipmentRfas />
        },
        {
            path: 'shipment_transmission_actions',
            element: <ShipmentTransmissionActions />
        },
        {
            path: 'holidays',
            element: <HolidayRecords />
        },
        {
            path: 'duty_estimates',
            element: <DutyEstimates />
        },
        {
            path: 'ddtc_licenses',
            element: <DDTCLicenses />
        },
        {
            path: 'recon_entries',
            element: <ReconEntries />
        },

        {
            path: 'pga_fda_codes',
            element: <PgaFdaCodes />
        },
        {
            path: 'pga_nhtsa_codes',
            element: <PgaNhtsaCodes />
        },
        {
            path: 'pga_aphis_codes',
            element: <PgaAphisCodes />
        },
        {
            path: 'pga_fsis_codes',
            element: <PgaFsisCodes />
        },
        {
            path: 'pga_entities',
            element: <PgaEntities />
        },
        {
            path: 'product_files',
            element: <ProductFiles />
        },
        {
            path: 'product_file_pga_codes',
            element: <ProductFilePgaCodes />
        },
        {
            path: 'product_file_pga_processing_codes',
            element: <ProductFilePgaProcessingCodes />
        },
        {
            path: 'aphis_program_codes',
            element: <AphisProgramCodes />
        },
        {
            path: 'aphis_processing_codes',
            element: <AphisProcessingCodes />
        },
        {
            path: 'aphis_types',
            element: <AphisTypes />
        },
        {
            path: 'aphis_item_types',
            element: <AphisItemTypes />
        },
        {
            path: 'aphis_category_types',
            element: <AphisCategoryTypes />
        },
        {
            path: 'aphis_commodity_qualifiers',
            element: <AphisCommodityQualifiers />
        },
        {
            path: 'upc_quality_codes',
            element: <UpcQualityCodes />
        },
        {
            path: 'insp_arrival_location_codes',
            element: <InspArrivalLocationCodes />
        },
        {
            path: 'intended_use_codes',
            element: <IntendedUseCodes />
        },
        {
            path: 'route_types',
            element: <RouteTypes />
        },
        {
            path: 'txn_types',
            element: <TxnTypes />
        },
        {
            path: 'lpco_types',
            element: <LpcoTypes />
        },
        {
            path: 'permit_issues',
            element: <PermitIssues />
        },
        {
            path: 'date_qualifiers',
            element: <DateQualifiers />
        },
        {
            path: 'nhtsa_box_numbers',
            element: <NhtsaBoxNumbers />
        },
        {
            path: 'nhtsa_program_codes',
            element: <NhtsaProgramCodes />
        },
        {
            path: 'nmfs_processing_codes',
            element: <NmfsProcessingCodes />
        },
        {
            path: 'nhtsa_qualifier_numbers',
            element: <NhtsaQualifierNumbers />
        },
        {
            path: 'epa_program_codes',
            element: <EpaProgramCodes />
        },
        {
            path: 'remark_codes',
            element: <RemarkCodes />
        },

        {
            path: 'dea_program_codes',
            element: <DeaProgramCodes />
        },
        {
            path: 'dea_document_ids',
            element: <DeaDocumentIds />
        },
        {
            path: 'fsis_program_codes',
            element: <FsisProgramCodes />
        },
        {
            path: 'fsis_inspections',
            element: <FsisInspections />
        },
        {
            path: 'fsis_lpco_types',
            element: <FsisLpcoTypes />
        },
        {
            path: 'fsis_lpco_geo_qualifiers',
            element: <FsisLpcoGeoQualifiers />
        },
        {
            path: 'fsis_category_codes',
            element: <FsisCategoryCodes />
        },
        {
            path: 'fsis_commodity_qualifiers',
            element: <FsisCommodityQualifiers />
        },
        {
            path: 'fws_program_codes',
            element: <FwsProgramCodes />
        },
        {
            path: 'fws_proc_codes',
            element: <FwsProcCodes />
        },
        {
            path: 'fws_disclaimers',
            element: <FwsDisclaimers />
        },
        {
            path: 'fws_declaration_codes',
            element: <FwsDeclarationCodes />
        },
        {
            path: 'fws_description_codes',
            element: <FwsDescriptionCodes />
        },
        {
            path: 'ttb_program_codes',
            element: <TtbProgramCodes />
        },
        {
            path: 'ttb_processing_codes',
            element: <TtbProcessingCodes />
        },
        {
            path: 'ttb_lpco_types',
            element: <TtbLpcoTypes />
        },
        {
            path: 'ttb_exemption_codes',
            element: <TtbExemptionCodes />
        },

        {
            path: 'product_file_nmfssim_harvdesccodes',
            element: <ProductFileNmfssimHarvdesccodes />
        },
        {
            path: 'product_file_nmfssim_harvgeartypes',
            element: <ProductFileNmfssimHarvgeartypes />
        },
        {
            path: 'product_file_nmfssim_procdesccodes',
            element: <ProductFileNmfssimProcdesccodes />
        },

        {
            path: 'country_codes',
            element: <CountryCodes />
        },
        {
            path: 'aphis_inspections',
            element: <AphisInspections />
        },

        {
            path: 'sim_specy_category_codes',
            element: <SimSpecyCategoryCodes />
        },
        {
            path: 'sim_species',
            element: <SimSpecies />
        },
        {
            path: 'harvest_desc_codes',
            element: <HarvestDescCodes />
        },
        {
            path: 'harvest_gear_types',
            element: <HarvestGearTypes />
        },
        {
            path: 'harvest_vessel_char_types',
            element: <HarvestVesselCharTypes />
        },

        {
            path: 'charges',
            element: <Charges />
        },
        {
            path: 'automatic_charges',
            element: <AutomaticCharges />
        },
        {
            path: 'invoices',
            element: <Invoices />
        },

        {
            path: 'system_support',
            element: <SystemSupport />
        },
        {
            path: 'email_templates',
            element: <EmailTemplates />
        },
        {
            path: 'carriers',
            element: <Carriers />
        },
    ]
};

export default MainRoutes;
