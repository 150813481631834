let es = {
  translation: {
    'Home': 'Inicio',
    'Welcome': 'Bienvenido',
    'Logout': 'Cerrar sesión',
    'English': 'Inglés',
    'Spanish': 'Español',
    'Portuguese': 'Portugués',
    'Event logs': 'Bitácora de acciones',
    'Event Logs': 'Bitácora de acciones',
    'Transmission logs': 'Bitácora de transmisiones',
    'Transmission Logs': 'Bitácora de transmisiones',
    'Logs': 'Historial',
    'Roles': 'Roles',
    'Assisted auth': 'Autenticación asistida',
    'Permissions': 'Permisos',
    'Record details': 'Detalles del registro',
    'Accounts': 'Cuentas',
    'Logs': 'Bitácoras',
	'AI Agent': 'Agente IA',
    'Verify the following validations': 'Verifique las siguientes validaciones',
    'Have you considered to update your profile avatar?': '¿Has considerado actualizar el avatar de tu perfil?',
    'Update user profile now': 'Actualizar perfil de usuario ahora',

    'Create': 'Adicionar',
    'Update': 'Actualizar',
    'Refresh': 'Actualizar',
    'Edit': 'Modificar',
    'Delete': 'Eliminar',
    'Confirm Delete': 'Confirmar Eliminar',
    'Cancel': 'Cancelar',
    'Close': 'Cerrar',
    'Name': 'Nombre',
    'Name Spanish': 'Nombre Español',
    'Name English': 'Nombre Inglés',
    'Name Portuguese': 'Nombre Portugués',
    'Create record': 'Adicionar registro',
    'Update record': 'Modificar registro',
    'Are you sure to delete this record?': '¿Está seguro de eliminar este registro?',
    'This operation is restricted to your user role': 'Esta operación no está permitida para su rol de usuario',
    'Permissions are already set for this user role': 'Los permisos de este rol de usuario ya han sido configurados',
    'Permissions of Role': 'Permisos del rol',
    'Read': 'Acceder',
    'Write': 'Modificar',
    'Remove': 'Eliminar',
    'List': 'Listar',
    'Has already been taken': 'Ya ha sido utilizado',
    'Range overflow': 'Por encima de lo permitido',
    'Pattern mismatch': 'Patrón incorrecto',
    'Range underflow': 'Por debajo de lo permitido',
    'Too long': 'Demasiado largo',
    'Type mismatch': 'Tipo de dato no válido',
    'Required': 'Requerido',
    'The operation was successful': 'La operación fue satisfactoria',
    'The operation could not be completed. Please, try again': 'No se pudo completar la operación. Por favor, inténtelo nuevamente',
    'The operation could not be completed. Possibly the instance has related records': 'La operacion no pudo ser completada. Posiblemente la instancia tiene registros relacionados',
    'Export to CSV': 'Exportar a CSV',
    'Filter By': 'Filtrar por',

    'Users': 'Usuarios',
    'Last visit': 'Última visita',
    'More than 100 days': 'Hace más de 100 dias',
    'days': 'dias',
    'Never': 'Nunca',

    'Change password': 'Cambiar contraseña',
    'Update profile': 'Actualizar perfil',
    'Photo': 'Foto',
    'Assisted authentication': 'Autenticación asistida',
    'New password': 'Contraseña nueva',
    'Confirm password': 'Confirmar contraseña',
    'Password and confirm password do not match': 'La nueva contraseña y la contraseña de confirmación no coindicen',
    'Current password is not valid': 'La contraseña actual no es correcta',
    'New password is not secure enough': 'La nueva contraseña no es segura',
    'New password can not be equal to current password': 'La nueva contraseña no puede ser igual a la contraseña actual',
    'Edit profile': 'Editar perfil',
    'Last name': 'Primer apellido',
    'Second last name': 'Segundo apellido',
    'Password should have at least 8 characters with a combination of letters, numbers and special characters. Sequences such as 123 or abc are considered insecure.': 'La contraseña debe tener al menos 8 caracteres con una combinación de letras, números y caracteres especiales. Secuencias como 123 o abc se consideran inseguras.',

    'Clic on image to change profile photo': 'Clic en la imagen para cambiar la foto de perfil',

    'User profile': 'Perfil de usuario',
    'Username': 'Nombre de usuario',
    'Exit': 'Salir',
    'Back': 'Regresar',

    'Profile': 'Perfil de usuario',
    'Orders': 'Órdenes',
    'Subtype': 'Subtipo',
    'Orders count': 'Cantidad de órdenes',
    'Order': 'Orden',
    'Records count': 'Cantidad de registros',

    'Import': 'Importar',
    'Export CSV': 'Exportar CSV',

    'Utils': 'Útiles',
    'Others': 'Otros',
    'Template': 'Modelo',
    'Confirm': 'Confirmar',
    'Confirm order': 'Confirmar orden',

    'Part number': 'Número',
    'Description': 'Descripción',
    'Quantity': 'Cantidad',
    'Metric': 'Unidad de medida',
    'Price': 'Precio',
    'Total': 'Total',
    'Date': 'Fecha',

    'Unconfirmed': 'Sin confirmar',
    'Confirmed': 'Confirmada',
    'State': 'Estado',

    'Code': 'Código',
    'Active': 'Activo',
    'Settings': 'Configuración',
    'Consignee types': 'Tipos de consignatarios',
    'Companies': 'Compañías',
    'Traffic': 'Tráfico',
    'Bond types': 'Tipos de bonos',
    'Reconciliation issues': 'Asuntos de conciliación',
    'Pmt types': 'Tipos de pago',
    'Manif dest from': 'Destinos múltiples desde',
    'Consecutives': 'Consecutivos',
    'Invoice formats': 'Formatos de factura',
    'Reach limit actions': 'Acciones de límite de alcance',

    'Empty validation actions': 'Acciones ante valores nulos',
    'Weight in lines': 'Pesos en línea',
    'Electronic filing options': 'Opciones de presentación electrónica',
    'Rel date mismatch actions': 'Acciones de desajuste de fecha real',
    'Document templates': 'Plantillas de documentos',
    'Traffic types': 'Tipos de tráfico',
    'Traffic statuses': 'Estados del tráfico',
    'Ports': 'Puertos',
    'Carriers': 'Transportistas',
    'Unit metrics': 'Unidades de medida',
    'Manufacturing ids': 'Identificadores de fabricación',
    'Mode of transports': 'Modos de transporte',
    'Loc of goods': 'Ubicación de bienes',
    'Del. terms': 'Terminos Del.',

    'Management': 'Administración',
    'System status': 'Estado del sistema',
    'Users count': 'Cantidad de usuarios',

    'Transmited': 'Transmitido',
    'Pending': 'Pendiente',
    'Arrive/Export document was not found in database': 'El documento de llegada/exportación no se encontró en la base de datos',
    'Transmit': 'Transmitir',
    'Document Links': 'Enlaces de documentos',
    'Notification Header WP10': 'Encabezado de notificación WP10',
    'Arrival, Export or Transfer Bond Liability WP20': 'Responsabilidad por fianza de llegada, exportación o transferencia WP20',
    'Arrive/Export Record Summary': 'Resumen de registro de llegada/exportación',
    'Traffic number': 'Número de tráfico',
    'Export mode of transport': 'Modo de transporte de exportación',
    'Firm code': 'Código de la empresa',
    'Inbond type': 'Tipo Inbond',
    'Port name': 'Nombre del puerto',
    'Process time': 'Tiempo de proceso',
    'Container number': 'Número de contenedor',
    'Bill of lading': 'Guía de carga',
    'Created by': 'Creado por',
    'Last updated by': 'Última actualización realizada por',
    'Response code': 'Código de respuesta',
    'Last CBP Response': 'Última respuesta de la CBP',
    'Arrive/Exports': 'Llegada/Exportaciones',
    'Record ID': 'Identificación de registro',
    'Action': 'Acción',
    'WP Inbond': 'WP en el interior',
    'Process Date': 'Fecha de proceso',
    'Port': 'Puerto',
    'No records': 'No hay registros',
    'Automatic': 'Automático',
    'Semi-Automatic': 'Semiautomático',
    'Return': 'Regresar',
    'Company': 'Compañía',
    'Document Type': 'Tipo de documento',
    'Charge': 'Cargar',
    'Amount': 'Cantidad',
    'Yes': 'Si',
    'No': 'No',
    'Blue letter document was not found in database': 'El documento de la carta azul no se encontró en la base de datos',
    'Blue Letter Summary': 'Resumen de la carta azul',
    'Consignee Key': 'Clave del destinatario',
    'Consignee Name': 'Nombre del destinatario',
    'Location of Goods': 'Ubicación de las mercancías',
    'Bonded': 'Garantizado',
    'Entry Type': 'Tipo de entrada',
    'Entry Date': 'Fecha de entrada',
    'Last Update User': 'Última actualización de usuario',
    'Customer Key': 'Clave del cliente',
    'Customer Name': 'Nombre del cliente',
    'CF-3499 Blue Letter': 'CF-3499 Carta Azul',
    'Blue Letter': 'Carta azul',
    'Traffic': 'Tráfico',
    'Consignee': 'Consignatario',
    'Exported': 'Exportado',
    'App. Port': 'Puerto de aplicación',
    'App. Date': 'Fecha de la aplicación',
    'Carrier': 'Transportador',
    'Entry number': 'Número de entrada',
    'Goods Consigned To': 'Mercancías Consignadas a',
    'Permission is Requested to': 'Se solicita permiso para',
    'Entry Information': 'Información de entrada',
    'Concurred Information': 'Información concordada',
    'Signature of Applicant': 'Firma del solicitante',
    'General': 'General',
    'Create Detail Line': 'Crear línea de detalle',
    'Edit Detail Line': 'Editar línea de detalle',
    'Update Detail Line': 'Actualizar línea de detalle',
    'Confirm Delete Detail Line': 'Confirmar eliminación de línea de detalle',
    'Detail Lines': 'Líneas de detalle',
    'Marks': 'Marcas',
    'Value': 'Valor',
    'Cargo Status': 'Estado de la carga',
    'Import CSV (*.csv)': 'Importar CSV (*.csv)',
    'Clean': 'Limpio',
    'Confirm clean': 'Confirmar limpieza',
    'Uploading': 'Subiendo',
    'First name': 'Nombre de pila',
    'Last name': 'Apellido',
    'Email': 'Correo electrónico',
    'Web Access': 'Acceso web',
    'Equipment ID': 'Identificación del equipo',
    'Equipment Type': 'Tipo de equipo',
    'ACE ID': 'Identificación ACE',
    'License Plates': 'Matrículas',
    'Update Equipment': 'Actualizar equipo',
    'Delete Equipment': 'Eliminar equipo',
    'Save Equipment': 'Guardar equipo',
    'Confirm delete Equipment': 'Confirmar eliminación de equipo',
    'List Equipments': 'Listado de equipos',
    'Notes': 'Notas',
    'Location': 'Ubicación',
    'Address': 'DIRECCIÓN',
    'Country': 'País',
    'City': 'Ciudad',
    'State': 'Estado',
    'ZIP': 'CREMALLERA',
    'Insurance Information': 'Información del seguro',
    'Name': 'Nombre',
    'Policy #': 'Política #',
    'Year': 'Año',
    'Ammount': 'Cantidad',
    'Others': 'Otros',
    'Total Drivers': 'Conductores totales',
    'Total Conveyance': 'Transferencia total',
    'Total Equipment': 'Equipamiento total',
    'Contacts': 'Contactos',
    'Code': 'Código',
    'Mode of transport': 'Modo de transporte',
    'AMS': 'AMS',
    'CTPAT': 'CTPAT',
    'Active': 'Activo',
    'Carriers': 'Transportistas',
    'Save': 'Ahorrar',
    'Update License Plate': 'Actualizar matrícula',
    'Save License Plate': 'Guardar matrícula',
    'Confirm delete License Plate': 'Confirmar eliminación de matrícula',
    'List Conveyances License Plates': 'Listado de matrículas de vehículos de transporte',
    'License Plate': 'Matrícula',
    'Create License Plate': 'Crear matrícula',
    'List Conveyances': 'Listado de transferencias',
    'Save Conveyance': 'Guardar la transferencia',
    'Conveyance ID': 'Identificación de transporte',
    'Airway': 'Vías respiratorias',
    'Federal ID': 'Identificación federal',
    'Delete License Plate': 'Eliminar matrícula',
    'List Equipments License Plates': 'Listado de equipos Matrículas',
    'Equipment': 'Equipo',
    'Create Equipment': 'Crear equipo',
    'Birthdate': 'Fecha de nacimiento',
    'Gender': 'Género',
    'Driver type': 'Tipo de controlador',
    'License Information': 'Información de licencia',
    'License': 'Licencia',
    'Expiration': 'Vencimiento',
    'Hazardous': 'Peligroso',
    'Cross Information': 'Información cruzada',
    'Middle name': 'Segundo nombre',
    'Second Last name': 'Segundo Apellido',
    'Proxy card': 'Tarjeta proxy',
    'Doc type': 'Tipo de documento',
    'Create Driver': 'Crear controlador',
    'DL Country': 'País DL',
    'DL State': 'Estado DL',
    'Expiration Date': 'Fecha de expiración',
    'Hazardous materials': 'Materiales peligrosos',
    'Cross Doc': 'Documento cruzado',
    'Cross Doc type': 'Tipo de documento cruzado',
    'Country Iss': 'País Iss',
    'Cross Expiration Date': 'Fecha de vencimiento cruzada',
    'Drivers License': 'Licencia de conducir',
    'Crossing Document': 'Documento de cruce',
    'Update Driver': 'Actualizar controlador',
    'Delete Driver': 'Eliminar controlador',
    'Save Driver': 'Guardar controlador',
    'Confirm delete Driver': 'Confirmar eliminación del controlador',
    'List Drivers': 'Listado de controladores',
    'Conveyance': 'Transporte',
    'Update Conveyance': 'Actualizar la transferencia',
    'Delete Conveyance': 'Eliminar cesión',
    'Confirm delete Conveyance': 'Confirmar eliminación de Conveyance',
    'Transpond': 'Transponder',
    'Conveyance Type': 'Tipo de transporte',
    'DOT Number': 'Número DOT',
    'Create Conveyance': 'Crear transferencia',
    'Entry details': 'Detalles de la entrada',
    'Traffic details': 'Detalles del tráfico',
    'Invoice details': 'Detalles de la factura',
    'Print': 'Imprimir',
    'Payers Unit Number': 'Número de unidad de pagadores',
    'Statement Type': 'Tipo de declaración',
    'Statement Filler': 'Relleno de declaración',
    'Statement/Bill Number': 'Número de estado de cuenta/factura',
    'Ammount to be paid': 'Monto a pagar',
    'Negation Date': 'Fecha de negación',
    'Clear': 'Claro',
    'Pay': 'Pagar',
    'Transmission Menu': 'Menú de transmisión',
    'ACH Payment': 'Pago ACH',
    'Automated Clearing House': 'Cámara de compensación automatizada',
    'ACE Transmission': 'Transmisión ACE',
    'Negate Payment': 'Negar pago',
    'Reset': 'Reiniciar',
    'Statement Details': 'Detalles de la declaración',
    'Check No.': 'Cheque No.',
    'Check Date': 'Comprobar fecha',
    'ACH Unit': 'Unidad ACH',
    'Ents. Count': 'Ents. Conde',
    'Ents. Del': 'Ent. Del',
    'Stmt. Print Date': 'Fecha de impresión de la declaración',
    'Due Date': 'Fecha de vencimiento',
    'Payment Date': 'Fecha de pago',
    'CBP Statements': 'Declaraciones de la CBP',
    'Export to CSV': 'Exportar a CSV',
    'Statement': 'Declaración',
    'Compare': 'Comparar',
    'Pmt. Msg': 'Mensaje de cortesía',
    'Payment type': 'Tipo de pago',
    'Importer or Record': 'Importador o Registro',
    'Importer': 'Importador',
    'Monthly': 'Mensual',
    'PDS - Unpaid': 'PDS - No pagado',
    'PDS - Paid / Final': 'PDS - Pagado / Final',
    'Query Importer': 'Importador de consultas',
    'Query Quota': 'Cuota de consulta',
    'Type': 'Tipo',
    'Tariff Number or textile category': 'Número arancelario o categoría textil',
    'Second tariff number': 'Segundo número de tarifa',
    'Origin country': 'País de origen',
    'Entry number To': 'Número de entrada Para',
    'Specific': 'Específico',
    'Range': 'Rango',
    'Entry number From': 'Número de entrada Desde',
    'ACS Stmt and ACH Pmt': 'Declaración de ACS y pago de ACH',
    'Transmission Date': 'Fecha de transmisión',
    'Customer': 'Cliente',
    'Statement number': 'Número de declaración',
    'ACH Payment Request': 'Solicitud de pago ACH',
    'PDS Payment Request': 'Solicitud de pago de PDS',
    'Preliminary Statement Request': 'Solicitud de declaración preliminar',
    'Final Statement Request': 'Solicitud de declaración final',
    'Pmt Type': 'Tipo de PMT',
    'Delete/Update Entry From Stmt': 'Eliminar/Actualizar entrada de la declaración',
    'Statement Reroute (MO/MQ)': 'Redirección de declaración (MO/MQ)',
    'Record B Port': 'Puerto de registro B',
    'Preliminary Daily Statement': 'Comunicado preliminar diario',
    'Final Daily Statement': 'Declaración diaria final',
    'PMS Preliminary Statement': 'Declaración preliminar del PMS',
    'PMS Final Statement': 'Declaración final del PMS',
    'Filer code': 'Código de archivo',
    'Request': 'Pedido',
    'Request TIB Extension': 'Solicitud de extensión de TIB',
    'Responses Log': 'Registro de respuestas',
    'From Date': 'Desde Fecha',
    'To Date': 'Hasta la fecha',
    'Date Range': 'Rango de fechas',
    'Specific Case': 'Caso específico',
    'Case number': 'Número de caso',
    'Origin/HTS': 'Origen/HTS',
    'Tariff number': 'Número de tarifa',
    'Stmt Type': 'Tipo de declaración',
    'Statement Filer': 'Registrador de declaraciones',
    'Amount to be paid': 'Importe a pagar',
    'Negative Payment': 'Pago Negativo',
    'Border Cargo Certification': 'Certificación de carga fronteriza',
    'Cargo Release Certification': 'Certificación de liberación de carga',
    'Verify Entries': 'Verificar entradas',
    'Modules': 'Módulos',
    'Send': 'Enviar',
    'CBP Transmission Trade': 'Comercio de transmisión de CBP',
    'Certificate Query': 'Consulta de certificado',
    'Condition code': 'Código de condición',
    'By Document Number': 'Por número de documento',
    'From Document': 'Del documento',
    'To Document': 'Para documentar',
    'By Document Date': 'Por fecha del documento',
    'Carrier Code': 'Código del transportista',
    'or Name': 'o Nombre',
    'Gold Price': 'Precio del oro',
    'Begin Date': 'Fecha de inicio',
    'Country Code': 'Código del país',
    'Currency Conversion': 'Conversión de moneda',
    'Exchange rate date 1': 'Fecha del tipo de cambio 1',
    'Exchange rate date 2': 'Fecha del tipo de cambio 2',
    'Region/District/Port': 'Región/Distrito/Puerto',
    'Download Port Codes from RbSystems': 'Descargar códigos de puerto de RbSystems',
    'Location by Code': 'Ubicación por código',
    'FIRMS Code': 'Código FIRMS',
    'Location by Name': 'Ubicación por nombre',
    'District Code': 'Código de distrito',
    'Zip, State code': 'Código postal, código estatal',
    'ACE version': 'Versión ACE',
    'Foreign port code': 'Código de puerto extranjero',
    'Team assignment': 'Asignación de equipo',
    'Reference Files': 'Archivos de referencia',
    'Affirmation code': 'Código de afirmación',
    'FDA Affirmation of Compliance': 'Afirmación de cumplimiento de la FDA',
    'By Range': 'Por rango',
    'From HTS': 'Desde HTS',
    'To HTS': 'Hacia HTS',
    'As of Date': 'A partir de la fecha',
    'Specific HTS': 'HTS específico',
    'Add HTS': 'Añadir HTS',
    'By Importer': 'Por importador',
    'Name qualifier': 'Calificador de nombre',
    'Name (Cont.)': 'Nombre (Cont.)',
    'Entries per year': 'Entradas por año',
    'Program code 1': 'Código de programa 1',
    'Program code 2': 'Código de programa 2',
    'Program code 3': 'Código de programa 3',
    'Program code 4': 'Código de programa 4',
    'Mailing address': 'Dirección de envio',
    'Mailing address 2': 'Dirección postal 2',
    'Mailing country': 'País de envío',
    'Address type': 'Tipo de dirección',
    'If other': 'Si otro',
    'Phone': 'Teléfono',
    'Website': 'Sitio web',
    'Physical address 1': 'Dirección física 1',
    'Physical address 2': 'Dirección física 2',
    'Physical country': 'País físico',
    'Business description': 'Descripción del negocio',
    'Filer (If applies)': 'Solicitante (si aplica)',
    'Year established': 'Año de fundación',
    'Primary Bank': 'Banco Primario',
    'Routing': 'Enrutamiento',
    'Company country': 'País de la empresa',
    'Locater ID': 'Identificación del localizador',
    'Reference': 'Referencia',
    'Title': 'Título',
    'I have no social security number': 'No tengo número de seguro social',
    'I have no IRS number': 'No tengo número de IRS',
    'I have not applied for an IRS number or SSN': 'No he solicitado un número de IRS o SSN',
    'I am not a U.S. resident': 'No soy residente de EE. UU.',
    'Consignee/Ultimate Consignee': 'Destinatario/Destinatario final',
    'Drawback Claimant': 'Reclamante de devolución',
    'Refund/Bills': 'Reembolso/facturas',
    'Other': 'Otro',
    'Address Information': 'Información de la dirección',
    'Use Mailing address as physical address': 'Utilice la dirección postal como dirección física',
    'Company Information (Optional)': 'Información de la empresa (opcional)',
    'Business Structure/Benefitial Owner/Company Officers (Optional)': 'Estructura de la empresa/Beneficiario final/Directivos de la empresa (opcional)',
    'Certification': 'Proceso de dar un título',
    'Street': 'Calle',
    'Zip code': 'Código postal',
    'Firm name': 'Nombre de la empresa',
    'Manufacturer Name and Address Add': 'Nombre y dirección del fabricante Agregar',
    'Manufacturer ID': 'Identificación del fabricante',
    'Manufacturer Name and Address Query': 'Consulta de nombre y dirección del fabricante',
    'Limit Output': 'Limitar la salida',
    'Inbond number': 'Número de entrada en vigor',
    'Air waybill': 'Carta de porte aéreo',
    'House Air waybill': 'Carta de porte aéreo de la casa',
    'Entry': 'Entrada',
    'Request for Bill of Lading Status': 'Solicitud de estado del conocimiento de embarque',
    'Inbond': 'En servidumbre',
    'Request House Bill of Lading (HBL) Information': 'Solicitud de información sobre conocimiento de embarque (HBL)',
    'Query Type': 'Tipo de consulta',
    'Consumption Entry Summaries Flag': 'Resúmenes de entradas de consumo Bandera',
    'FTA Recon Summaries Flag': 'Resúmenes de reconocimiento de FTA Bandera',
    'Other Recon Summaries Flag': 'Otros resúmenes de reconocimiento Marcar',
    'Drawback Summaries Flag': 'Resúmenes de desventajas Bandera',
    'FTA Duty Deferral Summaries Flag': 'Resúmenes de aplazamiento de derechos del TLC Bandera',
    'Exclude Entry Details': 'Excluir detalles de entrada',
    'Default value': 'Valor predeterminado',
    'Default cost': 'Costo predeterminado',
    'Charges': 'Cargos',
    'Key': 'Llave',
    'Related country': 'País relacionado',
    'Create Related': 'Crear relacionados',
    'Edit Related': 'Editar Relacionado',
    'Update Related': 'Actualizaciones relacionadas',
    'Delete Related': 'Eliminar Relacionados',
    'Related List': 'Lista relacionada',
    'Sort Priority': 'Ordenar por prioridad',
    'Sort Order': 'Orden de clasificación',
    'Field Name': 'Nombre del campo',
    'Create Printed invoice': 'Crear factura impresa',
    'Edit Printed invoice': 'Editar factura impresa',
    'Update Printed invoice': 'Actualizar factura impresa',
    'Delete Printed invoice': 'Eliminar factura impresa',
    'Printed Invoice Sort Settings': 'Configuración de clasificación de facturas impresas',
    'Item type': 'Tipo de artículo',
    'Address 2': 'Dirección 2',
    'Create ID File': 'Crear archivo de identificación',
    'Edit ID File': 'Editar archivo de identificación',
    'Update ID File': 'Actualizar archivo de identificación',
    'Delete ID File': 'Eliminar archivo de identificación',
    'ID Files List': 'Listado de archivos de identificación',
    'Description': 'Descripción',
    'Emails (Separated by comma)': 'Correos electrónicos (separados por coma)',
    'Create Group': 'Crear grupo',
    'Edit Group': 'Editar grupo',
    'Update Group': 'Grupo de actualización',
    'Delete Group': 'Eliminar grupo',
    'Groups List': 'Listado de grupos',
    'Document template': 'Documento ejemplar',
    'U.S. Port Unld': 'Puerto de Estados Unidos Unld',
    'Consignee Country': 'País del destinatario',
    'Export Country': 'País de exportación',
    'Fgn Port Of Load': 'Puerto de carga de Fgn',
    'Unit of Metric': 'Unidad métrica',
    'NDC Team': 'Equipo del NDC',
    'AII Id': 'Todo Id',
    'AII Number': 'Número AII',
    'Del. Terms': 'Del. Condiciones',
    'Currency': 'Divisa',
    'Search by key, isr/issn and name...': 'Buscar por clave, isr/issn y nombre...',
    'Search by code and name...': 'Buscar por código y nombre...',
    'Entities': 'Entidades',
    'Places': 'Lugares',
    '7501 Declarations': '7501 Declaraciones',
    'Declaration 1': 'Declaración 1',
    'Declaration 2': 'Declaración 2',
    'Declaration 3': 'Declaración 3',
    'Declaration 4': 'Declaración 4',
    'Other Defaults': 'Otros valores predeterminados',
    'ID Release': 'Liberación de identidad',
    'Create Default': 'Crear valor predeterminado',
    'Edit Default': 'Editar predeterminado',
    'Update Default': 'Actualizar predeterminado',
    'Delete Default': 'Eliminar predeterminado',
    'Defaults List': 'Listado de valores predeterminados',
    'Created On': 'Creado el',
    'Date': 'Fecha',
    'Position': 'Posición',
    'Mobile': 'Móvil',
    'Locating info': 'Información de localización',
    'Create Contact': 'Crear contacto',
    'Edit Contact': 'Editar contacto',
    'Update Contact': 'Actualizar contacto',
    'Delete Contact': 'Eliminar contacto',
    'Contacts List': 'Listado de contactos',
    'Test Emails': 'Correos electrónicos de prueba',
    'IRS / SSN': 'IRS / Número de Seguro Social',
    'Related': 'Relacionado',
    'Misc': 'Varios',
    'DIS Auto Store': 'Tienda de automóviles DIS',
    'GBI DUNS': 'GBI DUN',
    'Foreign ID': 'Identificación extranjera',
    'Foreign Sys Key': 'Clave de sistema externo',
    'Consignee Type': 'Tipo de destinatario',
    'Bond type': 'Tipo de enlace',
    'Days begin warning': 'Los días comienzan a advertir',
    'Surety': 'Garantía',
    'Producer': 'Productor',
    'Serial number': 'Número de serie',
    'Eff date': 'Fecha de vigencia',
    'Exp date': 'Fecha de caducidad',
    'Reconciliation issue': 'Problema de reconciliación',
    'End date': 'Fecha de finalización',
    'POA days begin warning': 'Los días de POA comienzan a advertir',
    'Product NOT exists': 'El producto NO existe',
    'HTS mismatch': 'Desajuste de HTS',
    'Origin/SPI mismatch': 'Desajuste de origen/SPI',
    'Enforce Prod Dates': 'Hacer cumplir las fechas de producción',
    'Electronic Filling Option': 'Opción de llenado electrónico',
    'If ECCN is Empty': 'Si el ECCN está vacío',
    'Default ECCN': 'ECCN predeterminado',
    'Center ID': 'Identificación del centro',
    'Pmt. Type': 'Tipo de pago',
    'Pmt. Weekday': 'Pmt. Día laborable',
    'Pmt. days': 'Días de pago',
    'ACH Unit Number': 'Número de unidad ACH',
    'ACH Bi-Monthly Stmt (2-16)': 'Declaración bimensual de ACH (16 de febrero)',
    'ACH Bi-Monthly Stmt (17-1)': 'Declaración bimensual de ACH (17-1)',
    'Deferred Tax': 'Impuesto diferido',
    'Cotton Cert.': 'Certificado de algodón.',
    'NOA Permit': 'Permiso NOA',
    'Known Importer': 'Importador conocido',
    'Manif Dets From': 'Detalles del manifiesto',
    'Consecutive': 'Consecutivo',
    'Ignore product Cat': 'Ignorar producto Cat',
    'Perishable': 'Perecedero',
    'CBP-4811': 'CBP-4811',
    'Prorated Weight as CBP Qty [Deprecated]': 'Peso prorrateado como cantidad de CBP [obsoleto]',
    'Weight in lines': 'Peso en lineas',
    'Specify Assist': 'Especificar asistencia',
    'Assist Pctg': 'Ayudar a Pctg',
    'AutoGen 7501': 'Autogenerador 7501',
    'Print Invoice on CF-7501': 'Imprimir factura en CF-7501',
    'On Rel Date Mismatch': 'En Rel Discordancia de fecha',
    'Day due': 'Día de vencimiento',
    'Billed to company': 'Facturado a la empresa',
    'Mail to company': 'Correo a la empresa',
    'Mail to (Free Text)': 'Enviar a (Texto libre)',
    'Pay Own Duties': 'Pagar sus propios deberes',
    'Invoice format': 'Formato de factura',
    'Pmt Message': 'Mensaje de PMT',
    'Accept traffic from companies': 'Aceptar tráfico de empresas',
    'Accept traffic for all Companies': 'Aceptar tráfico para todas las empresas',
    'General text': 'Texto general',
    'Invoice Print Consolidate CBP Charges': 'Impresión de facturas Consolidación de cargos de CBP',
    'Cash Disb Min Ammount': 'Desembolso mínimo de efectivo',
    'Balance': 'Balance',
    'Balance limit': 'Límite de saldo',
    'Credit days': 'Días de crédito',
    'Credit days limit': 'Límite de días de crédito',
    'Reach Limit Action': 'Acción de alcanzar límite',
    'Type your notes...': 'Escribe tus notas...',
    'Activate Customer for EDI': 'Activar cliente para EDI',
    'Do not assign Traf to EDI': 'No asigne Traf a EDI',
    'EDI File Has Errors': 'El archivo EDI tiene errores',
    'Use Product File': 'Utilizar archivo de producto',
    'Prod. Does Not Exist': 'Prod. No existe',
    'Prod. File Update': 'Actualización de archivo de producción',
    'Ignore PGA from XML file': 'Ignorar PGA del archivo XML',
    'Prod. File Mismatch': 'No hay coincidencia en el archivo de producción',
    'Web Svc Password': 'Contraseña de servicio web',
    'Send ABI Transmissions as': 'Enviar transmisiones ABI como',
    'Transmit Emanifest': 'Transmitir Emanifiesto',
    'HTS Correction': 'Corrección HTS',
    'Group Entry Lines by': 'Líneas de entrada grupal por',
    'Contains CVD/ADD HTS': 'Contiene CVD/ADD HTS',
    'Send SED Transmission as': 'Enviar transmisión SED como',
    'Less than $2,500 lines': 'Líneas de menos de $2,500',
    'On Convert': 'En Convertir',
    'On $0 Value Lines': 'En líneas de valor $0',
    'Group SED Lines by': 'Grupo Líneas SED por',
    'Overwrite Use Product File': 'Sobrescribir el archivo de uso del producto',
    'Overwrite Prod does not exist': 'Sobrescribir Prod no existe',
    'Convert EDA, use # of Decimals': 'Convertir EDA, utilizar número de decimales',
    'On UOM Line Mismatch': 'Desajuste de línea en UOM',
    'Send Inbond Trans. as': 'Enviar Inbond Trans. como',
    'Send ISF Trans. as': 'Enviar ISF Trans. como',
    'EDI Notes': 'Notas EDI',
    'Account Manager': 'Gerente de cuentas',
    'Broker Team': 'Equipo de corredores',
    'Emain Print Out Copies': 'Copias impresas por correo electrónico',
    'Acceptance': 'Aceptación',
    'Proforma/Comm. Invoice.': 'Factura proforma/comunitaria.',
    'Header': 'Encabezamiento',
    'AMS Match': 'Partido AMS',
    'Proforma': 'Proforma',
    'Delivery Order': 'Orden de entrega',
    'Receiving Ticket': 'Recepción de boleto',
    'Shipping Ticket': 'Boleto de envío',
    'No Active': 'No activo',
    'Bad customer': 'Mal cliente',
    'Exporter': 'Exportador',
    'Forwarder': 'Promotor',
    'Owner': 'Dueño',
    'Vendor': 'Proveedor',
    'Staff': 'Personal',
    'Key / Name': 'Clave / Nombre',
    'Company Identifiers': 'Identificadores de la empresa',
    'Status / Role': 'Estado / Rol',
    'AES Main Contact': 'Contacto principal de AES',
    'A/R Main Contact': 'Contacto principal de A/R',
    'Bond Information': 'Información sobre bonos',
    'Reconciliation': 'Reconciliación',
    'Power of Attorney': 'Poder legal',
    'FTA Certificate': 'Certificado FTA',
    'Product File Validation': 'Validación de archivo de producto',
    'Shipper Export': 'Exportador de remitentes',
    'Auto Generate Invoice': 'Generar factura automáticamente',
    'Invoice creation for Entry': 'Creación de facturas para entrada',
    'Centers of Excellence and Expertise': 'Centros de Excelencia y Experiencia',
    'Other Information': 'Otra información',
    'Entry Summary': 'Resumen de la entrada',
    'General Indicators': 'Indicadores generales',
    'Balance and Credit Days': 'Días de saldo y crédito',
    'EDI General Indicators': 'Indicadores generales del EDI',
    'EDI Entries Indicators': 'Indicadores de entradas EDI',
    'EDI Shipper Export Indicators': 'Indicadores de exportación de remitentes EDI',
    'EDI Inbond Indicators': 'Indicadores de EDI Inbond',
    'EDI ISF Indicators': 'Indicadores EDI ISF',
    'EManifest': 'Manifiesto EMisa',
    'Inbonds': 'Encadenamientos',
    'Recon': 'Reconocimiento',
    'E-Manifest': 'Manifiesto electrónico',
    'Electronic Exports': 'Exportaciones electrónicas',
    'General Documents': 'Documentos generales',
    'Wharehouse': 'Almacén',
    'Main contact name': 'Nombre del contacto principal',
    'Main contact phone': 'Teléfono de contacto principal',
    'Owner (Whse)': 'Propietario (Whse)',
    'Poa on file': 'Poa en archivo',
    'Poa begin date': 'Fecha de inicio de la poa',
    'Poa end date': 'Fecha de finalización del Poa',
    'Companies': 'Empresas',
    'Active Since': 'Activo desde',
    'Create Alias': 'Crear alias',
    'Edit Alias': 'Editar alias',
    'Update Alias': 'Actualizar alias',
    'Delete Alias': 'Eliminar alias',
    'Aliases List': 'Listado de alias',
    'Alias': 'Alias',
    'License': 'Licencia',
    'Part number': 'Número de pieza',
    'DDTC License Summary': 'Resumen de licencia DDTC',
    'Exporter Key': 'Clave de exportador',
    'Exporter IRS/ISSN': 'Exportador IRS/ISSN',
    'HTS Code': 'Código HTS',
    'HTS Description': 'Descripción de HTS',
    'Last updated by': 'Última actualización por',
    'DDTC Licenses': 'Licencias DDTC',
    'Description': 'Descripción',
    'Quantity': 'Cantidad',
    'Unit': 'Unidad',
    'Value': 'Valor',
    'Record details': 'Detalles del registro',
    'Packages': 'Paquetes',
    'Packages Unit Metric': 'Unidad de paquetes métrica',
    'Weight': 'Peso',
    'Weight Unit Metric': 'Unidad de peso métrica',
    'Create Traffic Doc': 'Crear documento de tráfico',
    'Edit Traffic Doc': 'Editar documento de tráfico',
    'Update Traffic Doc': 'Actualizar documento de tráfico',
    'Delete Traffic Doc': 'Eliminar documento de tráfico',
    'Line Items List': 'Lista de artículos de línea',
    'Weight': 'Peso',
    'Weight UOM': 'Peso Unidad',
    'Pkgs': 'Paquetes',
    'Pkgs UOM': 'Paquetes Unidad',
    'Date From': 'Fecha desde',
    'Date To': 'Fecha hasta',
    'Arr/Dep Date From': 'Fecha de llegada/salida desde',
    'Arr/Dep Date To': 'Fecha de llegada/salida hasta',
    'Number (Leave empty to assign next available consecutive number)': 'Número (Dejar vacío para asignar el siguiente número consecutivo disponible)',
    'Traffic (Leave empty to assign next available consecutive number': 'Tráfico (Dejar vacío para asignar el siguiente número consecutivo disponible)',
    'Entry Number': 'Número de entrada',
    'Inbond Number': 'Número de entrada',
    'Header': 'Encabezamiento',
    'Line Items': 'Artículos de línea',
    'Delivery Order': 'Orden de entrega',
    'Free Time Exp.': 'Tiempo libre Exp.',
    'Arr/Dep Date': 'Fecha de llegada/salida',
    'Customer Ref': 'Referencia del cliente',
    'Consignee Country': 'País consignatario',
    'Consignee State': 'Estado consignatario',
    'Consignee City': 'Ciudad del destinatario',
    'Consignee ZIP': 'Código postal del destinatario',
    'Consignee Address': 'Dirección del destinatario',
    'Consignee Phone': 'Teléfono del destinatario',
    'Consignee Contact': 'Contacto del destinatario',
    'Vessel': 'Buque',
    'Trucking': 'Camionaje',
    'Trucking Company Country': 'País de la empresa de transporte',
    'Trucking Company State': 'Estado de la empresa de transporte por carretera',
    'Trucking Company City': 'Ciudad de la empresa de transporte',
    'Trucking Company ZIP': 'Código postal de la empresa de transporte',
    'Trucking Company Address': 'Dirección de la empresa de transporte',
    'Trucking Company Phone': 'Teléfono de la empresa de transporte',
    'Trucking Company Contact': 'Contacto de la empresa de transporte por carretera',
    'Route': 'Ruta',
    'BL or AWB': 'BL o AWB',
    'Loc City': 'ciudad local',
    'Loc ZIP': 'Ubicación ZIP',
    'Contact': 'Contacto',
    'Loc Address': 'Dirección de ubicación',
    'Other Delivery Order Info': 'Otra información de pedido de entrega',
    'Prepaid': 'Pagado',
    'Origin/Dest': 'Origen/Destino',
    'Pickup Location': 'Ubicación de recogida',
    'Delivery Order document was not found in database.': 'El documento de orden de entrega no se encontró en la base de datos.',
    'Delivery Order': 'Orden de entrega',
    'Other Information': 'Otra información',
    'Arr/Dep Date': 'Fecha de llegada/salida',
    'Loc. of Goods': 'Loc. de bienes',
    'Pickup Location': 'Ubicación de recogida',
    'Delivery Orders': 'Órdenes de entrega',
    'File name': 'Nombre del archivo',
    'File size': 'Tamaño del archivo',
    'Created at': 'Creado en',
    'Download': 'Descargar',
    'Upload files': 'Subir archivos',
    'Subject': 'Asunto',
    'Document Template': 'Plantilla de documento',
    'TEXT': 'TEXTO',
    'Email Template Details': 'Detalles de la plantilla de correo electrónico',
    'Body': 'Contenido',
    'Email Templates': 'Plantillas de correo electrónico',
    'Format': 'Formato',
    'Enabled': 'Activado',
    'Document Type': 'Tipo de documento',
    'US Destination port': 'Puerto de destino de EE. UU.',
    'Foreign Destination port': 'Puerto de destino extranjero',
    'Exit estimated date': 'Fecha estimada de salida',
    'Mex. Pedimento': 'Mex. Pedimento',
    'Onward Carrier': 'Transportista hacia adelante',
    'Bonded IRS': 'IRS garantizado',
    'BL Type': 'Tipo BL',
    'Shipper': 'Expedidor',
    'Address 3': 'Dirección 3',
    'Broker': 'Corredor',
    'Broker port': 'Puerto del corredor',
    'Broker office': 'Oficina de corredora',
    'US Arrive port': 'Puerto de llegada de EE. UU.',
    'Foreign load port': 'Puerto de carga extranjero',
    'Foreign receipt port': 'Puerto de recepción extranjero',
    'Shipment Info': 'Información de envío',
    'Transmission': 'Transmisión',
    'Shipper Company': 'Empresa Transportista',
    'Consignee Company': 'Empresa consignataria',
    'Broker Download / Places': 'Corredora Descarga / Lugares',
    'Inbond Info': 'Información Inbond',
    'Create Shipment': 'Crear envío',
    'Edit Shipment': 'Editar envío',
    'Update Shipment': 'Actualizar envío',
    'Delete Shipment': 'Eliminar envío',
    'List Shipments': 'Listar envíos',
    'Shipments': 'Envíos',
    'Create Shipment': 'Crear envío',
    'Number': 'Número',
    'Bonded ID': 'Identificación garantizada',
    'Accepted': 'Aceptado',
    'Origin': 'Origen',
    'Create Shipment Detail': 'Crear detalle de envío',
    'Edit Shipment Detail': 'Editar detalle de envío',
    'Update Shipment Detail': 'Actualizar detalle de envío',
    'Confirm Delete Shipment Detail': 'Confirmar eliminar detalle del envío',
    'Delete Shipment': 'Eliminar envío',
    'List Shipment Details': 'Listar detalles de envío',
    'Shipment Details': 'Detalles de envío',
    'Create Shipment Detail': 'Crear detalle de envío',
    'Trip number (Leave blank to use next available number)': 'Número de viaje (Dejar en blanco para usar el siguiente número disponible)',
    'Traffic (Leave blank to use next available number)': 'Tráfico (Dejar en blanco para usar el siguiente número disponible)',
    'Create Seal': 'Crear sello',
    'Edit Seal': 'Editar sello',
    'Update Seal': 'Actualizar sello',
    'Confirm Delete Seal': 'Confirmar Eliminar Sello',
    'Delete Seal': 'Eliminar sello',
    'List Seals': 'Lista de sellos',
    'Seals': 'Sellos',
    'Create Seal': 'Crear sello',
    'Equipment': 'Equipo',
    'Equipment Type': 'Tipo de equipo',
    'Equipment ITT': 'Equipo ITT',
    'Edit Equipment': 'Editar equipo',
    'Create Equipment': 'Crear equipo',
    'Update Equipment': 'Actualizar equipo',
    'Confirm Delete Equipment': 'Confirmar Eliminar Equipo',
    'Delete Equipment': 'Eliminar equipo',
    'List Equipments': 'Listar equipos',
    'Equipments of Carrier': 'Equipos de transporte',
    'Create Equipment': 'Crear equipo',
    'License plates': 'Placas de matrícula',
    'Return to Emanifest Form': 'Volver al formulario de manifiesto',
    'Create License Plate': 'Crear matrícula',
    'Edit License Plate': 'Editar matrícula',
    'Update License Plate': 'Actualizar matrícula',
    'Confirm Delete License Plate': 'Confirmar eliminar matrícula',
    'Delete License Plate': 'Eliminar matrícula',
    'List License Plates': 'Lista de matrículas',
    'License Plates': 'Placas de matrícula',
    'Create License Plate': 'Crear placa de matrícula',
    'Crew': 'Miembros',
    'Add Member': 'Agregar miembro',
    'Driver': 'Conductor',
    'Conveyance ID': 'ID de transporte',
    'Transponter': 'Transpondedor',
    'Conveyance Type': 'Tipo de transporte',
    'DOT Number': 'Número DOT',
    'Create Conveyance': 'Crear transporte',
    'Edit Conveyance': 'Editar transporte',
    'Update Conveyance': 'Actualizar transporte',
    'Confirm Delete Conveyance': 'Confirmar eliminar transporte',
    'Delete Conveyance': 'Eliminar transporte',
    'List Conveyances': 'Lista de transportes',
    'Conveyances of Carrier': 'Medios de transporte del transportista',
    'Driver type': 'Tipo de conductor',
    'Nationality': 'Nacionalidad',
    'Proxy Card': 'Tarjeta Proxy',
    'DL Country': 'País de licencia',
    'DL State': 'Estado de la licencia de manejar',
    'Expiration Date': 'Fecha de expiración',
    'Hazardous materials': 'Materiales peligrosos',
    'Cross Doc': 'Documento de frontera',
    'Cross Doc type': 'Tipo de documento de frontera',
    'Country Iss': 'País Iss',
    'Cross Expiration Date': 'Fecha de vencimiento frontera',
    'General Info': 'Información general',
    'Drivers License': 'Licencia de conducir',
    'Crossing Document': 'Documento de frontera',
    'Create Driver': 'Crear conductor',
    'Edit Driver': 'Editar conductor',
    'Update Driver': 'Actualizar conductor',
    'Confirm Delete Driver': 'Confirmar eliminar conductor',
    'Delete Driver': 'Eliminar conductor',
    'List Drivers': 'Lista de conductores',
    'Drivers': 'Conductores',
    'Create Driver': 'Crear conductor',
    'License country': 'País de licencia',
    'License state': 'Estado de licencia',
    'License expiration': 'Caducidad de la licencia',
    'License hazardous': 'Licencia para materiales peligrosos',
    'Trip Number': 'Número de viaje',
    'Arrival Date': 'Fecha de llegada',
    'Arrival Time': 'Hora de llegada',
    'Transmission action': 'Acción de transmisión',
    'Transmission RFA': 'Transmisión RFA',
    'Status': 'Estado',
    'Finalized at': 'Finalizado en',
    'Released at': 'Publicado en',
    'Fast': 'Rápido',
    'QR Page': 'Página QR',
    'Conveyance License Plate': 'Placa de matrícula de transporte',
    'Equipment License Plate': 'Placa de matrícula del equipo',
    'Location company': 'Empresa de localización',
    'E-Manifest document was not found in database.': 'El documento del manifiesto electrónico no se encontró en la base de datos.',
    'Validate': 'Validar',
    'Trip Info': 'Información de viaje',
    'Transmission/Status': 'Transmisión/Estado',
    'Other indicators': 'Otros indicadores',
    'Conveyance / Equipment': 'Transporte / Equipo',
    'Select Conveyance License plate': 'Seleccione Matrícula de transporte',
    'Select Equipment License plate': 'Seleccione Matrícula de equipo',
    'Delivery Locations': 'Lugares de entrega',
    'Trip ID': 'ID de viaje',
    'Arrival date from': 'Fecha de llegada desde',
    'Arrival Port': 'Puerto de llegada',
    'EManifests Summary': 'Resumen de manifiestos electrónicos',
    'Driver name': 'Nombre del conductor',
    'Customer Reference': 'Referencia del cliente',
    'Rel. Date': 'Rel. Fecha',
    'Shipper Name': 'Nombre de expedidor',
    'Consignee Name': 'Nombre del consignatario',
    'Shipment count': 'Cantidad de envíos',
    'EManifests': 'EManifiestos',
    'EManifests Summary': 'Resumen de manifiestos electrónicos',
    'Entry type': 'Tipo de entrada',
    'Entry date': 'Fecha de entrada',
    'Est. Arr.': 'Est. Arr.',
    'Rel. Date': 'Rel. Fecha',
    'Rel. Time': 'Rel. Hora',
    'Sign Date': 'Fecha de firma',
    'Split shipment': 'Envío dividido',
    'Perishable': 'Perecedero',
    '3461 User': '3461 Usuario',
    'Certify Entry': 'Certificar entrada',
    'Entered value ($)': 'Valor ingresado ($)',
    'Gross weight (Kgs)': 'Peso bruto (Kgs)',
    'Vessel code': 'Código del barco',
    'Trip number': 'Número de viaje',
    'Vessel/Conveyance': 'Buque/Transporte',
    'Exporter country': 'País exportador',
    'Fn. Port Lad': 'Puerto extranjero de arrivo',
    'U.S. Port Unld': 'Puerto de EE. UU.',
    'Dest. Loc.': 'Destino. Loc.',
    'Audited file': 'Archivo auditado',
    'Pedimento': 'Pedimento',
    'Pedimento Date': 'Fecha del pedimento',
    'Destination': 'Destino',
    'RDD Date': 'Fecha del RDD',
    'Plant code': 'Código de planta',
    'Parties': 'Partes',
    'Values / Quantities': 'Valores / Cantidades',
    'Conveyance / Vessel': 'Transporte / Buque',
    'Places': 'Lugares',
    'Custom information': 'Información personalizada',
    'Import date': 'Fecha de importación',
    'Arrival date': 'Fecha de llegada',
    'Export date': 'Fecha de exportación',
    'Live': 'Activo',
    'ES User': 'Usuario ES',
    'Req. Paperless': 'Requerido Sin papel',
    'Request at': 'Solicitar en',
    'Response at': 'Respuesta en',
    'Last received response at': 'Última respuesta recibida en',
    'Reconciliation issue': 'Problema de reconciliación',
    'FTA Recon': 'Reconocimiento del TLC',
    'Payment type': 'Tipo de pago',
    'Deferred Tax': 'Impuesto Diferido',
    'Reconciliation / Entered Value & Weight': 'Conciliación/Valor y peso ingresados',
    'Payment': 'Pago',
    'Entry PGA Status': 'Estado de entrada de la PGA',
    'Status Date': 'Estado Fecha',
    'Message': 'Mensaje',
    'Role': 'Rol',
    'Qualifier': 'Qualificador',
    'Create AOC': 'Crear AOC',
    'Edit AOC': 'Editar AOC',
    'Update AOC': 'Actualizar AOC',
    'Delete AOC': 'Eliminar AOC',
    'Entity Key': 'Clave de entidad',
    'FDA code (Industry)': 'Código FDA (Industria)',
    'FDA code (Class)': 'Código FDA (Clase)',
    'FDA code (Subclass)': 'Código FDA (Subclase)',
    'FDA code (PIC)': 'Código FDA (PIC)',
    'FDA code (Product)': 'Código FDA (Producto)',
    'Industry': 'Industria',
    'Class': 'Clase',
    'Subclass': 'Subclase',
    'PIC': 'PIC',
    'Product': 'Producto',
    'Build FDA Code': 'Construir código FDA',
    'Apply': 'Aplicar',
    'Create Country': 'Crear país',
    'Update  Country': 'Actualizar país',
    'Delete Country': 'Eliminar país',
    'Edit Country': 'Editar país',
    'Create Entity': 'Crear entidad',
    'Update  Entity': 'Actualizar entidad',
    'Delete Entity': 'Eliminar entidad',
    'Edit Entity': 'Editar entidad',
    'Supplier ID': 'ID de proveedor',
    'Supplier Number': 'Número de proveedor',
    'Entered value': 'Valor ingresado',
    'Create Invoice': 'Crear factura',
    'Update  Invoice': 'Actualizar factura',
    'Delete Invoice': 'Eliminar factura',
    'Edit Invoice': 'Editar factura',
    'Commercial Invoices': 'Facturas Comerciales',
    'Importing Conveyance Plates': 'Importación de placas de transporte',
    'Plate Country': 'País de la placa',
    'Truck make': 'Marca de camion',
    'Truck owner': 'Propietario del camión',
    'Truck owner city and state': 'Ciudad y estado del propietario del camión',
    'Truck owner address': 'Dirección del propietario del camión',
    'Fast shipment': 'Envío rápido',
    'Haz-Mat': 'Materiales peligrosos',
    'Sticker': 'Etiqueta engomada',
    'Trailer': 'Tráiler',
    'Trailer make': 'Marca del remolque',
    'Importing trailer plates': 'Importación de placas de remolque',
    'Importing Plate Country': 'País importador de placa',
    'Person in charge': 'Persona responsable',
    'Foreign port of lading': 'Puerto de embarque extranjero',
    'Description template': 'Plantilla de descripción',
    'Additional description': 'Descripción adicional',
    'Inward Manifest & Other Info': 'Manifiesto interno y otra información',
    'Plate State': 'Estado de la placa',
    'Importing Plate State': 'Importación del estado de la placa',
    'Inbond Date': 'Fecha de entrada',
    'SCAC Code': 'Código SCAC',
    'Unit metric': 'Unidad de medida',
    'Container/Equipment #': 'Contenedor/Equipo #',
    'Less than full Qty': 'Cantidad inferior a la completa',
    'ACE Disposition': 'Disposición de ACE',
    'Create BL': 'Crear BL',
    'Update  BL': 'Actualizar BL',
    'Delete BL': 'Eliminar BL',
    'Edit BL': 'Editar BL',
    'Inbond No.': 'Inbond No.',
    'Container #': 'Contenedor #',
    'Invoice': 'Factura',
    'Part number': 'Número de pieza',
    'Condition Code 1': 'Código de condición 1',
    'Override Code 1': 'Sobreescribir código 1',
    'Condition Code 2': 'Código de condición 2',
    'Override Code 2': 'Sobreescribir código 2',
    'Entry line': 'Línea de entrada',
    'Origin': 'Origen',
    'Export': 'Exportar',
    'Save HTS to Importer profile': 'Guardar HTS en el perfil del importador',
    'Related party': 'Parte relacionada',
    'FTA Net Cost': 'Costo neto del TLC',
    'U.S. Packing': 'Embalaje en EE. UU.',
    'Quantity 1': 'Cantidad 1',
    'Charges': 'Cargos',
    'Quantity 2': 'Cantidad 2',
    'Quantity 3': 'Cantidad 3',
    'Duties': 'Deberes',
    'Fee Accounting Class Code': 'Código de clase de contabilidad de tarifas',
    'Manufacturer': 'Fabricante',
    'Sold to': 'Vendido a',
    'Fgn. Export': 'Fgn. Exportar',
    'Shipper': 'Expedidor',
    'Action': 'Acción',
    'FDA line value': 'Valor de línea de la FDA',
    'Intended use code': 'Código de uso previsto',
    'Intended description': 'Descripción prevista',
    'Brand name': 'Nombre de marca',
    'Disclaimer': 'Descargo de responsabilidad',
    'Program code': 'Código de programa',
    'Processing code': 'Código de procesamiento',
    'Quantity 4': 'Cantidad 4',
    'Quantity 5': 'Cantidad 5',
    'Base Quantity Total': 'Cantidad base total',
    'Dimension 1': 'Dimensión 1',
    'Dimension 2': 'Dimensión 2',
    'Dimension 3': 'Dimensión 3',
    'Container': 'Recipiente',
    'Number': 'Número',
    'Time': 'Tiempo',
    'Degree type': 'Tipo de grado',
    'Degrees': 'Grados',
    'Entry Line - Part Info': 'Línea de entrada - Información de la pieza',
    'Census': 'Censo',
    'Entry Line - General HTS Info; PGA Codes: FD1': 'Línea de entrada: información general de HTS; Códigos PGA: FD1',
    'Duties/Fees/Taxes': 'Derechos/tasas/impuestos',
    'General FDA Info': 'Información general de la FDA',
    'Agency Codes': 'Códigos de agencia',
    'FDA Quantities': 'Cantidades FDA',
    'Container Dimensions (feet)': 'Dimensiones del contenedor (pies)',
    'Prior Notice': 'Aviso Previo',
    'Temperature': 'Temperatura',
    'Entities': 'Entidades',
    'Countries': 'Países',
    'Message 1': 'Mensaje 1',
    'Message 2': 'Mensaje 2',
    'Message 3': 'Mensaje 3',
    'Message 4': 'Mensaje 4',
    'Message 5': 'Mensaje 5',
    'Message 6': 'Mensaje 6',
    'System notes': 'Notas del sistema',
    'Miss. Doc 1': 'Doc. No disponible 1',
    'Miss. Doc 2': 'Doc. No disponible 2',
    'CBP team': 'Equipo CBP',
    'Legacy CBP team': 'Equipo legacy CBP',
    'Signature': 'Firma',
    'Accepted': 'Aceptado',
    'Accepted Date': 'Fecha de Aceptación',
    'Docs. Required': 'Documentos Requerido',
    'Census Override': 'Sobreescribir censo',
    'Other Warns.': 'Otras advertencias',
    'Rejected': 'Rechazado',
    'PSC Status': 'Estado del PSC',
    'Entry Certified': 'Certificado de entrada',
    'Rel. Date': 'Rel. Fecha',
    'Line Release': 'Liberación de línea',
    'Intensive': 'Intensivo',
    'Quota Status': 'Estado de la cuota',
    'Duty': 'Debe',
    'Other': 'Otro',
    'Ammount': 'Cantidad',
    'Extension': 'Extensión',
    'Sched Date': 'Fecha programada',
    'Daily': 'Diariamente',
    'Check': 'Controlar',
    'Status / Totals': 'Estado / Totales',
    'Cert. / Crossing': 'Certificado. / Cruce',
    'Liquidation': 'Liquidación',
    'Total': 'Total',
    'Totals': 'Totales',
    'Statement': 'Declaración',
    'Flight/Trip': 'Vuelo/Viaje',
    'Vessel Name': 'Nombre del barco',
    'Number (Leave blank to apply entry sequence)': 'Número (Déjelo en blanco para aplicar la secuencia de entrada)',
    'Traffic number (Leave blank to assign new traffic)': 'Número de tráfico (Déjelo en blanco para asignar tráfico nuevo)',
    'Cargo Release 3461': 'Liberación de carga 3461',
    'Entry Summ 7501': 'Suma de entrada 7501',
    'Quote': 'Cita',
    'BL Number': 'Número de BL',
    'BL Quantity': 'Cantidad de BL',
    'BL Unit Metric': 'Unidad BL métrica',
    'Enter Commercial Invoices': 'Ingresar Facturas Comerciales',
    'Template': 'Plantilla',
    'Previous Entry': 'Entrada anterior',
    '7501 Entry Summary': 'Resumen de entrada 7501',
    '3461 Cargo Release': '3461 Liberación de carga',
    'Document Link': 'Enlace al documento',
    'Save as template': 'Guardar como plantilla',
    'Template name': 'Nombre de la plantilla',
    'General Info': 'Información general',
    'References': 'Referencias',
    'Initialize Entry From': 'Inicializar entrada desde',
    'Transmission done!': '¡Transmisión realizada!',
    'Entry document was not found in database.': 'El documento de entrada no se encontró en la base de datos.',
    'Verify / Transmit': 'Verificar / Transmitir',
    'Customer must be specified.': 'Se debe especificar el cliente.',
    'Carrier must be specified.': 'Se debe especificar el transportista.',
    'Entry Summary': 'Resumen de entrada',
    'Certification date': 'Fecha de certificación',
    'Release date': 'Fecha de lanzamiento',
    'Release time': 'Tiempo de liberación',
    'Acc.': 'Acc.',
    'Acc. Date': 'Acc. Fecha',
    'TIB Status': 'Estado de la TIB',
    'Last CBP Disp': 'Última entrega CBP',
    'Last BL Disp': 'Última entrega BL',
    'ACE FDA Prior': 'ACE FDA anterior',
    'Pmt. Date': 'Pmt. Fecha',
    'CBP Stmt No.': 'CBP Stmt No.',
    'Warning': 'Advertencia',
    'Days Olds': 'Días de edad',
    'Liq. Date': 'Fecha de liquidación',
    'Pay Own Duties': 'Pagar sus propios deberes',
    'Detained': 'Detenido',
    'Entries': 'Entradas',
    'Import Excel (*.xlsx)': 'Importar Excel (*.xlsx)',
    'Certification': 'Certificación',
    'Truck E-Manifest': 'Manifiesto electrónico del camión',
    'Same condition to NAFTA countries - The undersigned herein certifies that the merchandise herein described is in the same condition as when it was imported under above import entry(ies) and further certifies that this merchandise was not subjected to any process of manufacturer or other operation except the following operations:': 'Misma condición para los países del TLCAN: el abajo firmante certifica que la mercancía aquí descrita se encuentra en las mismas condiciones que cuando fue importada bajo las entradas de importación anteriores y certifica además que esta mercancía no fue sujeta a ningún proceso de fabricación u otra operación, excepto las siguientes operaciones:',
    'Allowable operations': 'Operaciones permitidas',
    'The undersigned hereby certifies that the merchandise herein described is unused in the United States and further certifies that this merchandise was not subjected to any process of manufacture or other operation except the following allowable operations:': 'El abajo firmante certifica por la presente que la mercancía aquí descrita no se utiliza en los Estados Unidos y certifica además que esta mercancía no fue sujeta a ningún proceso de fabricación u otra operación, excepto las siguientes operaciones permitidas:',
    'The undersigned hereby certifies that the merchandise herein described is commercially interchangeable with the designated imported merchandise and further certifies that the substituted merchandise is unused in the United States and that the substituted merchandise was in our possession prior to exportation or destruction.': 'El abajo firmante certifica por la presente que la mercancía aquí descrita es comercialmente intercambiable con la mercancía importada designada y además certifica que la mercancía sustituida no se utiliza en los Estados Unidos y que la mercancía sustituida estaba en nuestra posesión antes de su exportación o destrucción.',
    'Merchandise does not conform to sample or specifications.': 'La mercancía no se ajusta a la muestra ni a las especificaciones.',
    'Merchandise was defective at time of importation.': 'La mercancía estaba defectuosa al momento de la importación.',
    'Merchandise was shipped without consent of the consignee.': 'La mercancía fue enviada sin consentimiento del consignatario.',
    'The undersigned hereby certifies that the merchandise herein described is the same kind and quality as defined in 19 U.S.C. 1313(p)(3)(B), with the designated imported merchandise or the article manufactured or produced under 1313(a) or (b), as appropriate.': 'El abajo firmante certifica por la presente que la mercancía aquí descrita es del mismo tipo y calidad según se define en 19 U.S.C. 1313(p)(3)(B), con la mercancía importada designada o el artículo fabricado o producido conforme a 1313(a) o (b), según corresponda.',
    'The article(s) described above were manufactured or produced and disposed of as stated herein in accordance with the drawback ruling on file with CBP and in compliance with applicable laws and regulations.': 'Los artículos descritos anteriormente fueron fabricados o producidos y eliminados como se indica en este documento de acuerdo con la resolución de devolución registrada en CBP y de conformidad con las leyes y regulaciones aplicables.',
    'Member of Firm with Power of Attorney': 'Miembro de Firma con Poder',
    'Officer of Corporation': 'Oficial de Corporación',
    'Broker with Power of Attorney': 'Corredor con poder notarial',
    'Fee Code': 'Código de tarifa',
    'Amount': 'Cantidad',
    'Rate': 'Tasa',
    'Create Fee': 'Crear tarifa',
    'Update Fee Tax': 'Impuesto de tarifa de actualización',
    'Delete Fee Tax': 'Eliminar impuesto de tarifa',
    'Save Fee Tax': 'Guardar impuesto de tarifa',
    'Confirm delete Fee Tax': 'Confirmar eliminar impuesto de tarifa',
    'List Fee Taxes': 'Lista de impuestos de tarifas',
    'Drawback Amount': 'Monto del Reintegro',
    'Unit of Measure': 'Unidad de medida',
    'HTSUS Number': 'Número HTSUS',
    'Schedule B': 'Horario B',
    'Create Line Item': 'Crear línea de pedido',
    'Import Entry Number': 'Número de entrada de importación',
    'Line Item Number': 'Número de artículo de línea',
    'Merchandise Description': 'Descripción de la mercancía',
    'Drawback Ammount': 'Importe del reembolso',
    'Drawback Quantity': 'Cantidad de devolución',
    'Unit Metric': 'Unidad métrica',
    'Update Line Item': 'Actualizar línea de pedido',
    'Delete Line Item': 'Eliminar línea de pedido',
    'Save Line Item': 'Guardar línea de pedido',
    'Confirm delete Line Item': 'Confirmar eliminación de línea de pedido',
    'List Line Items': 'Listar líneas de pedido',
    'Preview': 'Avance',
    'Quantity Used': 'Cantidad usada',
    'Description Used': 'Descripción Usado',
    'Production Date': 'Fecha de producción',
    'Quantity Prod.': 'Cantidad Prod.',
    'Description Prod.': 'Descripción Prod.',
    'Create Manufactured Article': 'Crear artículo fabricado',
    'Import Date': 'Fecha de importación',
    'Date Received': 'Fecha de recepción',
    'Date Used': 'Fecha de uso',
    'Description': 'Descripción',
    'Entered Value': 'Valor ingresado',
    'Duty Rate': 'Tasa de servicio',
    'Update Duty Paid': 'Actualizar derechos pagados',
    'Delete Duty Paid': 'Eliminar derechos pagados',
    'Save Duty Paid': 'Guardar derechos pagados',
    'Confirm delete Duty Paid': 'Confirmar eliminación Derechos pagados',
    'List Duty Paids': 'Lista de derechos pagados',
    'Unique Number': 'Número único',
    'Exporter/Destroyer Name': 'Nombre del exportador/destructor',
    'Action Code': 'Código de acción',
    'Update Destroyed Article': 'Actualizar artículo destruido',
    'Delete Destroyed Article': 'Eliminar artículo destruido',
    'Save Destroyed Article': 'Guardar artículo destruido',
    'Confirm delete Destroyed Article': 'Confirmar eliminar artículo destruido',
    'List Destroyed Articles': 'Listar artículos destruidos',
    'Printed Name and Title': 'Nombre y título impresos',
    'Entry Type Code': 'Código de tipo de entrada',
    'Surety Code': 'Código de Fianza',
    'Claimant ID Number': 'Número de identificación del reclamante',
    'Broker ID Number': 'Número de identificación del corredor',
    'DBK Ruling Number': 'Número de resolución DBK',
    'Total Mpf Claimed': 'Mpf total reclamado',
    'Total Drawback Claimed': 'Reembolso total reclamado',
    'Total I.R. Tax Claimed': 'I.R. total Impuesto reclamado',
    'Method of Filing': 'Método de presentación',
    'Privilege Authorized': 'Privilegio autorizado',
    'Drawback Section': 'Sección de inconvenientes',
    'Name and Address of Claimant': 'Nombre y dirección del reclamante',
    'Contact Name, Address, Phone & Fax Numbers of Preparer': 'Nombre de contacto, dirección, teléfono y números de fax del preparador',
    'Exhibits to be attached': 'Anexos que se adjuntarán',
    'Unique Indentifier No.': 'Número de identificador único',
    'Exporter/Destroyer': 'Exportador/Destructor',
    'Quantity': 'Cantidad',
    'Create Destroyed Article': 'Crear artículo destruido',
    'Port Code': 'Código de puerto',
    '99% Duty': '99% Debe',
    'Create Duty Paid': 'Crear derechos pagados',
    'Quantity Produced': 'Cantidad producida',
    'Description Produced': 'Descripción Producido',
    'Factory Location': 'Ubicación de la fábrica',
    'Update Manufactured Article': 'Actualizar artículo fabricado',
    'Delete Manufactured Article': 'Eliminar artículo fabricado',
    'Save Manufactured Article': 'Guardar artículo fabricado',
    'Confirm delete Manufactured Article': 'Confirmar eliminar artículo fabricado',
    'List Manufactured Articles': 'Listar artículos fabricados',
    'Exporter Street 1': 'Calle Exportador 1',
    'Exporter Street 2': 'Calle Exportador 2',
    'Exporter City': 'Ciudad Exportadora',
    'Exporter State': 'Estado exportador',
    'Exporter ZIP': 'ZIP del exportador',
    'Identifier Type': 'Tipo de identificador',
    'Exporter Identifier': 'Identificador del exportador',
    'Broker Street 1': 'Calle de corredora 1',
    'Broker Street 2': 'Calle de corredora 2',
    'Broker City': 'Ciudad de corredora',
    'Broker State': 'Estado de corredora',
    'Broker ZIP': 'Código postal de corredora',
    'Broker Phone': 'Teléfono de corredora',
    'Broker Ext': 'Extensión de corredora',
    'Broker Email': 'Correo electrónico de corredora',
    'Intended Export Port': 'Puerto de exportación previsto',
    'Exporting Carrier': 'Transportista exportador',
    'Intended Action': 'Acción prevista',
    'Intended Date': 'Fecha prevista',
    'Drawback Office': 'Oficina de devolución',
    'Merchandise Location': 'Ubicación de la mercancía',
    'Destruction Method': 'Método de destrucción',
    'Destruction Location': 'Ubicación de destrucción',
    'T & E Number': 'Número de T & E',
    'Destination Country': 'País de destino',
    'Unused Merchandise Drawback J1': 'Mercancía no utilizada Devolución J1',
    'Unused Merchandise Drawback J2': 'Mercancía no utilizada Devolución J2',
    'Manufacturing Drawback': 'Inconveniente de fabricación',
    'Same Condition Drawback under NAFTA/USMCA': 'Reembolso por la misma condición según el TLCAN/USMCA',
    'Distilled Spirits, Wine or Beer under 26 U.S.C. 5062 (c)': 'Bebidas espirituosas destiladas, vino o cerveza según 26 U.S.C. 5062c)',
    'Rejected Merchandise': 'Mercancía rechazada',
    'Shipped without Consent': 'Enviado sin consentimiento',
    'Defective at Time of Importation': 'Defectuoso en el momento de la importación',
    'Not Conforming to Sample or Specifications': 'No conforme a la muestra o las especificaciones',
    'Returned Retail Merchandise': 'Mercancía minorista devuelta',
    'Page not found': 'Página no encontrada',
    'The requested url was not found on this server': 'La URL solicitada no se encontró en este servidor.',
    'GO HOME': 'IR A INICIO',
    'LOGOUT': 'CERRAR SESIÓN',
    'Page not loaded properly': 'La página no se cargó correctamente',
    'The requested url was not loaded properly on this server': 'La URL solicitada no se cargó correctamente en este servidor.',
    'Access denied': 'Acceso denegado',
    'Access to this resource on the server is denied': 'El acceso a este recurso en el servidor está denegado.',
    'Flexible reports': 'Informes flexibles',
    'Create Mark': 'Crear marca',
    'Edit Mark': 'Editar marca',
    'Update Mark': 'Actualizar marca',
    'Confirm Delete Mark': 'Confirmar eliminar marcar',
    'Delete Mark': 'Eliminar marca',
    'Marks': 'Marcas',
    'From CBP Doc': 'Desde documento de CBP',
    'Date of Entry': 'Fecha de entrada',
    'To CBP Doc': 'Hasta documento de CBP',
    'Date of Arrival': 'Fecha de llegada',
    'Bill of Lading': 'Guía de carga',
    'Total Packgs': 'Paquetes totales',
    'Qty Discrepancies': 'Discrepancias de cantidad',
    'Vehicle License': 'Licencia de vehículo',
    'Custodial Bond': 'Fianza de custodia',
    'C.H. License': 'C.H. Licencia',
    'Truck Number': 'Número de camión',
    'Delivery Propiertor': 'Propietario de entrega',
    'Company Name/Sig': 'Nombre/firma de la empresa',
    'File By': 'Archivar por',
    'Satisfied Date': 'Fecha Satisfecha',
    'Goods Delivered From': 'Bienes entregados desde',
    'Goods Delivered To': 'Bienes entregados a',
    'Additional Information': 'Información adicional',
    'Lien Information': 'Información sobre el gravamen',
    'GO Number (Leave blank to use next available number)': 'Número GO (Déjelo en blanco para usar el siguiente número disponible)',
    'Traffic (Leave blank to assign new Traffic)': 'Tráfico (Déjelo en blanco para asignar nuevo tráfico)',
    'From Entry (Leave blank to use next available number)': 'Desde la entrada (deje en blanco para usar el siguiente número disponible)',
    'From Inbond': 'Desde Inbond',
    'Include Detail Line Information': 'Incluir información de línea de detalle',
    'General Order No.': 'Orden General No.',
    'Traffic No.': 'Tráfico No.',
    'Entry No.': 'Entrada No.',
    'Voided': 'Anulado',
    'General Order document was not found in database.': 'El documento de Orden General no se encontró en la base de datos.',
    'G.O. Summary': 'Resumen de GO',
    'From Type': 'Desde el tipo',
    'Inbond From': 'Desde el Inbond',
    'To Type': 'Para escribir',
    'Inbond To': 'Hasta el Inbond',
    'Void': 'Vacío',
    'Delivered From': 'Entregado desde',
    'Delivered To': 'Entregado hasta',
    'CF-6043 General Orders': 'CF-6043 Órdenes Generales',
    'From Date >=': 'Desde fecha >=',
    'From Date <=': 'Desde fecha <=',
    'To Date >=': 'Hasta la fecha >=',
    'To Date <=': 'Hasta la fecha <=',
    'Importer HTS File Details': 'Detalles del archivo HTS del importador',
    'Secondary Rate': 'Tarifa secundaria',
    'Quota Quantity': 'Cantidad de cuota',
    'Additional Duties': 'Deberes adicionales',
    'Importer HTS Codes': 'Códigos HTS del importador',
    'Advalorem Rate': 'Tasa Advalorem',
    'Specific Rate': 'Tarifa específica',
    'House SCAC Code': 'Código SCAC House',
    'House Bill of lading': 'Conocimiento de embarque de la casa',
    'Manifest quantity': 'Cantidad manifiesta',
    'Foreign port lading': 'Embarque en puerto extranjero',
    'Bill of lading status': 'Estado del conocimiento de embarque',
    'Notify company': 'Notificar a la empresa',
    'Previous Documents': 'Documentos anteriores',
    'Shipper Information': 'Información del remitente',
    'Consignee Information': 'Información del destinatario',
    'Notify Party Information': 'Notificar información de la parte',
    'Create BL': 'Crear BL',
    'Edit BL': 'Editar BL',
    'Update BL': 'Actualizar BL',
    'Confirm Delete BL': 'Confirmar Eliminar BL',
    'Delete BL': 'Eliminar BL',
    'List BLs': 'Listar BL',
    'Bill of Lading Lines': 'Líneas de guías de carga',
    'BLs List': 'Lista de BL',
    'Port of Lading': 'Puerto de embarque',
    'Importing Conveyance (Truck)': 'Importación de medios de transporte (camión)',
    'Fast shipments': 'Envíos rápidos',
    'Truck make': 'Marca de camion',
    'Truck owner': 'Propietario del camión',
    'Truck owner address': 'Dirección del propietario del camión.',
    'Truck owner city/state': 'Propietario del camión ciudad/estado',
    'Date from': 'Fecha desde',
    'Date to': 'Fecha hasta',
    'Mode of Transport': 'Modo de transporte',
    'Signature Pedimento #': 'Pedimento de firma #',
    'Inbond (Leave blank to use next available number)': 'Inbond (Deje en blanco para usar el siguiente número disponible)',
    'Inbond Type': 'Tipo de inbond',
    'Bill of Ladings': 'Conocimiento de embarque',
    'Inward Manifest': 'Manifiesto interno',
    'Inbond Partials': 'Parciales internos',
    'Inbond Back Form': 'Formulario de devolución interno',
    'Inbond document was not found in database.': 'El documento interno no se encontró en la base de datos.',
    'Verify': 'Verificar',
    'Inbond Summary': 'Resumen interno',
    'Bonded SCAC Code': 'Código SCAC consolidado',
    'Bonded Name': 'Nombre vinculado',
    'Bonded IRS': 'IRS vinculado',
    'Inbond port': 'Puerto de entrada',
    'First US unlading port': 'Primer puerto de descarga de EE. UU.',
    'US destination port': 'Puerto de destino de EE. UU.',
    'Final foreign port': 'Puerto extranjero final',
    'Location of Good': 'Ubicación del bien',
    'Conveyance SCAC Code': 'Código SCAC de transporte',
    'Conveyance name': 'Nombre del medio de transporte',
    'Conveyance country': 'País de transporte',
    'Conveyance trip': 'Viaje de transporte',
    'Conveyance date sailing': 'Fecha de transporte zarpe',
    'Sig. Pedimento #': 'Sig. Pedimento #',
    'Imported via': 'Importado vía',
    'Imported on': 'Importado en',
    'Exporter country': 'País exportador',
    'Exported on': 'Exportado en',
    'Due date': 'Fecha de vencimiento',
    'Arrival date': 'Fecha de llegada',
    'Subject to FDA': 'Sujeto a la FDA',
    'Partial number': 'Numero parcial',
    'Car number': 'Número de coche',
    'Pedimento number': 'Número de pedimento',
    'Locate by Partial No...': 'Localizar por No. Parcial...',
    'Released': 'Liberado',
    'Deliver to Cartman or Lighterman in apparent good condition except as noted on this form': 'Entregar a Cartman o Lighterman en aparente buen estado, excepto lo indicado en este formulario.',
    'Wharehouse propiertor': 'Propietario de almacén',
    'I Certified that within-described goods were transferred by reason of': 'Certiqué que los bienes dentro de la descripción fueron transferidos en razón de',
    'To': 'A',
    'On': 'En',
    'In': 'En',
    'At': 'En',
    'and saled with': 'y vendido con',
    'or seals Nos.': 'o sellos Nos.',
    'and that goods were in same apparent condition as noted on original lading except': 'y que los bienes estaban en la misma condición aparente que se indica en el embarque original, excepto',
    'Inspector, Conductor or Master': 'Inspector, director o maestro',
    'And seals found': 'Y focas encontradas',
    'RECORD OF CARTAGE OR LIGHTERAGE': 'REGISTRO DE ACARREO O ALEAJE',
    'CERTIFICATES OF TRANSFER': 'CERTIFICADOS DE TRANSFERENCIA',
    'INSPECTED': 'INSPECCIONADO',
    'Inbond Type': 'Tipo de inbonds',
    'Entered by': 'Entrado por',
    'Location of Goods': 'Ubicación de mercancías',
    'Imp Carrier SCAC Code': 'Código SCAC del portador del diablillo',
    'Exported from': 'Exportado desde',
    'FDA PE Action': 'Acción de PE de la FDA',
    'Bill on file ace': 'Bill en el archivo as',
    'Inbond Action code': 'Código de acción interna',
    'Inbond transmission': 'transmisión interna',
    'Inbond error free': 'Sin errores internos',
    'Withdrawn': 'Retirado',
    'Entered signature': 'Firma ingresada',
    'Received signature': 'Firma recibida',
    'Plant': 'Planta',
    'Received doc by': 'Documento recibido por',
    'Commercial invoice': 'Factura comercial',
    'Whse ticket': 'Cuyo billete',
    'Canceled by': 'Cancelado por',
    'Other audited file': 'Otro expediente auditado',
    'Inbond #': 'Inbond #',
    'Bonded Carrier Information': 'Información del transportista garantizado',
    'US & Foreign Ports': 'Puertos estadounidenses y extranjeros',
    'Conveyance Information': 'Información de transporte',
    'Status Dates': 'Fechas de estado',
    'Indicators': 'Indicadores',
    'Signature Information': 'Información de firma',
    'Other Information': 'Otra información',
    'Delivered': 'Entregado',
    'Received': 'Recibió',
    'Received Date': 'Fecha de recepción',
    'Seal #': 'Sello #',
    'Seal2 #': 'Sello2 #',
    'Create Container': 'Crear contenedor',
    'Edit Container': 'Editar contenedor',
    'Update Container': 'Actualizar contenedor',
    'Confirm Delete Container': 'Confirmar eliminación del contenedor',
    'Delete Container': 'Eliminar contenedor',
    'List Containers': 'Listar contenedores',
    'Container Information': 'Información del contenedor',
    'Container #': 'Contenedor #',
    'BL Container Link': 'Enlace de contenedor BL',
    'Part number': 'Número de pieza',
    'HTS Option': 'Opción HTS',
    'FDA Code': 'Código FDA',
    'Bill of lading line #': '# Línea de guía de carga',
    'New Bill of Lading Line': 'Nueva línea de guía de carga',
    'Part Number Information': 'Información del número de pieza',
    'General Information': 'Información general',
    'Create BL Line': 'Crear línea BL',
    'Edit BL Line': 'Editar línea BL',
    'Update BL Line': 'Actualizar línea BL',
    'Confirm Delete BL Line': 'Confirmar eliminación de línea BL',
    'Delete BL Line': 'Eliminar línea BL',
    'List BL Lines': 'Lista de líneas BL',
    'Bill Lines Information': 'Información de líneas BL',
    'Container link': 'Enlace de contenedor',
    'Receiving Ticket': 'Recibir billete',
    'Part No.': 'Número de part',
    'Line No.': 'Línea No.',
    'Pkg Type': 'Tipo de paquete',
    'Expected Pkgs': 'Paquetes esperados',
    'Virtual Pkgs Bal.': 'Bal. de paquetes virtuales',
    'hysical Pkgs Bal.': 'Bal. de paquetes físicos',
    'Pkg Ratio': 'Relación de paquete',
    'Virtual Items Bal.': 'Bal. de artículos virtuales',
    'Physical Items Bal.': 'Bal. de artículos físicos',
    'Origin Co.': 'Origen Co.',
    'Received Pkgs': 'Paquetes recibidos',
    'Damaged Items': 'Artículos dañados',
    'Weight Balance': 'Equilibrio de peso',
    'Net Weight': 'Peso neto',
    'Inventory': 'Inventario',
    'Uploading': 'Subiendo',
    'Invoice Summary': 'Resumen de factura',
    'Customer Reference 1': 'Referencia del cliente 1',
    'Customer Reference 2': 'Referencia del cliente 2',
    'Customer Reference 3': 'Referencia del cliente 3',
    'Customer Description 1': 'Descripción del cliente 1',
    'Customer Description 2': 'Descripción del cliente 2',
    'Customer Description 3': 'Descripción del cliente 3',
    'Traffic status': 'Estado del tráfico',
    'CBP Amount': 'Monto CBP',
    'Created date': 'Fecha de creación',
    'Invoices': 'Facturas',
    'Printed': 'Impreso',
    'Customer Key': 'Clave de cliente',
    'Document Id': 'Identificación del documento',
    'Charge Code': 'Código de carga',
    'Select from Automatic charges...': 'Seleccione entre Cargos automáticos...',
    'Create Charge': 'Crear cargo',
    'Edit Charge': 'Editar cargo',
    'Update Charge': 'Cargo de actualización',
    'Confirm Delete Charge': 'Confirmar eliminar cargo',
    'Delete Charge': 'Eliminar cargo',
    'List Charges': 'Listar cargos',
    'Charges List': 'Lista de cargos',
    'Document': 'Documento',
    'Incomplete': 'Incompleto',
    'Mail To': 'Enviar a',
    'Reference 1': 'Referencia 1',
    'Description 1': 'Descripción 1',
    'Reference 2': 'Referencia 2',
    'Description 2': 'Descripción 2',
    'Reference 3': 'Referencia 3',
    'Description 3': 'Descripción 3',
    'Internal Notes': 'Notas internas',
    'Imp. Carrier': 'Imp. Transportador',
    'Mailed Waybill': 'Carta de porte enviada por correo',
    'Mailed Date': 'Fecha de envío por correo',
    'Export Date': 'Fecha de exportación',
    'Origin/Destination': 'Origen/Destino',
    'Other parties': 'Otras partes',
    'Customer References / Descriptions': 'Referencias/descripciones de clientes',
    'Additional Information': 'Información adicional',
    'Invoice number': 'Número de factura',
    'Type your notes...': 'Escribe tus notas...',
    'ISF Type': 'Tipo de ISF',
    'Entry (Leave blank to use next available number)': 'Entrada (Déjelo en blanco para usar el siguiente número disponible)',
    'Bill of lading type': 'Tipo de guía de carga',
    'Bill number (include SCAC in the BL number)': 'Número de factura (incluya SCAC en el número BL)',
    'ISF document was not found in database.': 'El documento ISF no se encontró en la base de datos.',
    'ISF Record Summary': 'Resumen de registros ISF',
    'Bill number': 'Número de factura',
    'Master bill of lading': 'Conocimiento de embarque maestro',
    'Loading date': 'Fecha de carga',
    'Bond holder': 'Tenedor de vínculos',
    'Activity code': 'Código de actividad',
    'ID Qualifier': 'Calificador de identificación',
    'ISF transmission action': 'Acción de transmisión ISF',
    'ISF transmission reason': 'Motivo de transmisión ISF',
    'Unlading Port': 'Puerto de descarga',
    'Delivery Port': 'Puerto de entrega',
    'Trans. number': 'Trans. Número',
    'Filing type': 'Tipo de presentación',
    'Master BL': 'Maestro BL',
    'Shipment type': 'Tipo de envío',
    'Customer ref': 'Referencia del cliente',
    'Reason': 'Razón',
    'Holder ID': 'ID del titular',
    'Bond. Ref.': 'Referencia de Vínculo',
    'Shipment subtype': 'Subtipo de envío',
    'Estimated quantity': 'Cantidad estimada',
    'Estimated value': 'Valor estimado',
    'Estimated weight': 'Peso estimado',
    'Full name': 'Nombre completo',
    'Carnet No.': 'Carnet No.',
    'Foreign Location Info': 'Información de ubicación extranjera',
    'Shipment Information': 'Información de envío',
    'Passport Info': 'Información del pasaporte',
    'Origin state': 'Estado de origen',
    'Create HTS': 'Crear HTS',
    'Edit HTS': 'Editar HTS',
    'Update HTS': 'Actualizar HTS',
    'Delete HTS': 'Eliminar HTS',
    'HTS List': 'Lista HTS',
    'ISF Entity type': 'Tipo de entidad ISF',
    'Section name': 'Nombre de la sección',
    'Section type': 'Tipo de sección',
    'Entities List': 'Lista de entidades',
    'Container type': 'Tipo de contenedor',
    'Chk Dif': 'Chk Dif',
    'Initial': 'Inicial',
    'Containers List': 'Lista de contenedores',
    'ISF Bill of lading type': 'Tipo de guía de carga ISF',
    'AMS Match': 'AMS Match',
    'Create Bill of Lading': 'Crear guía de carga',
    'Edit Bill of Lading': 'Editar guía de carga',
    'Update Bill of Lading': 'Actualizar guía de carga',
    'Delete Bill of Lading': 'Eliminar guía de carga',
    'Bill of Ladings List': 'Lista de guías de cargas',
    'TTB Lpco Types': 'Tipos de TTB Lpco',
    'TTB Program Codes': 'Códigos de programa TTB',
    'Txn types': 'Tipos de transmisiones',
    'UPC quality codes': 'Códigos de calidad UPC',
    'Weight in lines': 'Pesos en lineas',
    'ACE Dispositions': 'Disposiciones ACE',
    'Activity codes': 'Códigos de actividad',
    'AoC Codes': 'Códigos AoC',
    'APHIS category types': 'Tipos de categorías de APHIS',
    'APHIS commodity qualifiers': 'Calificadores de productos básicos de APHIS',
    'APHIS inspections': 'Inspecciones de APHIS',
    'APHIS item types': 'Tipos de elementos de APHIS',
    'APHIS processing codes': 'Códigos de procesamiento APHIS',
    'APHIS program codes': 'Códigos del programa APHIS',
    'APHIS types': 'Tipos de APHIS',
    'Arrive export actions': 'Acciones de arribos de exportación',
    'Bill of Lading Doc Types': 'Tipos de documentos de guías de carga',
    'Bill of lading types': 'Tipos de guías de carga',
    'Broker Teams': 'Equipos de corredoras',
    'CBP Teams': 'Equipos de CBP',
    'Reconciliation Change options': 'Opciones de cambio de conciliación',
    'Close traffic IE options': 'Cerrar opciones de tráfico de IE',
    'Close traffic IT options': 'Cerrar las opciones de TI de tráfico',
    'Close traffics': 'Cerrar tráficos',
    'Close traffic TE options': 'Cerrar opciones de TE de tráfico',
    'Consecutives': 'Consecutivos',
    'Consignee types': 'Tipos de destinatarios',
    'Container types': 'Tipos de contenedores',
    'Conveyance types': 'Tipos de transporte',
    'Country codes': 'Códigos de paises',
    'Country FTA Options': 'Opciones País FTA',
    'Country source types': 'Tipos de fuentes de países',
    'Cross document types': 'Tipos de documentos cruzados',
    'Date qualifiers': 'Calificadores de fecha',
    'DEA Document Ids': 'ID de documentos DEA',
    'DEA Program Codes': 'Códigos del programa DEA',
    'Default AES form billed docs': 'Documentos por defecto facturados en el formulario AES',
    'Default entry labels': 'Etiquetas de entrada predeterminadas',
    'Del. terms': 'Del. Términos',
    'Disclaimers': 'Descargos de responsabilidad',
    'Document templates': 'Plantillas de documentos',
    'Driver types': 'Tipos de conductores',
    'Duty Estimates': 'Estimaciones de deberes',
    'EDI ABI Transmissions': 'Transmisiones EDI ABI',
    'EDI convert eda actions': 'Acciones de conversión EDI EDA',
    'EDI CVD add hts types': 'EDI CVD agregar tipos de HTS',
    'EDI empty value lines': 'Líneas de valor vacías EDI',
    'EDI file error actions': 'Acciones de error de archivos EDI',
    'EDI group entry lines': 'Líneas de entrada grupo EDI',
    'EDI group sed lines': 'Líneas sed grupo EDI',
    'EDI inbond transmissions': 'Transmisiones internas EDI',
    'EDI ISF Transmissions': 'Transmisiones EDI ISF',
    'EDI lower hts types': 'Tipos de HTS inferiores de EDI',
    'EDI on convert actions': 'EDI en acciones de conversión',
    'EDI overwrite product no exist actions': 'Producto de sobrescritura EDI cuando no existe acciones',
    'EDI overwrite use product files': 'Archivos de producto de uso de sobrescritura EDI',
    'EDI product file mismatch actions': 'Acciones de discrepancia en archivos de productos EDI',
    'EDI product file updated actions': 'Acciones de actualización archivo de producto EDI',
    'EDI product no exist actions': 'Acciones cuando no existe el Producto EDI',
    'EDI sed transmissions': 'Transmisiones EDI sed',
    'EDI UOM line mismatches': 'Tipos de no coincidencias EDI UOM',
    'EDI use product file actions': 'EDI acciones de us archivo del producto',
    'Electronic Export Seds': 'Exportaciones electrónicas SEDs',
    'Electronic filing options': 'Opciones de llenado electrónico',
    'Emanifest transmission actions': 'Manifiesto acciones de transmisión',
    'Emanifest transmission RFAs': 'Manifiesto transmisiones RFAs',
    'Empty validation actions': 'Acciones de validación vacías',
    'Entity roles': 'Roles de entidad',
    'Entry actions': 'Acciones de entrada',
    'Entry Summary Query Types': 'Tipos de consultas de resumen de entradas',
    'Entry types': 'Tipos de entradas',
    'EPA Program codes': 'Códigos del programa EPA',
    'Equipment ITTs': 'Equipos ITT',
    'Equipment types': 'Tipos de equipos',
    'Exchange rates': 'Tipos de cambio',
    'Export actions': 'Acciones de exportación',
    'Export codes': 'Códigos de exportación',
    'Export Dfs': 'Exportar DFS',
    'Export mode of transports': 'Modos de exportación de transportes',
    'FDA Actions': 'Acciones de la FDA',
    'FDA PE Actions': 'Acciones FDA PE',
    'Fee Accounting Codes': 'Códigos de contabilidad de tarifas',
    'Fee codes': 'Códigos de tarifa',
    'Filing types': 'Tipos de presentación',
    'Firm codes': 'Códigos de empresa',
    'FSIS Category Codes': 'Códigos de categoría FSIS',
    'FSIS Commodity Qualifiers': 'Calificadores de productos básicos FSIS',
    'FSIS Inspections': 'Inspecciones FSIS',
    'FSIS Lpco Geo Qualifiers': 'Calificadores Geo FSIS Lpco',
    'FSIS Lpco Types': 'Tipos de FSIS Lpco',
    'FSIS Program Codes': 'Códigos programa FSIS',
    'FTA Options': 'Opciones FTA',
    'FWS Declaration Codes': 'Códigos de declaración FWS',
    'FWS Description Codes': 'Códigos de descripción FWS',
    'FWS Disclaimers': 'Descargo de responsabilidad FWS',
    'FWS Proc Codes': 'Códigos de proceso FWS',
    'GO Statuses': 'Estados GO',
    'Harvest Description Codes': 'Códigos de descripción de extracción',
    'Harvest Gear Types': 'Tipos de equipos de extracción',
    'Harvest Vessel Char Types': 'Tipos de equipos de extracción en buques',
    'Holidays': 'Vacaciones',
    'HTS options': 'Opciones HTS',
    'ID Qualifiers': 'Calificadores de identificación',
    'Inbond Action Codes': 'Inbond Códigos de acción',
    'Inbond codes': 'Códigos Inbond',
    'Inbond entered options': 'Inbond opciones de entrada',
    'Inbond types': 'Tipos de Inbond',
    'Insp. Arrival location codes': 'Insp. Códigos de ubicación de llegada',
    'Intended use codes': 'Códigos de uso previsto',
    'Invoice formats': 'Formatos de factura',
    'InwardMan stickers': 'InwardMan Pegatinas',
    'ISF Entity section types': 'Tipos de sección de entidad ISF',
    'ISF Entity types': 'Tipos de entidades ISF',
    'ISF Statuses': 'Estados ISF',
    'ISF Transmission actions': 'Acciones de transmisión ISF',
    'ISF Transmission reasons': 'Razones de transmisión ISF',
    'License codes': 'Códigos de licencia',
    'Location types': 'Tipos de ubicaciónes',
    'Loc of goods': 'Ubicaciones de mercancías',
    'Lpco types': 'tipos de lpco',
    'Management modes': 'Modos de gestión',
    'Manif dest from': 'Destino manifiesto desde',
    'Mode of transports': 'Modos de transporte',
    'NHTSA Box numbers': 'Números de caja NHTSA',
    'NHTSA Program codes': 'Códigos del programa NHTSA',
    'NHTSA Qualifier numbers': 'Números de clasificación NHTSA',
    'NMFS 370 Proc Codes': 'NMFS 370 Códigos de procesamiento',
    'NMFS SIM Processing Codes': 'SIM NMFS Códigos de procesamiento',
    'Overwrite origins': 'Sobrescribir orígenes',
    'Payment types': 'Tipos de pago',
    'Permit issues': 'Problemas de permisos',
    'PGA APHIS codes': 'Códigos PGA APHIS',
    'PGA FDA Codes': 'Códigos PGA FDA',
    'PGA FSIS codes': 'Códigos PGA FSIS',
    'PGA NHTSA Codes': 'Códigos PGA NHTSA',
    'Place of deliveries': 'Lugares de entregas',
    'Pmt types': 'Tipos de pagos',
    'Print ent not certs': 'Imprimir ent sin certificado',
    'Processing codes': 'Códigos de procesamiento',
    'Product File NMFS SIM Harvest Desc. Codes': 'Códigos de descripción de extracción de productos NMFS SIM',
    'Product File NMFS SIM Harvest Gear Types': 'Tipos de equipos de extracción de productos NMFS SIM',
    'Product File NMFS SIM Processing Desc. Codes': 'Descrión de códigos de procesamiento de extracción de productos NMFS SIM',
    'Product files PGA Codes': 'Códigos productos PGA',
    'Product File PGA Processing Codes': 'Códigos de procesamiento productos PGA',
    'Program codes': 'Códigos de programas',
    'Reach limit actions': 'Alcanzar acciones límite',
    'Reconciliation issues': 'Problemas de reconciliación',
    'Reconciliation Location codes': 'Códigos de ubicación de conciliación',
    'Reconciliation Payment types': 'Tipos de pago de conciliación',
    'Reconciliation Statuses': 'Estados de conciliación',
    'Reconciliation Transmission actions': 'Acciones de transmisión de conciliación',
    'Related Types': 'Tipos de relacionados',
    'Rel. date mismatch actions': 'Rel. acciones de discrepancia de fechas',
    'Remark codes': 'Códigos de observación',
    'Routes': 'Rutas',
    'Route types': 'Tipos de ruta',
    'SED Labels': 'Etiquetas SED',
    'SED Transmission actions': 'Acciones de transmisión SED',
    'Sequence modes': 'Modos de secuencia',
    '7501 Declaration forms': '7501 Formularios de declaración',
    'Shipment BL types': 'Tipos de envío BL',
    'Shipment RFAs': 'RFA de envío',
    'Shipments': 'Envíos',
    'Shipment transmission actions': 'Acciones de transmisión de envíos',
    'Shipment types': 'Tipos de envío',
    'Shipper statuses': 'Estados del remitente',
    'SIM Species': 'Especies SIM',
    'SIM Specy Category Codes': 'Códigos de categoría de especie SIM',
    'Smtp ssl modes': 'Modos smtp ssl',
    'Smtp types': 'Tipos SMTP',
    'Spip options': 'Opciones Spip',
    'Spis options': 'Opciones Spis',
    'Split shipments': 'Envíos divididos',
    'Status totals ACE PSC statuses': 'Estados de ACE PSC',
    'Status totals Cert Quota statuses': 'Estados de cuotas de certificados',
    'Status totals Liq Types': 'Tipos de líquido',
    'System on update actions': 'Sistema en acciones de actualización.',
    'Teams': 'Equipos',
    'Temp. Degree types': 'Temperatura. Tipos de grados',
    'Temp. Locations': 'Temperaturas. Ubicaciones',
    'Temp. Qualifiers': 'Temperatura. Clasificatorios',
    'Traffic flows': 'Flujos de tráfico',
    'Traffic statuses': 'Estados del tráfico',
    'Traffic types': 'Tipos de tráfico',
    'Trip statuses': 'Estados de viaje',
    'TTB Exemption Codes': 'Códigos de exención TTB',
    'Reviewed on': 'Revisado el',
    'Zip': 'ZIP',
    'DEA Registration or ID': 'Registro o identificación de la DEA',
    'FWS eDecs Filer Account': 'Cuenta de archivador de eDecs de FWS',
    'FSIS Insp./Arrival Loc. Code': 'Código de inspección/ubicación de llegada del FSIS',
    'FSIS Est. No.': 'N.º de estimación del FSIS',
    'Inactive': 'Inactivo',
    'Contact (used on PGA transmissions)': 'Contacto (utilizado en transmisiones PGA)',
    'Entity Identifiers': 'Identificadores de entidad',
    'Transaction type': 'Tipo de transacción',
    'Date Qualifier': 'Calificador de fecha',
    'PGA Entity Lpcos': 'Entidad PGA Lpcos',
    'Permit issue': 'Emisión de permisos',
    'Location code': 'Código de ubicación',
    'Agency Reg. Description': 'Descripción de la Agencia Reg.',
    'Exemption code': 'Código de exención',
    'Issuer': 'Editor',
    'Edit Lpco': 'Editar Lpco',
    'Metrics': 'Métrica',
    'Create Lpco': 'Crear Lpco',
    'Update Lpco': 'Actualizar Lpco',
    'Confirm Delete Lpco': 'Confirmar eliminar Lpco',
    'Delete Lpco': 'Eliminar Lpco',
    'List Lpcos': 'Lista de Lpcos',
    'PGA Entities': 'Entidades de la PGA',
    'Contact Info': 'Información de contacto',
    'LPCO type': 'Tipo de LPCO',
    'Geo Qualifier': 'Calificador geográfico',
    'Licenses, Permits, Certificates': 'Licencias, permisos, certificados',
    'Proc. code': 'Código de procedimiento',
    'Country of Origin': 'País natal',
    'Intended use code': 'Código de uso previsto',
    'Declaration code': 'Código de declaración',
    'Electronic image submitted': 'Imagen electrónica enviada',
    'Description code': 'Código de descripción',
    'Genus name': 'Nombre del género',
    'Species name': 'Nombre de la especie',
    'Sub. Species name': 'Sub. Nombre de la especie',
    'Common name (Specific)': 'Nombre común (específico)',
    'Common name (General)': 'Nombre común (General)',
    'Live Wildlife Specimen is Venomous': 'Un ejemplar de fauna silvestre viva es venenoso',
    'Scientific Name Information': 'Información del nombre científico',
    'Scientific Name if Animal is a hybrid': 'Nombre científico si el animal es un híbrido',
    'Specific and General Common Name': 'Nombre común específico y general',
    'List Entities': 'Entidades de lista',
    'Entities List': 'Lista de entidades',
    'Product key': 'Clave del producto',
    'Product name': 'Nombre del producto',
    'Valid From Date': 'Fecha de validez desde',
    'Valid To Date': 'Válido hasta la fecha',
    'Customs Broker': 'Agente de aduana',
    'Percentage': 'Porcentaje',
    'Create Element': 'Crear elemento',
    'Edit Element': 'Editar elemento',
    'Update Element': 'Elemento de actualización',
    'Confirm Delete Element': 'Confirmar eliminación de elemento',
    'Delete Element': 'Eliminar elemento',
    'List Elements': 'Elementos de la lista',
    'Elements List': 'Lista de elementos',
    'Intended use Desc.': 'Uso previsto Desc.',
    'Box number': 'Número de caja',
    'Category code': 'Código de categoría',
    'Brand/Make': 'Marca/Fabricar',
    'Model': 'Modelo',
    'Manuf. Month': 'Mes de fabricación',
    'Embassy country': 'País de la embajada',
    'Passport country': 'País del pasaporte',
    'Passport number': 'Número de pasaporte',
    'Declaration & Certification Code': 'Código de Declaración y Certificación',
    'Signed Docs on File': 'Documentos firmados en archivo',
    'NHTSA Importers Statement': 'Declaración del importador de la NHTSA',
    'Signed Manufacturer Letter': 'Carta firmada por el fabricante',
    'Copy of Contract': 'Copia del contrato',
    'Incomplete Vehicle Document': 'Documento de vehículo incompleto',
    'NHTSA HS 474 DOT Bond': 'Fianza NHTSA HS 474 DOT',
    'Motor Veh Equipment Statement': 'Declaración de equipamiento del vehículo de motor',
    'Brand Model': 'Modelo de marca',
    'Country / Crossing Doc': 'País / Documento de cruce',
    'ID Numbers': 'Números de identificación',
    'DOT Bond Info': 'Información sobre bonos DOT',
    'Txn type': 'Tipo de transacción',
    'Permit Issuer Ctry': 'Ciudad emisora del permiso',
    'Lpcos List': 'Lista de Lpcos',
    'NMFS International Fisheries Trade Permit (IFTP)': 'Permiso de Comercio Pesquero Internacional (IFTP) del NMFS',
    'Confidential Information': 'Información confidencial',
    'Contains yellowfin tuna': 'Contiene atún de aleta amarilla',
    'Country of registry': 'País de registro',
    'Ocean Area': 'Área del océano',
    'Gear Type': 'Tipo de engranaje',
    'Vessel country': 'País del buque',
    'Dolphin Safe Status': 'Estatus Dolphin Safe',
    'Captains Statement': 'Declaración del capitán',
    'Observers Statement': 'Declaración del observador',
    'IDCP Member Nation Certification': 'Certificación de nación miembro del IDCP',
    'Importer Signature': 'Firma del importador',
    'Create Item': 'Crear artículo',
    'Edit Item': 'Editar artículo',
    'Update Item': 'Actualizar artículo',
    'Confirm Delete Item': 'Confirmar eliminación de elemento',
    'Delete Item': 'Eliminar artículo',
    'List Items': 'Elementos de la lista',
    'Involved Items': 'Artículos involucrados',
    'Contains Yellowfin tuna': 'Contiene atún aleta amarilla',
    'Scientific species code': 'Código científico de especies',
    'Harvest Description Code': 'Código de descripción de la cosecha',
    'Country of Harvest Code': 'Código del país de cosecha',
    'List of Small Vessels': 'Lista de Embarcaciones Pequeñas',
    'Geographic Location': 'Ubicación geográfica',
    'Harvest Gear Type Code': 'Código de tipo de equipo de cosecha',
    'Processing Description Code': 'Código de descripción de procesamiento',
    'Weight / LPCOs': 'Peso / LPCO',
    'Harvest Vessel Char Type': 'Tipo de equipos de extracción en buques',
    'Harvest Vessel Characteristic': 'Características del buque de extracción',
    'Route type': 'Tipo de ruta',
    'Routing country': 'País de ruta',
    'Create Scenario': 'Crear escenario',
    'Edit Scenario': 'Editar escenario',
    'Update Scenario': 'Escenario de actualización',
    'Delete Scenario': 'Eliminar escenario',
    'Confirm Delete Scenario': 'Confirmar eliminación de escenario',
    'List Scenarios': 'Lista de escenarios',
    'Wild Caught Scenarios / Routing Information': 'Escenarios de captura en estado salvaje / Información de ruta',
    'Weight Quantity': 'Peso Cantidad',
    'Weight UOM': 'Peso UOM',
    'Routing Type': 'Tipo de enrutamiento',
    'Routing Country': 'País de enrutamiento',
    'Part prefix': 'Prefijo de la pieza',
    'Part suffix': 'Sufijo de la parte',
    'Part base': 'Parte base',
    'Supplier': 'Proveedor',
    'HTS Ruling': 'Sentencia HTS',
    'PGA Code': 'Código PGA',
    'Program/Proc. Code': 'Código de programa/procedimiento',
    'Create PGA': 'Crear PGA',
    'Edit PGA': 'Editar PGA',
    'Update PGA': 'Actualización de la PGA',
    'Delete PGA': 'Eliminar PGA',
    'Confirm Delete PGA': 'Confirmar eliminación de PGA',
    'List PGAs': 'Lista de PGA',
    'PGAs List': 'Lista de PGA',
    'Program Code': 'Código del programa',
    'Proc Code': 'Código de procedimiento',
    'Valid Date From >=': 'Fecha válida desde >=',
    'Valid Date From <=': 'Fecha válida desde <=',
    'Valid Date To >=': 'Fecha válida hasta >=',
    'Valid Date To <=': 'Fecha válida hasta <=',
    'Product Files': 'Archivos de productos',
    'Company IRRS/ISSN': 'Empresa IRRS/ISSN',
    'Unit Metric': 'Unidad métrica',
    'Valid From': 'Válido desde',
    'Valid To': 'Válido hasta',
    '11 - Federal Label Approval': '11 - Aprobación de etiquetas federales',
    '165 - Payment or Performance Bond': '165 - Garantía de pago o cumplimiento',
    '268 - Wine Certificate': '268 - Certificado de vino',
    '853 - Distilled Spirits Certificate': '853 - Certificado de bebidas destiladas',
    'Documents': 'Documentos',
    'Loc Code': 'Código de ubicación',
    'Excemption code': 'Código de excepción',
    'License Code': 'Código de licencia',
    'License Number': 'Número de licencia',
    'License ECCN': 'Licencia ECCN',
    'Export Code': 'Código de exportación',
    'Export D/F/M': 'Exportación D/F/M',
    'License Info': 'Información de licencia',
    'Harmonized Tariff': 'Tarifa armonizada',
    'Intended use description': 'Descripción del uso previsto',
    'UPC Qual. Code': 'Código de calificación UPC',
    'UPC Qual. Description': 'Descripción de la calificación UPC',
    'AMS Units': 'Unidades AMS',
    'Units (2)': 'Unidades (2)',
    'Units (3)': 'Unidades (3)',
    'Qty (2)': 'Cantidad (2)',
    'Qty (3)': 'Cantidad (3)',
    'Remark code': 'Código de observación',
    'Remarks': 'Observaciones',
    'UPC Quality': 'Calidad UPC',
    'Insp. Arrival location': 'Inspección del lugar de llegada',
    'Inspection 2': 'Inspección 2',
    'Insp. Arrival location 2': 'Inspección del lugar de llegada 2',
    'Code 2': 'Código 2',
    'Country Prd': 'País PRD',
    'Country Src': 'País de origen',
    'Category type': 'Tipo de categoría',
    'Commodity Qua': 'Calificador de productos básicos',
    'Commodity Char Qua': 'Características del Calificador de productos básicos',
    'Create Characteristic': 'Crear característica',
    'Edit Characteristic': 'Editar característica',
    'Update Characteristic': 'Actualizar característica',
    'Delete Characteristic': 'Eliminar característica',
    'Confirm Delete Characteristic': 'Confirmar Eliminar Característica',
    'List Characteristics': 'Características de la lista',
    'Characteristics List': 'Lista de características',
    'Scientific genus name': 'Nombre científico del género',
    'Scientific species name': 'Nombre científico de la especie',
    'Source': 'Fuente',
    'Country': 'País',
    'Commodity qualifier': 'Calificador de productos básicos',
    'Commodity char qualifier': 'Calificador de caracteres de mercancía',
    'Specific name': 'Nombre específico',
    'Genus/Species': 'Género/especie',
    'Source type': 'Tipo de fuente',
    'Additional Characteristics of a Component (Only use to report ingredients within AP0300)': 'Características adicionales de un componente (Utilícelo únicamente para informar los ingredientes dentro del AP0300)',
    'Specific Common Name': 'Nombre común específico',
    'Create Component': 'Crear componente',
    'Edit Component': 'Editar componente',
    'Update Component': 'Componente de actualización',
    'Delete Component': 'Eliminar componente',
    'Confirm Delete Component': 'Confirmar eliminación de componente',
    'List Components': 'Componentes de la lista',
    'Components List': 'Lista de componentes',
    'Country source type': 'Tipo de fuente del país',
    'Common name': 'Nombre común',
    'Subspecies name': 'Nombre de la subespecie',
    'Create Genus/Specy': 'Crear género/especie',
    'Edit Genus/Specy': 'Editar género/especie',
    'Update Genus/Specy': 'Actualizar género/especie',
    'Delete Genus/Specy': 'Eliminar género/especie',
    'Confirm Delete Genus/Specy': 'Confirmar eliminación de género/especie',
    'List Genus/Species': 'Lista de géneros y especies',
    'Genus/Species List': 'Lista de géneros y especies',
    'Permit Issuer Country': 'País emisor del permiso',
    'Routing name': 'Nombre de ruta',
    'Create Route': 'Crear ruta',
    'Edit Route': 'Editar ruta',
    'Update Route': 'Actualizar ruta',
    'Delete Route': 'Eliminar ruta',
    'List Routes': 'Lista de rutas',
    'Routes List': 'Lista de rutas',
    'Unit price': 'Precio unitario',
    'Price per': 'Precio por',
    'Unit weight': 'Peso unitario',
    'Cont. weight': 'Peso continuo',
    'Invoice UOM': 'Unidad de medida de factura',
    'Foreign Exporter': 'Exportador extranjero',
    'Elegibility': 'Elegibilidad',
    'Net Cost': 'Costo neto',
    'Customer Part number': 'Número de pieza del cliente',
    'Quantity Conversion Factor (Inv UOM -> HTS UOM)': 'Factor de conversión de cantidad (UOM Inv -> UOM HTS)',
    'Origin/FTA': 'Origen/TLC',
    'Other Flags': 'Otras banderas',
    'Reference Information': 'Información de referencia',
    'Invoice': 'Factura',
    'UPCO Auth. Party': 'Partido Autorizado UPCO',
    'Permit': 'Permiso',
    'Document ID': 'Identificación del documento',
    'Entities and Documents': 'Entidades y Documentos',
    'FDA Units': 'Unidades de la FDA',
    'Unit (4)': 'Unidad (4)',
    'Unit (5)': 'Unidad (5)',
    'Qty (4)': 'Cantidad (4)',
    'Qty (5)': 'Cantidad (5)',
    'Program': 'Programa',
    'Processing': 'Tratamiento',
    'Intended use code 2': 'Código de uso previsto 2',
    'New PGA Info': 'Nueva información de la PGA',
    'AOC Code': 'Código AOC',
    'Create AOC': 'Crear AOC',
    'Edit AOC': 'Editar AOC',
    'Update AOC': 'Actualizar AOC',
    'Delete AOC': 'Eliminar AOC',
    'Confirm Delete AOC': 'Confirmar eliminación de AOC',
    'List AOCs': 'Lista de AOC',
    'AOCs List': 'Lista de AOC',
    'Country code': 'Código del país',
    'Confirm Delete Country': 'Confirmar eliminar país',
    'List Countries': 'Lista de países',
    'Countries List': 'Lista de países',
    'Registration Number': 'Número de registro',
    'Submitter (Shipper)': 'Remitente (Remitente)',
    'Create Registration Number': 'Crear número de registro',
    'Edit Registration Number': 'Editar número de registro',
    'Update Registration Number': 'Actualizar número de registro',
    'Delete Registration Number': 'Eliminar número de registro',
    'Confirm Delete Registration Number': 'Confirmar eliminación de número de registro',
    'List Registration Numbers': 'Lista de números de registro',
    'Registration Numbers List': 'Lista de números de registro',
    'Country Prod': 'Productor de país',
    'Inspection': 'Inspección',
    'FSIS Entity': 'Entidad del FSIS',
    'Insp./Arrival Location Code': 'Código de lugar de inspección/llegada',
    'Insp./Arrival Location': 'Inspección/Lugar de llegada',
    'Production start date': 'Fecha de inicio de la producción',
    'Production end date': 'Fecha de finalización de la producción',
    'Characteristic Qualifier': 'Calificador característico',
    'Exporting': 'Exportador',
    'Producing': 'Productor',
    'Unit Metric 1': 'Unidad Métrica 1',
    'Unit Metric 2': 'Unidad Métrica 2',
    'Weight (Lbs)': 'Peso (libras)',
    'Category': 'Categoría',
    'Foreign Establishment Entities': 'Entidades de Establecimiento Extranjero',
    'Package Info': 'Información del paquete',
    'Create Lot': 'Crear lote',
    'Edit Lot': 'Editar lote',
    'Update Lot': 'Actualizar lote',
    'Delete Lot': 'Eliminar lote',
    'Confirm Delete Lot': 'Confirmar eliminación de lote',
    'List Lots': 'Listado de lotes',
    'FSIS Lots': 'Lotes del FSIS',
    'Start date': 'Fecha de inicio',
    'End date': 'Fecha de finalización',
    'Package ID': 'Identificación del paquete',
    'Severity': 'Gravedad',
    'Transmission action': 'Acción de transmisión',
    'Change option': 'Cambiar opción',
    'Payment Date From': 'Fecha de pago desde',
    'Payment Date To': 'Fecha de pago hasta',
    'Earliest Date From': 'Fecha más temprana desde',
    'Earliest Date To': 'Fecha más temprana para',
    'Latest Date From': 'Última fecha desde',
    'Latest Date To': 'Última fecha para',
    'Statement Date From': 'Fecha de declaración desde',
    'Statement Date To': 'Fecha de declaración hasta',
    'Recon Entry Summary': 'Resumen de la entrada de reconocimiento',
    'Importer Key': 'Clave de importador',
    'Importer IRS/ISSN': 'Importador IRS/ISSN',
    'AGGREGATE': 'AGREGAR',
    'STANDARD': 'ESTÁNDAR',
    'Earliest date': 'Fecha más temprana',
    'Latest date': 'Última fecha',
    'Liquidation Tax': 'Impuesto de liquidación',
    'Liquidation Fee': 'Tarifa de liquidación',
    'Liquidation ADD/CVD': 'Liquidación ADD/CVD',
    'Recon Entries': 'Entradas de reconocimiento',
    'Issue': 'Asunto',
    'Traffic (Leave blank to use next available)': 'Tráfico (Dejar en blanco para utilizar el siguiente disponible)',
    'Remote filling': 'Relleno remoto',
    'Payment date': 'Fecha de pago',
    'Comment': 'Comentario',
    'Recon issues': 'Problemas de reconocimiento',
    'Change/No Change': 'Cambio/Sin cambio',
    'Walve refund': 'Reembolso de Walve',
    'Prior disclosure': 'Divulgación previa',
    'IRS No.': 'N.º del IRS',
    'Liq. Duty': 'Impuesto sobre líquidos',
    'Liq. Tax': 'Impuesto sobre liquidos',
    'Liq. Fee': 'Tarifa de liquidación',
    'Liq. Interest': 'Interés líquido',
    'Liq. ADD/CVD': 'Líquido ADD/CVD',
    'Recon Indicators': 'Indicadores de reconocimiento',
    'Statement and Liquidation': 'Declaración y Liquidación',
    'Recon Entry document was not found in database.': 'No se encontró el documento de Recon Entry en la base de datos.',
    'Name Spanish': 'Nombre español',
    'Name English': 'Nombre Inglés',
    'Messages': 'Mensajes',
    'Emanifest': 'Emanifiesto',
    'Reports': 'Informes',
    'This operation is restricted to your user role': 'Esta operación está restringida a su rol de usuario',
    'Permissions are already set for this user role': 'Los permisos ya están configurados para este rol de usuario',
    'Print as Broker Contact': 'Imprimir como contacto del corredor',
    'Default Startup Page': 'Página de inicio predeterminada',
    'Team': 'Equipo',
    'Electronic Signature': 'Firma electrónica',
    'Assign': 'Asignar',
    'Default signature printed name': 'Nombre impreso de la firma predeterminada',
    'Request signature to user': 'Solicitar firma al usuario',
    'Allow password reset': 'Permitir restablecimiento de contraseña',
    'Change password to next logon': 'Cambiar contraseña para el próximo inicio de sesión',
    'Request User to confirm information': 'Solicitar al usuario que confirme la información',
    'Email Form': 'Formulario de correo electrónico',
    'Email Cc': 'Correo electrónico Cc',
    'Email Bcc': 'Correo electrónico CCO',
    'Reference ID': 'Identificación de referencia',
    'Export Port': 'Puerto de Exportación',
    'Unloading Port': 'Puerto de descarga',
    'Expiration date from': 'Fecha de caducidad desde',
    'Expiration date to': 'Fecha de caducidad hasta',
    'Reference number (Leave blank to use next available number)': 'Número de referencia (déjelo en blanco para utilizar el siguiente número disponible)',
    'SED Label': 'Etiqueta SED',
    'Shipper document was not found in database.': 'No se encontró el documento del remitente en la base de datos.',
    'Shipper Summary': 'Resumen del remitente',
    'Ultimate Consignee': 'Destinatario final',
    'Exporter Ultimate Related': 'Exportador Ultimate Relacionado',
    'Electronic filing option': 'Opción de presentación electrónica',
    'Hazardous material': 'Material Peligroso',
    'Routed export trans': 'Exportación enrutada trans',
    'Fast ctpat': 'CTPAT rápido',
    'Self propelled': 'Autopropulsado',
    'SED Transmission action': 'Acción de transmisión SED',
    'SED Transmission itn': 'Transmisión SED itn',
    'Origin USA State': 'Origen EE.UU. Estado',
    'Destination country': 'País de destino',
    'Destination state': 'Estado de destino',
    'Inbond code': 'Código de entrada',
    'Original ITN': 'ITN original',
    'Shippers': 'Transportistas',
    'Title number': 'Número de título',
    'Create VIN': 'Crear VIN',
    'Edit VIN': 'Editar VIN',
    'Update VIN': 'Actualizar VIN',
    'Delete VIN': 'Eliminar VIN',
    'Reference number': 'Número de referencia',
    'Expiration date': 'Fecha de expiración',
    'Inter consignee': 'Entre consignatarios',
    'Exporter ultimate related': 'Exportador relacionado en último término',
    'FTZ Zone': 'Zona de libre comercio',
    'Mode of transport': 'Modo de transporte',
    'Origin and Destination': 'Origen y destino',
    'Inbond Information': 'Información de Inbond',
    'Transportation': 'Transporte',
    'Equipment number': 'Número de equipo',
    'Seal number': 'Número de sello',
    'Equipments': 'Equipamientos',
    'Save HTS to Exporter profile': 'Guardar HTS en el perfil del exportador',
    'Weight (kg)': 'Peso (kg)',
    'Marks': 'Marcas',
    'License code': 'Código de licencia',
    'License number': 'Número de licencia',
    'Export code': 'Código de exportación',
    'Export DF': 'Exportar DF',
    'Export action': 'Acción de exportación',
    'Consent number': 'Número de consentimiento',
    'RCRA Manif tradding': 'Comercio de manif. RCRA',
    'Net quantity': 'Cantidad neta',
    'Export Cert. Number': 'Número de certificado de exportación',
    'Create Line': 'Crear línea',
    'HTS & Quantities': 'HTS y cantidades',
    'License Info': 'Información de licencia',
    'Environment Protection Agency': 'Agencia de Protección del Medio Ambiente',
    'Agricultural Marketing Service': 'Servicio de Comercialización Agrícola',
    'Edit Line': 'Editar línea',
    'Update Line': 'Línea de actualización',
    'Delete Line': 'Borrar linea',
    'Commodity Lines Items': 'Artículos de líneas de productos básicos',
    'Activities details': 'Detalles de las actividades',
    'ISO Code': 'Código ISO',
    'Size Type': 'Tipo de Tamaño',
    'Travesy Status': 'Estado de travesía',
    'Current Position': 'Posición actual',
    'Travesy Type': 'Tipo de travesía',
    'Line Name': 'Nombre de la línea',
    'Locations & Operations': 'Ubicaciones y operaciones',
    'Travesy': 'Travesía',
    'Miles': 'Millas',
    'Miles traveled': 'Millas recorridas',
    'Miles to destination': 'Millas hasta el destino',
    'Activities details': 'Detalles de las actividades',
    'Customers with ISF Containers': 'Clientes con contenedores ISF',
    'ISF Container Numbers': 'Números de contenedores ISF',
    'Ships Monitoring': 'Monitoreo de buques',
    'Container number:': 'Número de contenedor:',
    'Last Requested Transit': 'Último tránsito solicitado',
    'Shipping Line': 'Línea naviera',
    'Container TEU': 'Contenedor TEU',
    'Container Type': 'Tipo de contenedor',
    'Show transit path': 'Mostrar ruta de tránsito',
    'Transit': 'Tránsito',
    'Vessel Position': 'Posición del buque',
    'Transit From': 'Tránsito desde',
    'Container tracking service message: ': 'Mensaje del servicio de seguimiento de contenedores: ',
    'Vessel does not support geolocation...': 'El barco no admite geolocalización...',
    'ISF date': 'Fecha de la ISF',
    'Custom clearance date': 'Fecha de despacho de aduana',
    'LFD date': 'Fecha de LFD',
    'Operation Details': 'Detalles de la operación',
    'Custom clearance': 'Despacho de aduana',
    'Call Sign': 'Indicativo de llamada',
    'Sea Line Code': 'Código de línea marítima',
    'Travesy begin': 'La travestía comienza',
    'Travesy end': 'Fin de la parodia',
    'Miles': 'Millas',
    'Miles traveled': 'Millas recorridas',
    'Miles to destination': 'Millas hasta el destino',
    'Commercial Invoice': 'Factura comercial',
    'Arrival Notice': 'Aviso de llegada',
    'Confirm Delete Document': 'Confirmar eliminación de documento',
    'Confirm Accept Document': 'Confirmar Aceptar Documento',
    'Delete Document': 'Eliminar documento',
    'Accept Document': 'Aceptar documento',
    'List Documents': 'Lista de documentos',
    'Upload Document': 'Subir documento',
    'No documents': 'Sin documentos',
    'Statement Details': 'Detalles de la declaración',
    'Check Date': 'Comprobar fecha',
    'Statement type': 'Tipo de declaración',
    'Gen. Desc': 'Descripcion general',
    'Docs Req.': 'Documentos requeridos.',
    'Person in Charge': 'Persona responsable',
    'Additional Description': 'Descripción adicional',
    'System Support': 'Soporte del sistema',
    'From Email': 'Desde el correo electrónico',
    'Receive a Copy': 'Recibir una copia',
    'To Email': 'Para enviar un correo electrónico',
    'Attach Files': 'Adjuntar archivos',
    'Entry lines': 'Líneas de entrada',
    'Entries with more than 20 lines': 'Entradas con más de 20 líneas',
    'EDI Entries': 'Entradas EDI',
    'EDI Entry lines': 'Líneas de entrada EDI',
    'EDI Entries with more 20 lines': 'Entradas EDI con más de 20 líneas',
    'Non EDI Entries': 'Entradas no EDI',
    'Non EDI Entry lines': 'Líneas de entrada que no son EDI',
    'Non EDI Entries with more 20 lines': 'Entradas no EDI con más de 20 líneas',
    'Inbonds & Inbonds BoL Entry Lines': 'Líneas de entrada de BoL de Inbonds y Inbonds',
    'Inbond lines': 'Líneas de conexión interna',
    'Inbonds with more than 20 lines': 'Inbonds con más de 20 líneas',
    'EDI Inbonds': 'EDI entrantes',
    'EDI Inbond lines': 'Líneas de EDI Inbond',
    'EDI Inbonds with more 20 lines': 'EDI Inbonds con más de 20 líneas',
    'Non EDI Inbonds': 'Entradas no EDI',
    'Non EDI Inbond lines': 'Líneas de entrada no EDI',
    'Non EDI Inbonds with more 20 lines': 'Envíos entrantes no EDI con más de 20 líneas',
    'EDI Inbonds': 'EDI entrantes',
    'Non EDI Inbonds': 'Entradas no EDI',
    'Shippers with more than 20 lines': 'Embarcadores con más de 20 líneas',
    'EDI Shippers': 'Transportistas EDI',
    'EDI Shipper lines': 'Líneas de envío EDI',
    'EDI Shippers with more 20 lines': 'Transportistas EDI con más de 20 líneas',
    'Non EDI Shippers': 'Transportistas que no utilizan EDI',
    'Non EDI Shipper lines': 'Líneas de envío que no utilizan EDI',
    'Non EDI Shippers with more 20 lines': 'Transportistas no EDI con más de 20 líneas',
    'EDI Shippers': 'Transportistas EDI',
    'Non EDI Shippers': 'Transportistas que no utilizan EDI',
    'Inbonds & Inbonds BoL Entry Lines': 'Líneas de entrada de BoL de Inbonds y Inbonds',
    'Shippers & Shipper Lines': 'Transportistas y líneas de transporte',
    'Total Entries': 'Total de entradas',
    'Entries & Entry Lines': 'Entradas y líneas de entrada',
    'Traffic type': 'Tipo de tráfico',
    'Create Traffic Doc': 'Crear documento de tráfico',
    'Edit Traffic Doc': 'Editar documento de tráfico',
    'Update Traffic Doc': 'Actualizar documento de tráfico',
    'Delete Traffic Doc': 'Eliminar documento de tráfico',
    'Linked Docs List': 'Lista de documentos vinculados',
    'Doc. Type': 'Tipo de documento',
    'Doc. SubType': 'Subtipo de doc.',
    'Doc ID': 'Identificación del documento',
    'Doc. Date': 'Doc. Fecha',
    'Invoice Printed': 'Factura impresa',
    'Number (Leave blank to assign next available number)': 'Número (Dejar en blanco para asignar el siguiente número disponible)',
    'Linked Docs': 'Documentos vinculados',
    'Importer/Owner': 'Importador/Propietario',
    'Inter. Consignee': 'Consignatario Interno',
    'Trailer No.': 'No. De Trailer',
    'Linked Docs': 'Documentos vinculados',
    'Traffic document was not found in database.': 'No se encontró el documento de tráfico en la base de datos.',
    'Inv. printed': 'Inv. impreso',
    'Transmission Responses': 'Respuestas de transmisión',
    'Section': 'Sección',
    'QB Trunc Customer Name': 'Nombre del cliente de QB Trunc',
    'Charges Account Name (Without Code)': 'Cargos Nombre de la cuenta (sin código)',
    'Charges Account Name (Without Code)': 'Cargos Nombre de la cuenta (sin código)',
    'Pmt Rec Deposit Account / Bank Name': 'Cuenta de depósito de pago por adelantado / Nombre del banco',
    'Credit Memo Charge': 'Nota de crédito con cargo',
    'Consolidate Invoices Charges': 'Consolidar cargos de facturas',
    'Use QB Charges': 'Utilice cargas de QB',
    'QB Version': 'Versión QB',
    'Invoices / Payments / Credits': 'Facturas / Pagos / Créditos',
    'QuickBooks': 'QuickBooks',
    'Upload QuickBook File': 'Subir archivo QuickBook',
    'Download QuickBook File': 'Descargar archivo QuickBook',
    'Clear QuickBook File': 'Borrar archivo QuickBook',
    'QB Version': 'Versión QB',
    'Invoices / Payments / Credits': 'Facturas / Pagos / Créditos',
    'Flow': 'Fluir',
    'Traffic Prefix': 'Prefijo de tráfico',
    'Recon List Screen Max Page Size': 'Tamaño máximo de página de la pantalla de lista de reconocimiento',
    'Traffif Consecutive Flow': 'Flujo de tráfico consecutivo',
    'Go-Traffic Consecutive Flow': 'Flujo consecutivo de Go-Traffic',
    'Allow multiple GOs per traffic': 'Permitir múltiples GO por tráfico',
    'Go-Traffic Consecutive Flow': 'Flujo consecutivo de Go-Traffic',
    'GO Indicators': 'Indicadores GO',
    'Other Indicators': 'Otros indicadores',
    'Days Due for IT': 'Días de vencimiento de TI',
    'Days Due for IE': 'Días de vencimiento para IE',
    'Days Due for TE': 'Días de vencimiento para TE',
    'For Days Due set Inbond Date as Day 1': 'Para los días de vencimiento, establezca la fecha de entrada como Día 1',
    'District Port': 'Puerto del distrito',
    'Firm Code': 'Código de la empresa',
    'Entered By': 'Ingresado por',
    'Filter Entered By': 'Filtrar ingresado por',
    'Inbond List Screen Max Page Size': 'Tamaño máximo de página de la pantalla de lista de entrada',
    'Starting': 'A partir de',
    'Ending': 'Final',
    'Limit Warning Message': 'Mensaje de advertencia de límite',
    'Print Transfer Legend': 'Leyenda de transferencia de impresión',
    'Close Traffic for IE': 'Cerrar el tráfico para IE',
    'Close Traffic for IT': 'Cerrar el tráfico para TI',
    'Close Traffic for TE': 'Cerrar el tráfico para TE',
    'Do not send Record 32': 'No enviar Registro 32',
    'Transmit Inbond after receiving Prior Notice': 'Transmitir Inbond después de recibir Notificación Previa',
    'Upd IB Arrival/Export Dates from Bill of Landing NS': 'Actualización de las fechas de llegada/exportación del conocimiento de embarque de NS',
    'Do not Print Previous Balances for FTZ WD': 'No imprimir saldos anteriores para FTZ WD',
    'Commercial Invoice WDs for Perishable': 'Factura comercial WD para productos perecederos',
    'Handle IB Balances for FTZ WD Manually': 'Manejar saldos IB para FTZ WD manualmente',
    'WP old Workflow (No Traffic)': 'Flujo de trabajo antiguo de WP (sin tráfico)',
    'Register Inbond with Canceled Document (CE, FTZ, GO)': 'Registrar Inbond con Documento Cancelado (CE, FTZ, GO)',
    'Allow alphanumeric GOs, Blue Letters, Delivery Orders': 'Permitir GO alfanuméricos, letras azules, órdenes de entrega',
    'Optimize Rendering of CF7512': 'Optimizar la representación de CF7512',
    'Show Importer Notes': 'Mostrar notas del importador',
    'Allow new WP on already Invoice Traffic': 'Permitir nuevo WP en tráfico ya facturado',
    'Pedimento Prefix': 'Prefijo de pedimento',
    'Default Inbond Label': 'Etiqueta de Inbond predeterminada',
    'Critical Notification Emails (1S, 1R,53, 1K, 1F, 1H & 1G)': 'Correos electrónicos de notificación crítica (1S, 1R, 53, 1K, 1F, 1H y 1G)',
    'Default': 'Por defecto',
    'Sequence': 'Secuencia',
    'Other Indicators': 'Otros indicadores',
    'Templates': 'Plantillas',
    'Design': 'Diseño',
    'Eman-Traffic Consecutive Flow': 'Flujo consecutivo de tráfico Eman',
    'Emanifest List Screen Max Page Size': 'Tamaño máximo de página de la pantalla de lista de manifiestos',
    'Include Second Last Name field in transmission': 'Incluir el campo de Segundo Apellido en la transmisión',
    'Host': 'Host',
    'Log Days': 'Días de registro',
    'SSL Mode': 'Modo SSL',
    'SMTP Type': 'Tipo de SMTP',
    'Delegate Emails to RBProcs': 'Delegar correos electrónicos a RBProcs',
    'Default Email (To)': 'Correo electrónico predeterminado (Para)',
    'Email From': 'Correo electrónico de',
    'Tel API Account SID': 'SID de cuenta de API de Tel',
    'Tel API Auth Token': 'Token de autenticación de API de Tel',
    'Server Root Path': 'Ruta raíz del servidor',
    'Require Label': 'Requerir etiqueta',
    'On System Update': 'Sobre la actualización del sistema',
    'User Pass Valid For Days': 'Pase de usuario válido por días',
    'C-TPAT password format': 'Formato de contraseña C-TPAT',
    'Broker Pass Code': 'Código de acceso del corredor',
    'Late ISF Notifs. Email': 'Notificaciones ISF tardías. Correo electrónico',
    'ISF List Screen Max Per Page Size': 'Tamaño máximo por página de la pantalla de lista ISF',
    'Bill of Landing': 'Conocimiento de embarque',
    'Prov/Invoice': 'Prov/Factura',
    'Sequence Mode': 'Modo secuencial',
    'Prefix': 'Prefijo',
    'Length': 'Longitud',
    'Current Traffic': 'Tráfico actual',
    'No padding': 'Sin relleno',
    'Allow Entry/Inb/Sed in same traf': 'Permitir entrada/entrada/sesión en el mismo tráfico',
    'By pass Traffic Status Validation': 'Omitir la validación del estado del tráfico',
    'Optimize Rendering on Traffic Form': 'Optimizar la representación en el formulario de tráfico',
    'Current Sequence': 'Secuencia actual',
    'Loockup Companies By': 'Empresas de búsqueda por',
    'Limit Result Row': 'Fila de resultados límite',
    'Include Details': 'Incluir detalles',
    'Current Bill of Landing': 'Conocimiento de aterrizaje actual',
    'Allow new BL on already Invoice Traffic': 'Permitir nuevo BL en tráfico ya facturado',
    'Convert KGS to LBS from Entries': 'Convertir KGS a LBS a partir de entradas',
    'Optimize Rendering on Bill Landing Form': 'Optimizar la representación en el formulario de facturación',
    'Allow new DO on already Invoice Traffic': 'Permitir nuevo DO en tráfico ya facturado',
    'Optimize Rendering on Delivery Order Form': 'Optimizar la representación en el formulario de pedido de entrega',
    'Hours to Close pgm': 'Horas para cerrar pgm',
    'Fix Label Avery 5160': 'Etiqueta de reparación Avery 5160',
    'Require [Verify] before Transmission': 'Requerir [Verificar] antes de la transmisión',
    'CBP Message update with Time Zone by Port': 'Actualización de mensajes de CBP con zona horaria por puerto',
    'Smtp Email Settings': 'Configuración de correo electrónico SMTP',
    'Test Email': 'Correo electrónico de prueba',
    'Test RBProcs Email': 'Prueba el correo electrónico de RBProcs',
    'Text Message Settings': 'Configuración de mensajes de texto',
    'Imaging': 'Imágenes',
    'Terms of Use': 'Condiciones de uso',
    'Assign Document': 'Asignar documento',
    'Preview Document': 'Vista previa del documento',
    'Export Document': 'Documento de exportación',
    'Import Document': 'Documento de importación',
    'Delete Document': 'Eliminar documento',
    'Design Document': 'Documento de diseño',
    'System': 'Sistema',
    'On System Update': 'Sobre la actualización del sistema',
    'User Pass Valid For Days': 'Pase de usuario válido por días',
    'C-TPAT password format': 'Formato de contraseña C-TPAT',
    'Broker Pass Code': 'Código de acceso del corredor',
    'ISF Information': 'Información de la ISF',
    'Custom Legend': 'Leyenda personalizada',
    'Bill of Landing': 'Conocimiento de embarque',
    'Assign Logo': 'Asignar logotipo',
    'Clear Logo': 'Logotipo transparente',
    'Activate EDI Automated Process': 'Activar el proceso automatizado EDI',
    'From Email Account (EDI Email Address)': 'Desde una cuenta de correo electrónico (dirección de correo electrónico EDI)',
    'Send Error to Email Broker Acct.': 'Enviar error al correo electrónico de la cuenta del corredor.',
    'Only 1 NHTSA record when grouping': 'Solo 1 registro de NHTSA al agrupar',
    'POP3 Server': 'Servidor POP3',
    'POP3 Port': 'Puerto POP3',
    'Check Email every (value) minutes': 'Consultar el correo electrónico cada (valor) minutos',
    'Days to Keep EDI DIS Detached Files': 'Días para conservar archivos separados de EDI DIS',
    'Activate EDI Automated Process': 'Activar el proceso automatizado EDI',
    'From Email Account (EDI Email Address)': 'Desde una cuenta de correo electrónico (dirección de correo electrónico EDI)',
    'Send Error to Email Broker Acct.': 'Enviar error al correo electrónico de la cuenta del corredor.',
    'Only 1 NHTSA record when grouping': 'Solo 1 registro de NHTSA al agrupar',
    'Upload EDI File': 'Subir archivo EDI',
    'Download EDI File': 'Descargar archivo EDI',
    'Clear EDI File': 'Borrar archivo EDI',
    'Email Settings': 'Configuración de correo electrónico',
    'Test POP3': 'Prueba POP3',
    'EDI Files': 'Archivos EDI',
    'Default': 'Por defecto',
    'City/State/ZIP': 'Ciudad/Estado/Código Postal',
    'Default Email From': 'Correo electrónico predeterminado de',
    'Invoice Mode': 'Modo factura',
    'A/R Closing Date': 'Fecha de cierre de cuentas por cobrar',
    'Padding Length': 'Longitud del relleno',
    'Credit Memo Mode': 'Modo de nota de crédito',
    'Current Invoice': 'Factura actual',
    'Current Credit Memo': 'Nota de crédito actual',
    'Inv. Header Legend': 'Leyenda del encabezado de Inv.',
    'Fgn Txn Traffic': 'Tráfico de Fgn Txn',
    'Inventory Footer Legend': 'Leyenda del pie de página del inventario',
    'Invoice Format': 'Formato de factura',
    'AR List Screen Max Page Size': 'Tamaño máximo de página de la pantalla de lista AR',
    'Allow Discounts 1.0': 'Permitir descuentos 1.0',
    'Allow Discounts 2.0': 'Permitir descuentos 2.0',
    'Allow Open Status traffic to be inviced': 'Permitir que se invite al tráfico de estado abierto',
    'Do not print charges with zero values': 'No imprimir cargos con valores cero',
    'Allow traffic to be invoiced several times': 'Permitir que el tráfico se facture varias veces',
    'Allow automatic charges in zero': 'Permitir cargos automáticos en cero',
    'Allow Cost of Doing Business': 'Permitir el costo de hacer negocios',
    'Warn user when charges in zero': 'Avisar al usuario cuando los cargos estén en cero',
    'Allow Customer replacement on Invoice': 'Permitir reemplazo de cliente en factura',
    'Invoice Print - Use Bold for CBP Charges': 'Impresión de facturas: Utilice negrita para los cargos de CBP',
    'Initialize Invoices with Incomplete Status': 'Inicializar facturas con estado incompleto',
    'Show Invoice already printed warning': 'Mostrar aviso de factura ya impresa',
    'Traffic No. as Invoice No. when called from Main Doc': 'Número de tráfico como número de factura cuando se llama desde el documento principal',
    'Auto Charges by Cattle (Consignee + Port)': 'Gastos de Auto por Ganado (Consignatario + Puerto)',
    'Allow flaggin invoices as transferred': 'Permitir marcar facturas como transferidas',
    'When Invoicing, show warning if traffics have shipments pending of CBP acceptance': 'Al facturar, mostrar advertencia si los tráficos tienen envíos pendientes de aceptación de CBP',
    'Set Invoice Date = Entry Release Date': 'Establecer fecha de factura = Fecha de emisión de entrada',
    'When printing, prevent preview of documents pending of CBP acceptance': 'Al imprimir, evitar la vista previa de los documentos pendientes de aceptación de CBP',
    'Include Automatic Charges with and without SubType per Document': 'Incluir cargos automáticos con y sin subtipo por documento',
    'AR Receipt / Credit Memo Screen - Show ammount to apply per invoice': 'Pantalla de recibo de AR/nota de crédito: muestra el monto a aplicar por factura',
    'Set Invoice Date = Inbond Export Date (IE/TE)': 'Establecer fecha de factura = Fecha de exportación de importación (IE/TE)',
    'Optimize Rendering of Invoice Form': 'Optimizar la representación del formulario de factura',
    'Automatic Charges By Port': 'Cargos automáticos por puerto',
    'Sub Customer in Automatic Charges': 'Subcliente en cobros automáticos',
    'Optimize Auto Charges': 'Optimizar los cargos automáticos',
    'Free Code': 'Código libre',
    'Free Pctg %': '% Pctg gratuito',
    'Free Minimum': 'Mínimo gratuito',
    'AR Codes': 'Códigos AR',
    'These Customers Only (Leave empty to apply to all customers)': 'Solo estos clientes (déjelo en blanco para aplicarlo a todos los clientes)',
    'Information': 'Información',
    'Cash Disbursement Free - Traffics': 'Desembolso de efectivo gratuito - Tráfico',
    'Current SED number': 'Número SED actual',
    'Last SED Number': 'Último número SED',
    'Padding Lenght': 'Longitud del relleno',
    'Default AES form on Billed Docs': 'Formulario AES predeterminado en Documentos facturados',
    'AES List Screen Max Page Size': 'Tamaño máximo de página de la pantalla de lista AES',
    'Show Customer Notes': 'Mostrar notas del cliente',
    'Additional Info on SED Plain Format': 'Información adicional sobre el formato SED Plain',
    'Schedule B Only': 'Solo Anexo B',
    'Optimize Rendering AES Form': 'Optimizar la representación de formularios AES',
    'SED-Traffic Consecutive Flow': 'Flujo de tráfico consecutivo SED',
    'Digital Signature': 'Firma digital',
    'Assign AES Signature': 'Asignar firma AES',
    'Clear AES Signature': 'Borrar firma AES',
    'Other Indicators': 'Otros indicadores',
    'Current consecutive number': 'Número consecutivo actual',
    'Last consecutive number': 'Último número consecutivo',
    'Smallest number': 'Número más pequeño',
    'Entry-Traffic Consecutive Flow': 'Flujo consecutivo de tráfico de entrada',
    'Traffic Prefix': 'Prefijo de tráfico',
    'Exporter Company': 'Empresa exportadora',
    'Origin Company': 'Origen de la empresa',
    'Permit Broker': 'Corredor de permisos',
    'Nafta Zero Date': 'Fecha cero del TLCAN',
    'Print Owner': 'Propietario de la impresión',
    'Hours to Cross': 'Horas para cruzar',
    'MID as Owner': 'MID como propietario',
    'Hours to Cross PN': 'Horas para cruzar PN',
    'Carrier as Owner': 'El transportista como propietario',
    'Print Consignee': 'Consignatario de la impresión',
    'Entry Barcode': 'Código de barras de entrada',
    'Carrier Responsability msg.': 'Mensaje de responsabilidad del transportista.',
    'Carrier as Conv.': 'Portador como Conv.',
    'Ent line desc on Cover Sheet': 'Descripción de la línea de entrada en la portada',
    'Remove Cover Sheet': 'Quitar la hoja de cubierta',
    'Show QR Code': 'Mostrar código QR',
    'Optimize Rendering of 7533 Form': 'Optimizar la representación del formulario 7533',
    'Desactivate AMS Tab': 'Desactivar pestaña AMS',
    'Enable Global Business ID': 'Habilitar la identificación comercial global',
    'ACH Unit Number': 'Número de unidad ACH',
    'Pmt Week Type': 'Tipo de semana de pago',
    'Pmt Days': 'Días de pago',
    'Stmt Branch': 'Rama de la administración',
    'Broker Key': 'Clave del corredor',
    'PGA Correction on dutiable HTS Only': 'Corrección de PGA sobre HTS sujeto a derechos únicamente',
    'Do not ask copy from previous on all FDA lines': 'No solicite copia de anteriores en todas las líneas de la FDA',
    'If Ent Type (21,22,31) set FDA as Warning': 'Si el tipo de entrada es (21,22,31), establezca FDA como Advertencia',
    'Feed Product File': 'Archivo de producto de alimentación',
    'Initialize APHIS & AMS quantities from FDA': 'Inicializar cantidades de APHIS y AMS desde la FDA',
    'Print Ent not Cert/Acc': 'Imprimir Ent no Cert/Acc',
    'CBP Stmt Brk Cntcts': 'CBP declara que se han producido fallos de seguridad',
    'CBP Stmt Brk Cntcts': 'CBP declara que se han producido fallos de seguridad',
    'Informal Limit': 'Límite informal',
    '7501 Print Inv Break': '7501 Impresión de interrupción de inversión',
    'GSP Refund': 'Reembolso del SGP',
    'Surcharge': 'Recargo',
    'NDCs per Invoice': 'NDC por factura',
    'Default Entry Label': 'Etiqueta de entrada predeterminada',
    'Default Entry Label View': 'Vista de etiqueta de entrada predeterminada',
    'Entry Sum. Notifs. Email Deprecated': 'Entrada Suma Notificaciones Correo electrónico Obsoleto',
    'AMS Notifications': 'Notificaciones de AMS',
    'Rej. Recon Notif. Email': 'Notificación de reconocimiento de Rej. por correo electrónico',
    'Carrier as Conveyance': 'El transportista como medio de transporte',
    'Declaration 1': 'Declaración 1',
    'Declaration 2': 'Declaración 2',
    'Declaration 3': 'Declaración 3',
    'Declaration 4': 'Declaración 4',
    'Prevent Eman Trans': 'Prevenir Eman Trans',
    'Ent Summ Skip PGAs': 'Ent Summ Saltar PGAs',
    'Ent Summ Containers': 'Contenedores Ent Summ',
    'Show Importer Notes': 'Mostrar notas del importador',
    'ACESIDs on Emans': 'ACESID en Emans',
    'Enable Produce': 'Habilitar Producir',
    'Input Entry Check Digit': 'Dígito de control de entrada de datos',
    'Overwrite Origin': 'Sobrescribir origen',
    'No RFL for Ports': 'No hay RFL para los puertos',
    'Prevent Eman Print': 'Prevenir la impresión de Eman',
    '7501 Declaration Form': 'Formulario de declaración 7501',
    'Ask for Entry Requested Date/Time': 'Solicitar entrada Fecha/hora solicitada',
    'Commercial Invoice by Part Control': 'Control de Facturas Comerciales por Partidas',
    '3461 Long - First Page Only': '3461 Largo - Solo la primera página',
    'On General Desc empty': 'En General Desc vacio',
    'Convert Nafta Flag to USMCA': 'Convertir la bandera del TLCAN a la del T-MEC',
    'Optimize Rendering of CF-7501 Form': 'Optimizar la representación del formato CF-7501',
    'USMCA Valid Origins': 'Orígenes válidos del T-MEC',
    'Entry List Screen Max Page Size': 'Tamaño máximo de página de la pantalla de lista de entradas',
    'Traffic List Screen Max Page Size': 'Tamaño máximo de página de la pantalla de lista de tráfico',
    'Other List Screen Max Page Size': 'Tamaño máximo de página de la pantalla de otra lista',
    'Notice of Arrival': 'Aviso de llegada',
    'Product File': 'Ficha del producto',
    'MST / Entities': 'MST / Entidades',
    'CBP Broker Notifications': 'Notificaciones a corredores de CBP',
    'CBP Stmt Notifications': 'Notificaciones de declaraciones de CBP',

    'Transmission pending': 'Transmisión pendiente',
    'Transmission in process': 'Transmisión en proceso',
    'Transmission Rejected': 'Transmisión rechazada',
    'Transmission Accepted': 'Transmisión aceptada',
    'Transmission Accepted with Warning(s)': 'Transmisión aceptada con Advertencias',
    'Transmission Referred to Human Review': 'Transmisión orientada a revisión asistida',
    
    'Details': 'Detalles',
    'File ID': 'ID Archivo',
    'Updated at': 'Actualizado en',
    'Warnings/Errors': 'Advertencias/Errores',
    
    'User': 'Usuario'
  }
}

export default es;
